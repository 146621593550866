import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import { Button, Form } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import ReactQuill from "react-quill";
import { useParams, useLocation } from "react-router-dom";
import { trackEvent, trackPageView } from "../utils/googleAnalytics";
import { showErrorMessage, fetchData, getUserInfo, formatDateWithTime, apiRequest, showSuccessMessage } from "../utils/helpers";
import { apiConfig } from "../ApiConfig";
import Loader from "./Loader";
import EmptyState from "./EmptyState";
import '../css/TicketDetails.css';
import StarRating from "./StarRating";

const TicketDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true); // Start with loading true
    const [ticketDetails, setTicketDetails] = useState({});
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [customerName, setCustomerName] = useState('username');

    const [showForm, setShowForm] = useState(false); // State to toggle form visibility
    const [formData, setFormData] = useState({ subject: "", description: "" });
    const [files, setFiles] = useState([]);
    const [fileError, setFileError] = useState("");
    const [validated, setValidated] = useState(false);

    const url = `${apiConfig.TICKET}/${id}/conversation`;

    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);

    useEffect(() => {
        loadData();
        setCustomerName(getUserInfo('name'));
    }, [url]);

    const loadData = async () => {
        try {
            trackEvent('Ticket Details', 'request', 'Loading ticket data');
            const data = await fetchData(url, 'GET', '', true, true);
            if (data?.conversation) {
                setTicketDetails(data); // Use the actual data structure
                trackEvent('Ticket Details', 'success', 'Successfully loaded ticket details');
            }
        } catch (error) {
            showErrorMessage();
            console.error(`Ticket Details: ${error.message}`);
            trackEvent('Ticket Details', 'error', `Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const conversationStatus = ticketDetails.status?.toLowerCase();
    const rating = ticketDetails.rating;

    const handleDescriptionChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            description: value,
        }));
    };

    const handleFileChange = (e) => {
        const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
        const selectedFiles = Array.from(e.target.files);
        const validFiles = selectedFiles.filter(
            (file) => file.size <= MAX_FILE_SIZE_BYTES
        ); // Filter files <= 5MB

        if (validFiles.length + files.length > 5) {
            setFileError('Maximum of 5 files are allowed.');
            document.getElementById('fileUpload').value = ''; 
            return;
        }

        setFileError(''); // Clear error if valid
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    };

    const removeFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = prevFiles.filter((_, i) => i !== index);
            if (newFiles.length === 0) {
                // Clear file input if no files are left
                document.getElementById('fileUpload').value = ''; 
            }
            return newFiles;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            setIsButtonLoading(false);
            return;
        }

        try {
            const formDataToSubmit = new FormData();
            formDataToSubmit.append('details', formData.description);

            // Append files if any
            files.forEach((file) => {
                formDataToSubmit.append('attachments', file);
            });
            
            // Call the API to post the form data
            const result = await apiRequest(`${apiConfig.TICKET}/${id}/update`, 'POST', formDataToSubmit);

            if (result.statusCode === 200) {
                showSuccessMessage('Ticket has been updated successfully. Please follow updates under "My Tickets"');
                loadData();
                setShowForm(false);
                // Reset form to initial state
                setFormData({
                    subject: '',
                    description: '',
                });
                setFiles([]);
                setFileError('');
                setValidated(false);
            } else {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : ticketDetails?.conversation?.length === 0 ? (
                <EmptyState openModal={false} backUrl="/support/ticket" />
            ) : (
                <div className="container ticket-container">
                    <div className="ticket-header">
                        <div className="ticket-header-inner">
                            <h3>
                                #{ticketDetails?.ticket_id} – {ticketDetails?.subject}
                            </h3>
                            <div className={classNames('ticket-status', ticketDetails?.status?.toLowerCase())}>
                                {ticketDetails?.status}
                            </div>
                        </div>
                        
                        <StarRating id={id} rating={rating}/>
                    </div>
                    {ticketDetails?.conversation.map((conversation, index) => {
                        const sanitizedHTML = DOMPurify.sanitize(conversation?.details);

                        return conversation?.created_by?.toLowerCase() === 'customer' ? (
                            <div key={index} className="ticket-message">
                                <p className="message-name">
                                    {customerName} &nbsp;|&nbsp; {formatDateWithTime(conversation.created_at)}
                                </p>
                                <div
                                    className="message"
                                    dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                                ></div>
                                <div className="message hidden-attachments">{conversation?.has_attachment ? ('[attachments have been redacted]') : ('')}</div>
                            </div>
                        ) : (
                            <div key={index} className="ticket-message">
                                <p className="message-name reply">
                                    {formatDateWithTime(conversation?.created_at)} &nbsp;|&nbsp; {conversation?.created_by}
                                </p>
                                <div
                                    className="message reply"
                                    dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                                ></div>
                            </div>
                        );
                    })}
                    <div className="ticket-footer">
                        {!showForm && (
                            <Button
                                variant="primary"
                                type="button"
                                disabled={isButtonLoading}
                                className="reopen-ticket-button"
                                onClick={() => setShowForm(true)} // Show form
                            >
                                {isButtonLoading
                                    ? conversationStatus === 'open'
                                        ? 'Adding...'
                                        : 'Reopening Ticket...'
                                    : conversationStatus === 'open'
                                    ? 'Add More Details'
                                    : 'Reopen Ticket'}
                            </Button>
                        )}
                        {showForm && (
                            <div>
                            <Form noValidate validated={validated} onSubmit={handleSubmit} className="us-stock">
                                <Form.Group controlId="description" style={{marginBottom: '3.6rem'}}>
                                    <Form.Label>Add more details *</Form.Label>
                                    <ReactQuill
                                        theme="snow"
                                        value={formData.description}
                                        onChange={handleDescriptionChange}
                                        placeholder="Please provide a detailed description of your issue or problem. The more information you give, including context and background, the better we can assist you."
                                        style={{ height: '100px' }} // Adjust the height as needed
                                    />
                                    {validated && !formData.description && (
                                        <div className="text-danger mt-2">Please enter the details.</div>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="fileUpload">
                                    <Form.Label>Attach files</Form.Label>
                                    <Form.Control
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        style={{marginBottom: '0', marginTop: '0'}}
                                    />
                                    <Form.Text className="text-muted">
                                        Maximum of 5 files allowed. Each file must be less than 5MB.
                                    </Form.Text>
                                    {files.length > 0 && (
                                        <div className="mt-2">
                                            <Form.Label>Selected Files ({files.length}):</Form.Label>
                                            <ul>
                                                {files.map((file, index) => (
                                                    <li key={index}>
                                                        {file.name}
                                                        <i className="fas fa-trash delete-company" style={{marginLeft: '.5rem'}} onClick={() => removeFile(index)}>
                                                        </i>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    {fileError && <div className="text-danger mt-2">{fileError}</div>}
                                    
                                </Form.Group>
                                <Form.Group controlId="feedBackSubmit" className="btn-container" style={{marginTop: '1rem'}}>
                                    <Button variant="primary" type="submit" disabled={isButtonLoading}>
                                        {isButtonLoading ? 'Updating...' : 'Update Ticket'}
                                    </Button>
                                </Form.Group>
                            </Form>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default TicketDetails;
