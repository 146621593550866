import React, { useState, useEffect, useContext } from "react";
import {Modal, Button, Form } from "react-bootstrap";
import { formatRupees, formatDateToInputValue, fetchData, ConvertDateToMilliseconds, getFullUrl } from "../../utils/helpers";
import { ExchangeRateContext } from "../../ExchangeRateContext";
import { apiConfig } from "../../ApiConfig";
import { trackEvent } from '../../utils/googleAnalytics';

function PrivateEquityForm({ isOpen, closeModal, onFormSubmit, initialFormData, onEdit }) {
    const [allCompanies, setAllCompanies] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [formData, setFormData] = useState({ ...initialFormData });
    const [validated, setValidated] = useState(false);
    const [isDateFocused, setIsDateFocused] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [addedCompanies, setAddedCompanies] = useState([]);
    // Access currency, exchangeRate, and currency toggle function from context
    const { currency, handleCurrencyToggle, loadingExchangeRate, exchangeRate, convertCurrency } = useContext(ExchangeRateContext);

    const url = apiConfig.PRIVATEEQUITYDIRECTORY;
    const method = 'GET';
    const localStorageKey = 'privateEquityCompanyList';

    const loadData = async (refresh = false) => {
        try {
            trackEvent('PrivateEquity', 'request', 'Requesting private equity directory');
            const data = await fetchData(url, method, localStorageKey, refresh);
            setAllCompanies(data); 
            trackEvent('PrivateEquity', 'success', 'Successfully private equity directory');
        } catch (error) {
            console.error(`Private Equity directory: ${error.message}`);
        }
    };

    useEffect(() => {
        if (isOpen) {
            loadData();
            setFormData({
                ...initialFormData,
                date: initialFormData.date ? formatDateToInputValue(initialFormData.date) : "",
            });
            setValidated(false);

            // Reset currency switch to INR when the form is opened
            if (currency !== 'INR' && !onEdit) {
                handleCurrencyToggle(formData, setFormData, 'stockprice', true); // Toggle to INR
            } else if (onEdit && currency !== 'INR' && !initialFormData.is_us_company){
                handleCurrencyToggle(formData, setFormData, 'stockprice', true); 
            } else if(onEdit && initialFormData.is_us_company && currency !== 'USD') {
                handleCurrencyToggle(formData, setFormData, 'stockprice'); 
            } else if(onEdit && initialFormData.is_us_company && currency !== 'INR') {
                const convertedAmount = convertCurrency(initialFormData.stockprice, exchangeRate, 'USD');
                setFormData(prevFormData => ({
                    ...prevFormData,
                    stockprice: convertedAmount, // Update stockprice with the converted amount
                }));
            }
        }
    }, [isOpen, initialFormData]);

    const handleSearchInputChange = (e) => {
        const query = e.target.value.toLowerCase();
        setFormData((prevData) => ({ ...prevData, name: e.target.value }));
    
        if (query.length > 0 && allCompanies && allCompanies.length > 0) {  // Check if allCompanies is defined and not empty
            const filteredResults = allCompanies.filter((company) =>
                company.name.toLowerCase().includes(query)
            );
            setSearchResults(filteredResults); 
        } else {
            setSearchResults([]);
        }
    };    
    

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        let newValue = value;

        if (id === "stockprice") {
            // Remove commas for processing
            newValue = value.replace(/,/g, "");

            // Allow only numbers and a single dot
            if (/^\d*\.?\d*$/.test(newValue)) {
                // Do not apply formatting if the input is incomplete (e.g., ends with a dot)
                if (newValue === "." || newValue.endsWith(".")) {
                    setFormData((prevData) => ({ ...prevData, [id]: newValue }));
                    return;
                }
    
                // Apply formatting for valid numbers
                newValue = formatRupees(newValue);
            } else {
                // If invalid input, do nothing
                return;
            }
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
    };

    const handleSelectResult = (result) => {
        setFormData((prevData) => ({
            ...prevData,
            name: result,
        }));
        setSearchResults([]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsButtonLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setIsButtonLoading(false);
        } else {
            // Additional validation
            if (addedCompanies.length === 0 && (!formData.name || !formData.quantity || !formData.stockprice || !formData.date)) {
                setValidated(true);
                setIsButtonLoading(false);
                return;
            }

            try {
                // If there is data in the form, add it to the added companies list
                const updatedFormData = { ...formData, date: ConvertDateToMilliseconds(formData.date)};
    
                let finalAddedCompanies = [...addedCompanies];
                if (formData.name && formData.quantity && formData.stockprice) {
                    // Ensure stockprice is in INR if currency is USD
                    if (currency === 'USD') {
                        updatedFormData.stockprice = convertCurrency(updatedFormData.stockprice, exchangeRate, 'INR'); // Convert to INR
                        updatedFormData.is_us_company = true; // Set to true if the currency is USD
                    } else {
                        updatedFormData.is_us_company = false; // Set to false for non-USD currencies
                    }
                    // Merge the form data only if it's not blank
                    finalAddedCompanies = [...addedCompanies, updatedFormData];
                }
                await onFormSubmit(finalAddedCompanies);
                
                setValidated(false); // Reset validation state
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsButtonLoading(false);
            }
        }
    };

    const handleAddMore = (event) => {
        event.preventDefault();
        
        if (!formData.name || !formData.quantity || !formData.stockprice || !formData.date) {
            setValidated(true); // Validate the form fields
            return;
        }
    
        // Prepare the new company data
        let newCompanyData = {
            ...formData,
            date: ConvertDateToMilliseconds(formData.date),
        };
    
        // Check currency and convert amount if necessary
        if (currency === 'USD' && newCompanyData.stockprice) {
            newCompanyData.stockprice = convertCurrency(newCompanyData.stockprice, exchangeRate, 'INR'); // Convert USD to INR
        }
    
        if (isEditing && editIndex !== null) {
            // Update existing entry in the added companies array
            const updatedCompanies = [...addedCompanies];
            updatedCompanies[editIndex] = newCompanyData;
            setAddedCompanies(updatedCompanies);
            
            setIsEditing(false);
            setEditIndex(null);
        } else {
            // Add new entry to the added companies array
            setAddedCompanies([...addedCompanies, newCompanyData]);
        }
    
        // Reset form data
        setFormData(initialFormData);
        setValidated(false);
    };
    

    const handleEdit = (index) => {

        const itemToEdit = addedCompanies[index];
        const formattedDate = itemToEdit.date ? formatDateToInputValue(itemToEdit.date) : "";
        const amount = currency === 'USD' ? convertCurrency(itemToEdit.stockprice, exchangeRate, currency) : itemToEdit.stockprice;
        // Fill the form with the selected item's data for editing
        setFormData({
            ...itemToEdit,
            date: formattedDate, // Set the formatted date
            stockprice: amount
        });

        setIsEditing(true);
        setEditIndex(index);
        handleDelete(index);
    };

    const handleDelete = (index) => {
        setAddedCompanies(addedCompanies.filter((_, i) => i !== index));
    };
    
    return (
        <Modal show={isOpen} onHide={closeModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{onEdit ? 'Update' : 'Add'} Private Equity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="private-equity">
                    <Form.Group controlId="name">
                        {onEdit ? (
                            <Form.Control
                                type="text"
                                value={formData.name}
                                onChange={handleSearchInputChange}
                                placeholder="Enter Company Name *"
                                required
                                autoComplete="off"
                                disabled={true}
                            />
                        ) : (
                            <>
                                <Form.Control
                                    type="text"
                                    value={formData.name}
                                    onChange={handleSearchInputChange}
                                    placeholder="Enter Company Name *"
                                    required
                                    autoComplete="off"
                                />
                                <div style={{ position: "relative" }}>
                                    <ul className="list-group search-results" style={{ position: "absolute", top: "100%", left: "0", zIndex: "1000", width: "100%" }}>
                                        {searchResults.map((result, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item"
                                                onClick={() => handleSelectResult(result.name)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {result.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                        <Form.Control.Feedback type="invalid">
                            Please enter the company name.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="quantity">
                        <Form.Control
                            type="number"
                            placeholder="No. Of Stocks *"
                            value={formData.quantity}
                            autoComplete="off"
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the no. of stocks.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="stockprice" className="input-container">
                            <span className="input-label">{currency === 'USD' ? '$' : '₹'}</span>
                            <Form.Control
                                type="text"
                                placeholder="Price Per Stock *"
                                value={formData.stockprice}
                                autoComplete="off"
                                required
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter the stock price.
                            </Form.Control.Feedback>
                    </Form.Group>
                    
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="currencySwitch">Is this a US Company?</label>
                        <input className="form-check-input" type="checkbox" id="currencySwitch" disabled={loadingExchangeRate} checked={currency === 'USD'} onChange={() => handleCurrencyToggle(formData, setFormData, 'stockprice')} />
                        <div style={{paddingLeft: '1rem', cursor: 'pointer'}} title="To get personalised wealth insights based on geographical region"><img alt="Info" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" src={getFullUrl('images/info.png')}/></div>
                    </div>
                    
                    <Form.Group controlId="date">
                        <div className={`position-relative ${!formData.date ? 'placeholder-date' : ''}`}>
                        {!formData.date && !isDateFocused && (
                            <span className='date-placeholder'>
                            Invested Date
                            </span>
                        )}
                        <Form.Control
                            type="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            autoComplete="off"
                            placeholder="Invested Date *"
                            required
                            style={{ width: '100%', cursor: 'pointer' }}
                            onFocus={(e) => {
                                if (!formData.date) {
                                    setIsDateFocused(true); // Only set focus state if date is not filled
                                    e.target.showPicker();  // Show the picker when focused
                                }
                            }}
                            onBlur={() => setIsDateFocused(false)} // Show placeholder on blur if no date
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select the date.
                        </Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    {!onEdit && (
                        <>
                            <a className="add-more" onClick={handleAddMore}>
                                Add More
                            </a>
                            {addedCompanies.length > 0 && (
                                <div className="added-companies-list">
                                    <table className="companies-table">
                                    <thead>
                                        <tr>
                                        <th className="table-head-name">Name</th>
                                        <th>Qty</th>
                                        <th>Amount</th>
                                        <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addedCompanies.map((company, index) => (
                                        <tr key={index} className='buy'>
                                            <td className="table-row-name">
                                            <div className="name-wrapper">
                                                <span>{company.name}</span>
                                            </div>
                                            </td>
                                            <td>
                                            <span>{company.quantity}</span>
                                            </td>
                                            <td>
                                            <span>
                                                ₹
                                                {company.stockprice}
                                            </span>
                                            </td>
                                            <td>
                                            <span>
                                                <i
                                                className="fas fa-edit edit-company"
                                                onClick={() => handleEdit(index)}
                                                ></i>
                                                <i
                                                className="fas fa-trash delete-company"
                                                onClick={() => handleDelete(index)}
                                                ></i>
                                            </span>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                    )}
                    <Form.Group controlId="privateEquitySubmit" className="btn-container">
                        <Button variant="primary" type="submit" disabled={isButtonLoading}>
                            {isButtonLoading ? (onEdit ? 'Updating...' : 'Saving...') : (onEdit ? 'Update' : 'Save')}
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default PrivateEquityForm;
