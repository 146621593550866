import React from "react";
import { Header } from "./navbar/Navbar.style";
import { CDBNavbar } from "cdbreact";
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import '../css/Header.css';
import '../css/Sidebar.css';
import { Button } from "antd";
import { getFullUrl } from "../utils/helpers";

const HeaderNoValidation = () => {
    const navigate = useNavigate();

    return (
        <div className="container-fluid pad-un">
            <Header className="header-container">
                <div className="form-inline ml-auto">
                    <div className='logo-container'>
                        <img alt="icon" src={getFullUrl('logo.png')} style={{ width: "2rem", height: "2rem", marginLeft: 'unset' }} />
                        <div className='logo-name'>MoneyLens</div>
                    </div>
                </div>
                <CDBNavbar dark expand="md" scrolling className="justify-content-end">
                <div className="ml-auto">
                    <div className="menu-container">
                        {/* Calculator Dropdown */}
                        {/* <NavLink exact='true' to="/calculator/growth" activeclassname="activeClicked">CAGR Calculator</NavLink>
                        <NavLink exact='true' to="/calculator/retirement" activeclassname="activeClicked">Retirement Planning</NavLink>
                        <NavLink exact='true' to="/calculator/sip" activeclassname="activeClicked">SIP Calculator</NavLink>
                        <NavLink exact='true' to="/calculator/home-loan" activeclassname="activeClicked">Home Loan Calculator</NavLink>
                        <NavLink exact='true' to="/calculator/car-loan" activeclassname="activeClicked">Car Loan Calculator</NavLink>
                        <NavLink exact='true' to="/calculator/personal-loan" activeclassname="activeClicked">Personal Loan Calculator</NavLink> */}
                        {/* Calculator Dropdown */}
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="custom-dropdown-toggle">
                            Calculators <i className="fas fa-angle-down" style={{ marginLeft: '7px' }}></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="custom-dropdown-menu">
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/growth" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    CAGR Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/car-loan" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Car Loan Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/home-loan" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Home Loan Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/personal-loan" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Personal Loan Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/retirement" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Retirement Planning
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/sip" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    SIP Calculator
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    <Button className="btn btn-primary custom-btn btn-signin" style={{marginRight: '1rem', marginLeft: '2rem'}} onClick={() => navigate('/login')}>Sign in</Button>
                    <Button className="btn btn-primary custom-btn btn-signin" onClick={() => navigate('/signup')}>Sign up</Button>
                </div>
                </CDBNavbar>
            </Header>
        </div>
    );
};

export default HeaderNoValidation;