import React, {useEffect} from 'react';
import './css/App.css';
import './css/Responsive.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; 
import { ExchangeRateProvider } from './ExchangeRateContext';
import AppLayout from './AppLayout';
import { initGA } from './utils/googleAnalytics';

function App() {

  useEffect(() => {
    // Initialize GA only once when the app is loaded
    initGA();
  }, []);  // Empty dependency array means it runs only once

  return (
    <AuthProvider>
      <ExchangeRateProvider>
        <Router>
          <AppLayout />
        </Router>
      </ExchangeRateProvider>
    </AuthProvider>
  );
}

export default App;
