import React, {useState, useEffect} from "react";
import '../../css/MutualFund.css';

import TotalPortfolioValue from "../TotalPortfolioValue";
import DynamicTabs from "../DynamicTabs";
import PrivateEquityAnalytics from './PrivateEquityAnalytics';
import PrivateEquityTransactions from './PrivateEquityTransactions';
import Loader from "../Loader";

import { fetchData, updateDirtyState, apiRequest, showErrorMessage, showSuccessMessage } from "../../utils/helpers";
import EmptyState from "../EmptyState";
import { apiConfig } from "../../ApiConfig";
import PrivateEquityForm from "./PrivateEquityForm";
import PrivateEquityList from "./PrivateEquityList";

import { trackEvent, trackPageView } from "../../utils/googleAnalytics";
import { useLocation } from "react-router-dom";
// import { useSidebar } from "../SidebarContext";

function PrivateEquity() {

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [privateEquityList, setPrivateEquityList] = useState([]);
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const { updateSideBarData } = useSidebar();

    const url = apiConfig.PRIVATEEQUITYSUMMARY;
    const method = 'GET';
    const localStorageKey = 'privateEquityList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            trackEvent('PrivateEquity', 'request', 'Loading private equity data');
            const data = await fetchData(url, method, localStorageKey, refresh);
            if(data) {
                setPortfolioValue(data);
                setPrivateEquityList(data?.asset_details || [])
                setPortfolioValueReturns(data['returns'] || [0, 0]);
                trackEvent('PrivateEquity', 'success', 'Successfully loaded private equity data');
            }
        } catch (error) {
            showErrorMessage();
            console.error(`Private Equity list: ${error.message}`);
            trackEvent('PrivateEquity', 'error', `Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        updateDirtyState('privateEquityList');
        updateDirtyState('privateEquityAnalyticsData');
        updateDirtyState('privateEquityTransactionList');
        updateDirtyState('privateEquityCompanyList');
        updateDirtyState('networth-dashboard');
        // updateSideBarData();
    };

    const portfolioData = {
        module: 'privateequity',
        value: portfolioValue?.curr_val || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
    };
    
    const tabs = [
        { key: 'list', name: 'My Assets', component: PrivateEquityList },
        { key: 'analytics', name: 'Analytics', component: PrivateEquityAnalytics },
        { key: 'transactions', name: 'Transactions', component: PrivateEquityTransactions },
    ];
    
    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true); // Open the modal
        trackEvent('PrivateEquity', 'openForm', 'Opening form modal');
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (formData) => {
        formData = { transactions: formData };
        try {
            trackEvent('PrivateEquity', 'request', 'Submitting private equity transaction');

            const url = apiConfig.PRIVATEEQUITYTRANSACTIONS;
            
            // Call the API to post the form data
            const result = await apiRequest(url, 'POST', formData);
            if (result.statusCode === 200){
                showSuccessMessage();
                handleRefresh();
                closeModal(); // Close the modal after submission
                trackEvent('PrivateEquity', 'success', 'Successfully submitted transaction');
            } if (result.error) {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('PrivateEquity', 'error', `APi (${url}): ${result.error.message}`);
            } else if(result.statusCode !== 200) {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('PrivateEquity', 'error', `APi (${url}): ${result.message}`);
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
            trackEvent('PrivateEquity', 'error', `APi (${url}): ${error.message}`);
        }
    };

    // Initial form data state for both forms
    const initialFormData = {
        name: '', //  name
        quantity: '', // Quantity
        stockprice: '', // Purchase price
        date: '', // Purchase date
    };
    return (
        <div>
        {isLoading ? ( 
            <Loader />
        ) : privateEquityList.length === 0 ? 
            <EmptyState openModal={openModal}/>
        : (
            <div className="container rsusEsop pad-right-unset pad-left-unset">
                <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'Private Equity'} openForm={() => openModal(null)}/>
                <DynamicTabs tabs={tabs} data={privateEquityList} module={'privateequity'} onTabChange={(activeTabKey) => {
                    if (activeTabKey === 'list') {
                        loadData(); // Call loadData when "My Assets" tab is clicked
                    }
                }}/>
                
                <div className="float-container">
                    <a className="float"  onClick={(e) => { e.preventDefault(); openModal(null); }}>
                        <i className="fa fa-plus my-float"></i>
                    </a>
                </div>
            </div>
        )}
        <PrivateEquityForm 
            isOpen={isModalOpen} 
            closeModal={closeModal} 
            onFormSubmit={handleFormSubmit} 
            initialFormData={initialFormData}
        />
        </div>
    );
}

export default PrivateEquity;