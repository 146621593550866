// Import the CSS file for styling the pie chart
import '../css/PieChart.css';
import { isEmptyField, removeSign, formatCurrency } from '../utils/helpers';
// Import necessary React modules and external libraries
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames'; // Utility for conditionally joining class names
import * as d3 from 'd3'; // Import the D3 library for creating data-driven documents

// Define the PieChart component with destructured props
const PieChart = ({ pieData, id, width, height, heading = '', status = true, profitLossColor = false, module }) => {
    const chartRef = useRef(); // Create a reference for the SVG element

    // Determine dynamic class based on the 'id' prop
    const dynamicClass = (id === 'current' || id === 'invested') ? 'border-top-radius-unset' : '';
    // Define a color array for the pie chart segments
    const colorArray = [
        '#007BB6',
        '#9BB3E5',
        '#688ED9',
        '#0059C1',
        
        '#FFD36E',
        '#FFE19E',
        '#FFF2DE',
        '#FFB50D',
        '#FFC43D',
        
        '#FFCEC5',
        '#FD9AC0',
        '#7FC7BC',
        '#41AC9C',
        '#008F7A',
        '#C270C8',
        '#A761C6',
        '#734FC3',
        '#AA95DB'
    ];
    
    useEffect(() => {
        // Convert pieData into objects and sort by value in descending order
        if (pieData && !isEmptyField(pieData)) {

            const data = pieData
                    .map(([name, value, percentage]) => ({
                        name,
                        value,
                        percentage: removeSign(percentage)
                    }))
                    .filter(item => parseFloat(item.percentage) >= 0.01) // Filter out items with percentage < 0.01
                    .sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)); // Sorts in descending order by percentage

            //data.sort((a, b) => b.value - a.value);
        
            // Parse width and height props
            const viewWidth = parseFloat(width);
            const viewHeight = parseFloat(height);

            // Set SVG dimensions
            const svgWidth = viewHeight;
            const svgHeight = viewHeight;
            const thickness = 40; // Thickness of pie chart segments
            const radius = Math.min(svgWidth, svgHeight) / 2; // Calculate the radius for the pie chart

            const isMobile = window.innerWidth <= 768;
            const viewHeightResolution = isMobile ? parseFloat('150') : viewHeight; 
            const pieTranslateResolution = isMobile ? (profitLossColor) ? 90 : 80 : ((svgWidth / 2) + (thickness / 2)) - 10;
            // Define color scale based on data
            const color = profitLossColor
                ? d3.scaleOrdinal()
                .domain(data.map(d => d.name))
                .range(data.map(d => (d.name === 'Profit' ? '#198754' : '#ff0000'))) // Green for Profit, Red for Loss
                : d3.scaleOrdinal()
                    .domain(data.map(d => d.name))
                    .range(colorArray);
            
            // Find the maximum value in the data
            const max = d3.max(data, maxData => maxData.value);
            
            // Set up the SVG container with responsive properties
            const svg = d3.select(chartRef.current)
                .attr('viewBox', `0 0 ${viewWidth + thickness} ${viewHeight + thickness}`)
                .attr('preserveAspectRatio', 'xMidYMid meet')
                .attr('width', viewWidth)
                .attr('height', viewHeightResolution);

            // Append a group element and center it in the SVG
            const g = svg.append('g')
                .attr('transform', `translate(${pieTranslateResolution}, ${(svgHeight / 2) + (thickness / 2)})`);

            // Define arcs for the pie chart and hover state
            const arc = d3.arc().innerRadius(radius - thickness).outerRadius(radius);
            const arcHover = d3.arc().innerRadius(radius - (thickness + 10)).outerRadius(radius + 10);
            // Define the pie layout
            const pie = d3.pie().value(d => d.value).sort(null);
            let activeSegment; // Variable to track the active segment on hover

            // Bind data and create groups for each pie slice
            const path = g.selectAll('path')
                .data(pie(data))
                .enter()
                .append('g')
                .attr('class', `data-group-${id}`);

            // Draw the pie chart segments
            path.append('path')
                .attr('d', arc)
                .attr('fill', d => color(d.data.name)) // Assign colors to segments
                .attr('class', `data-path-${id}`)
                .style('opacity', 1)
                .on('mouseover', function(event, d) {
                    // Hover effect: highlight the segment and show details
                    if (this !== activeSegment) {
                        activeSegment = this;
                        d3.selectAll(`.data-text__name-${id}`).style('opacity', 0);
                        d3.selectAll(`.data-text__value-${id}`).style('opacity', 0);
                        d3.selectAll(`.data-path-${id}`)
                            .transition()
                            .duration(0)
                            .attr('d', arc)
                            .style('opacity', 0.3);

                        d3.select(this)
                            .transition()
                            .duration(0)
                            .attr('d', arcHover)
                            .style('opacity', 1);

                        const parentNode = this.parentNode;
                        d3.select(parentNode).select(`.data-text__name-${id}`).style('opacity', 1).classed('data-text--show', true);
                        d3.select(parentNode).select(`.data-text__value-${id}`).style('opacity', 1).classed('data-text--show', true);
                        

                        d3.selectAll(`.legend-item-${id}`)
                            .style('background-color', 'transparent')
                            .filter(legendData => legendData.name === d.data.name)
                            .style('background-color', '#ddd')
                            .each(function () {
                                this.scrollIntoView({ behavior: 'smooth', block: 'nearest' }); // Scroll legend into view
                            });
                    }
                })
                .on('mouseout', function() {
                    // Restore default state when the mouse leaves a segment
                    d3.selectAll(`.data-path-${id}`)
                        .transition()
                        .duration(250)
                        .attr('d', arc)
                        .style('opacity', 1);

                    if (this === activeSegment) {
                        activeSegment = null;
                    }

                    d3.selectAll(`.legend-item-${id}`).style('background-color', 'transparent');
                });

            // Define the text content and word count
            path.each(function(d) {
                const name = d.data.name;
                const wordCount = name.split(' ').length;
                const hasMoreThanSixChars = name.length > 16;
            
                // Create legend items and hide them by default
                const legendItem = d3.select('.legend-container')
                    .append('div')
                    .attr('class', 'legend-item')
                    .style('display', 'none')  // Hide all legend items by default
                    .text(name);
            
                // Check if the name has more than 3 words and more than 6 characters
                if (wordCount > 2 && hasMoreThanSixChars) {
                    d3.select(this).append('foreignObject')
                        .attr('x', -75)
                        .attr('y', -35)
                        .attr('width', 150)
                        .attr('height', 120)
                        .append('xhtml:div')
                        .attr('class', `data-text-more data-text data-text__name-${id}`)
                        .style('text-align', 'center')
                        .style('margin-bottom', '1')
                        .style('word-wrap', 'break-word')
                        .style('opacity', 0)
                        .attr('dy', '-1rem')
                        .text(name);
            
                    d3.select(this).append('text')
                        .text(`${formatCurrency(d.data.value, module, profitLossColor)}${profitLossColor ? '%' : ''}`)
                        .attr('class', `data-value data-text data-text__value-${id}`)
                        .attr('transform', `translate(0, 0)`)
                        .attr('text-anchor', 'middle')
                        .attr('dy', '3.5rem')
                        .style('opacity', 0);
            
                } else {
                    d3.select(this).append('foreignObject')
                        .attr('x', profitLossColor ? -50 : -75)
                        .attr('y', profitLossColor ? -30 : -45)
                        .attr('width', profitLossColor ? 100 : 150)
                        .attr('height', profitLossColor ? 60 : 120)
                        .append('xhtml:div')
                        .attr('class', `data-text-long data-text data-text__name-${id}`)
                        .style('text-align', 'center')
                        .style('margin-bottom', '.5')
                        .style('word-wrap', 'break-word')
                        .style('opacity', 0)
                        .attr('dy', '-1rem')
                        .text(name);
            
                    d3.select(this).append('text')
                        .text(`${formatCurrency(d.data.value, module, profitLossColor)}${profitLossColor ? '%' : ''}`)
                        .attr('class', `data-value data-text data-text__value-${id}`)
                        .attr('transform', `translate(0, 0)`)
                        .attr('text-anchor', 'middle')
                        .attr('dy', '1rem')
                        .style('opacity', 0);
                }
            
                // Add mouseover and mouseout events to show and hide the corresponding legend item
                d3.select(this).on('mouseover', function() {
                    // Show the corresponding legend item
                    legendItem.style('display', 'block');
                }).on('mouseout', function() {
                    // Hide the legend item again
                    legendItem.style('display', 'none');
                });
            });
            
            
            // Show the largest value by default
            let isShown = false;
            d3.selectAll(`.data-group-${id}`)
                .filter(d => d.data.value === max)
                .each(function() {
                    if (max > 1 && !isShown) {
                        // Show only the first matching item
                        d3.select(this)
                            .select(`.data-text__name-${id}`)
                            .style('opacity', 1)
                            .classed('data-text--show', true);

                        d3.select(this)
                            .select(`.data-text__value-${id}`)
                            .style('opacity', 1)
                            .classed('data-text--show', true);

                        isShown = true; // Mark that an item is shown
                    }
                });


            if (status) {
                const legendRectSize = 15; // Size of legend squares
                const legendSpacing = 10; // Spacing between legend items
                const legendSVGResolution = isMobile ? 350 : 300;
                const legendSVGXResolution = isMobile ? svgWidth - 20 : svgWidth + 20;
                // Create a legend container using foreignObject for HTML-based legends
                const foreignObject = svg.append('foreignObject')
                    .attr('x', legendSVGXResolution)
                    .attr('y', 20)
                    .attr('width', legendSVGResolution)
                    .attr('height', svgHeight)
                    .append('xhtml:div')
                    .attr('class', 'legend-wrapper')
                    .style('overflow-y', 'auto')
                    .style('height', `${svgHeight}px`);

                // Bind data to legend items and set hover effects
                const legend = foreignObject.selectAll(`.legend-${id}`)
                    .data(data)
                    .enter()
                    .append('div')
                    .attr('class', d => `legend-item-${id}`)
                    .style('display', 'flex')
                    .style('align-items', 'center')
                    .style('padding', '5px 0');
                    // .on('mouseover', (event, d) => {
                    //     const hoveredLegend = event.currentTarget;
                    //     hoveredLegend.style.backgroundColor = '#ddd';

                    //     d3.selectAll(`.data-path-${id}`)
                    //         .transition()
                    //         .duration(250)
                    //         .attr('d', arc)
                    //         .style('opacity', 0.3);

                    //     d3.select(`.data-path-${id}[fill="${color(d.name)}"]`)
                    //         .transition()
                    //         .duration(250)
                    //         .attr('d', arcHover)
                    //         .style('opacity', 1);
                        
                    //     d3.select(`.data-text__name-${id}`).text(d.name).style('opacity', 1);
                    //     d3.select(`.data-text__value-${id}`).text(d.value).style('opacity', 1);
                    //     hoveredLegend.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    // })
                    // .on('mouseout', () => {
                    //     d3.selectAll(`.data-path-${id}`)
                    //         .transition()
                    //         .duration(250)
                    //         .attr('d', arc)
                    //         .style('opacity', 1);

                    //     d3.selectAll(`.legend-item-${id}`).style('background-color', 'transparent');
                    // });

                // Add color circles to each legend item
                legend.append('svg')
                    .attr('width', legendRectSize)
                    .attr('height', legendRectSize)
                    .append('circle')
                    .attr('cx', legendRectSize / 2)
                    .attr('cy', legendRectSize / 2)
                    .attr('r', legendRectSize / 2)
                    .style('fill', d => color(d.name));

                // Add text labels to each legend item
                legend.append('div')
                    .attr('class', 'legend-item-text')
                    .style('display', 'flex')
                    .style('justify-content', 'space-between')
                    .style('margin-left', `${legendSpacing}px`)
                    .html(d => `
                        <div class="legend-item-name">${d.name}</div>
                        <div class="legend-item-percentage"><span class="legend-percentage-value">${d.percentage}</span><span class='legend-percentage-symbol'>%</span></div>
                    `);
            }
            
            // Cleanup function to remove the SVG elements on component unmount
            return () => {
                d3.select(chartRef.current).selectAll('*').remove();
            };
        }

    }, [pieData, id, width, height, status, colorArray, profitLossColor]); // Dependencies for the useEffect
    // Render the pie chart component
    return (
        <div className={classNames('piechart-wrapper', dynamicClass)}>
            {heading && <h2 className='heading'>{heading}</h2>} {/* Optional heading */}
            <svg ref={chartRef} /> {/* SVG element for the pie chart */}
        </div>
    );
};

export default PieChart; // Export the PieChart component
