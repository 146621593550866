import React, {useState, useEffect, useMemo} from "react";
import { fetchData, isEmptyField, showErrorMessage, updateDirtyState } from "../utils/helpers";
import ApexChart from "./ApexChart";
import PieChart from "./PieChart";
import Analytics from "./Analytics";
import EmptyState from "./EmptyState";
import {apiConfig} from "../ApiConfig";
import { useSidebar } from "./SidebarContext";

import TotalPortfolioValue from "./TotalPortfolioValue";
import '../css/MutualFund.css';

import Loader from "./Loader";

function NetWorth () {
    
    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [networthValue, setNetworthValue] = useState({});

    const [networthHeader, setNetworthHeader] = useState({});
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueAssets, setPortfolioValueAssets] = useState({}); 

    const [baseDailyTrend, setBaseDailyTrend] = useState({}); 
    const [baseWeeklyTrend, setBaseWeeklyTrend] = useState({}); 

    const url = apiConfig.NETWORTH;
    const method = 'GET';
    const localStorageKey = 'networth-dashboard';
    
    const { setSideBarData } = useSidebar();
    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(url, method, localStorageKey, refresh);
            if(data){
              updateStateWithData(data);
            } else {
              updateStateWithData(null)
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    // Fetch asset data when the component mounts
    useEffect(() => {
      loadData();
    }, [url, method, localStorageKey, setSideBarData]);
    
    
    
    const updateStateWithData = (result) => {
        if (!result) return; // Do not reset states if result is null

        setSideBarData(result.header || 0);
        setNetworthValue(result || {});
        setNetworthHeader(result?.header || {});
        setPortfolioValueAssets(result?.header?.networth || 0)
        setPortfolioValue1DChange(result?.header?.networth['1d_change'] || 0)

        const baseDailyTrend = result?.historic_trend?.base_trend?.daily_data || [];
        setBaseDailyTrend(baseDailyTrend);
        const baseWeeklyTrend = result?.historic_trend?.base_trend?.weekly_data || [];
        setBaseWeeklyTrend(baseWeeklyTrend);
    }

    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        updateDirtyState('networth-dashboard');

        updateDirtyState('mutualFundList');
        updateDirtyState('mutualFundAnalyticsData');
        updateDirtyState('mutualFundTransactionList');

        updateDirtyState('indiaStockList');
        updateDirtyState('indiaStocksAnalyticsData');
        updateDirtyState('indiaStocksTransactionList');

        updateDirtyState('usStockList');
        updateDirtyState('usStocksAnalyticsData');
        updateDirtyState('usStocksTransactionList');

        updateDirtyState('cryptoCurrencyList');
        updateDirtyState('cryptoCurrencyAnalyticsData');
        updateDirtyState('cryptoCurrencyTransactionList');

        updateDirtyState('preciousMetalsList');
        updateDirtyState('preciousMetalsAnalyticsData');
        updateDirtyState('preciousMetalsTransactionList');

        updateDirtyState('privateEquityList');
        updateDirtyState('privateEquityAnalyticsData');
        updateDirtyState('privateEquityTransactionList');

        updateDirtyState('realEstateList');
        updateDirtyState('realEstateAnalyticsData');
        updateDirtyState('realEstateTransactionList');

        updateDirtyState('usStockCurrVal');
        updateDirtyState('cryptoCurrencyCurrVal');
    };

    const portfolioData = {
        module: 'networths',
        value: portfolioValueAssets?.curr_val || 0,
        dayChangeValue: portfolioValue1DChange?.[0] || 0,
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: 0,
        returns: 0,
        returnspercent: 0,
        plusIcon: false,
    };
    
    const initialRows = [
        {
          id: 'row1',
          columns: [
            { id: '1', header: 'Portfolio Growth Trend', contentId: 'historic-trend' },
          ],
        },
        {
          id: 'row2',
          columns: [
            { id: '2', header: 'Geo Allocation', contentId: 'country-distribution'},
            { id: '3', header: 'Market Cap', contentId: 'market-cap'},
          ],
        },
        {
          id: 'row3',
          columns: [
            { id: '4', header: 'Asset Classes', contentId: 'assets-class'},
            { id: '5', header: 'Industry Allocation', contentId: 'industry'},
          ]
        },
        {
          id: 'row4',
          columns: [
            { id: '6', header: 'Sector Allocation', contentId: 'sector-distribution'},
            { id: '7', header: 'Asset Type', contentId: 'asset-type'}
          ]
        },
      ];
    
    const contentMap = useMemo(() => ({
      'historic-trend' : <div className='holding-chart-container'>
                            <ApexChart 
                              baseTrend={baseDailyTrend} 
                              referenceTrend={false} 
                              baseWeeklyTrend={baseWeeklyTrend} 
                              referenceWeeklyTrend={false} 
                              referenceIndex={false}
                            />
                          </div>,
      'country-distribution' : <PieChart pieData={networthValue.country_distribution} id='country-distribution' width='480' height='250' />,
      'market-cap' : <PieChart pieData={networthValue.market_cap_distribution} id='market-cap' width='480' height='250' />,
      'assets-class' : <PieChart pieData={networthValue.asset_class_distribution} id='assets-class' width='480' height='250' />,
      'sector-distribution' : <PieChart pieData={networthValue.sector_distribution} id='sector-distribution' width='480' height='250' />,
      'industry' : <PieChart pieData={networthValue.industry_distribution} id='industry' width='480' height='250' />,
      'asset-type' : <PieChart pieData={networthValue.asset_type_distribution} id='asset-type' width='480' height='250' />,
    }), [networthValue, baseDailyTrend, baseWeeklyTrend]);

    return (
        <div>
        {isLoading ? (
            <Loader /> // Show the loader while data is loading
        )  : isEmptyField(networthHeader) ? ( // Check if the object is empty
            <EmptyState />  // Render the Empty State component when there's no data
        ) : (
        <div className="container networth pad-right-unset pad-left-unset">
            <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'NetWorth'}/>
            <Analytics initialRows={initialRows} contentMap={contentMap} module={'networth-analytics'}/>
        </div>
        )}
        </div>
    );
}

export default NetWorth;