import React, { useState } from "react";
import '../css/DynamicTable.css';
import { formatRupees } from "../utils/helpers";

const CalculatorTable = ({ data, heading2, heading3, module, cagr = false }) => {
  const [expandedYear, setExpandedYear] = useState(null);

  const toggleYear = (year) => {
    setExpandedYear(expandedYear === year ? null : year);
  };

  const individualYearlyData = (data) => {
    switch (module) {
      case 'sip':
        return data.yearlyInvestment;
      default:
        return data.months.reduce((acc, month) => acc + parseFloat(month.expense), 0).toFixed(0);
    }
  }
  return (
    <div className="table-responsive">
      <table className="table table-retirement">
        <thead className="table-header">
          <tr>
            <th></th>
            <th scope="col" className="table-heading-data">Year</th>
            <th scope="col" className="table-heading-data">{heading2 || 'Total Expense'}</th>
            <th scope="col" className="table-heading-data">{heading3 || 'Remaining Corpus'}</th>
            {cagr ? <th scope="col" className="table-heading-data">{cagr}</th> : ''}
          </tr>
        </thead>
        <tbody>
          {data.map((yearData, index) => (
            <React.Fragment key={index}>
              {/* Year Row */}
              <tr onClick={() => toggleYear(yearData.year)}>
                <td>
                  <span className="table-button-container">
                    {/* Conditionally render the icon based on expandedYear */}
                    <i className={`fa ${expandedYear === yearData.year ? 'fa-minus' : 'fa-plus'}`}></i>
                  </span>
                </td>
                <td>{`Year ${yearData.months[yearData.months.length - 1].yearNo}`}</td>
                <td>{`₹${formatRupees(individualYearlyData(yearData))}`}</td>
                <td>{`₹${formatRupees(yearData.months[yearData.months.length - 1].remainingCorpus)}`}</td>
                {cagr ? <td>{`₹${formatRupees(individualYearlyData(yearData) + yearData.months[yearData.months.length - 1].remainingCorpus)}`}</td> : ''}
              </tr>

              {/* Expanded Monthly Data */}
              {expandedYear === yearData.year &&
                yearData.months.map((monthData, idx) => (
                  <tr key={idx}>
                    <td></td> {/* This cell is left empty; you can add any content or remove this if not needed */}
                    <td className="table-heading-data">{`${monthData.month}`}</td>
                    <td className="table-heading-data">{`₹${formatRupees(monthData.expense)}`}</td>
                    <td className="table-heading-data">{`₹${formatRupees(monthData.remainingCorpus)}`}</td>
                    {cagr ? <td className="table-heading-data">{`₹${formatRupees(monthData.expense + monthData.remainingCorpus)}`}</td> : ''}
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CalculatorTable;
