import React, { useState, useEffect } from 'react';
import './css/Login.css';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { apiRequest, getFullUrl } from './utils/helpers';
import { apiConfig } from './ApiConfig';
import { trackPageView, trackEvent, setUserInfo } from './utils/googleAnalytics';
import Footer from './Footer';

const Login = () => {
    const navigate = useNavigate();
    const { login, isAuthenticated } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [error, setError] = useState(null);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    // Load remembered email and password on mount
    useEffect(() => {
        const rememberedData = JSON.parse(localStorage.getItem('rememberMeData'));
        if (rememberedData && rememberedData.rememberMe) {
            setEmail(rememberedData.email);
            setPassword(rememberedData.password);
            setRememberMe(rememberedData.rememberMe);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
          // If user is authenticated, redirect to /networth
          navigate('/networth', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        const form = e.currentTarget;
    
        // Check if form is valid
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            setIsButtonLoading(false);
        }else{
            setValidated(true);
            try {
                const formData = {
                    email: email,
                    password: password
                };
            
                // Call the API to post the form data
                const result = await apiRequest(apiConfig.LOGIN, 'POST', formData);
                
                if (result.statusCode === 200) {
                    const data = result.data;
                    const token = data.token;
                    if (token) {
                        // Set user info for GA
                        trackEvent('User', 'Login', 'Successful');
                        setUserInfo(email);  // Set user email for GA tracking

                        if (rememberMe) {
                            const rememberMeData = {
                                email: email,
                                password: password,
                                rememberMe: true
                            };
                            localStorage.setItem('rememberMeData', JSON.stringify(rememberMeData));
                        } else {
                            localStorage.removeItem('rememberMeData');
                        }
                        login(data);
                    } else {
                        setError('Invalid email or password. Please try again.');
                        trackEvent('User', 'Login', 'Failed: Invalid credentials');
                    }
                } else if (result.statusCode === 401 || result.code === 401 || result.statusCode === 400 || result.code === 400) {
                    setError(result.message);
                    trackEvent('User', 'Login', 'Failed: Unauthorized');
                }
            } catch (error) {
                // Use error.message to display the API error message
                setError('Oops! Something went wrong. Please try again later.');
                trackEvent('API', 'Error', error.message);
            } finally {
                setIsButtonLoading(false);
            }            
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='login-main-margin'>
            <div className='login-main-container'>
                <div className="sign-panels">
                    <div className="login">
                    <div className='logo-container'>
                        <img alt="icon" src={getFullUrl('logo.png')} style={{ width: "3rem", height: "3rem" }} />
                        <div className='logo-name'>MoneyLens</div>
                    </div>
                        <div className="title">
                            <span>Sign In</span>
                            <p></p>
                        </div>

                        <Form noValidate validated={validated} onSubmit={handleLogin}>
                            {/* Email input */}
                            <Form.Group controlId="email" className="password-container">
                                <Form.Control
                                    type="email"
                                    placeholder="Email Address *"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    autoComplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your email address.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Password input */}
                            <Form.Group controlId="password" className="password-container">
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password *"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    autoComplete="off"
                                />
                                <i
                                    className={`password-eye fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} // Icon changes based on visibility state
                                    onClick={toggleShowPassword}
                                    aria-hidden="true"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your password.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Error message */}
                            {error && <div className="error-message">{error}</div>}

                            {/* Remember Me checkbox */}
                            <Form.Group controlId="rememberMe" className="remember-me password-container">
                                <Form.Check
                                    type="checkbox"
                                    label="Remember Me"
                                    checked={rememberMe}
                                    onChange={() => setRememberMe(!rememberMe)}
                                />
                            </Form.Group>

                            {/* Submit Button */}
                            <Button className="btn-signin" type="submit" disabled={isButtonLoading}>
                                {isButtonLoading ? 'Signing In...' : 'Sign In'}
                            </Button>

                            {/* Reset password and Signup links */}
                            <span onClick={() => navigate('/resetpassword')} className="btn-reset btn-fade">
                                Reset password<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                            <span onClick={() => navigate('/signup')} className="btn-member btn-fade">
                                Not a member yet? Signup<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </Form>
                    </div>
                </div>
                <div className='right-container'>
                    <div className='right-top'>
                        <div>Welcome Back!</div>
                        <div className='quotes'>Your wealth insights,<br />
                        just a click away.</div>
                    </div>
                    <img src={getFullUrl('images/banner.png')} alt="banner" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Login;
