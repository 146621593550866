import React, {useEffect, useState} from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import { shortFormatRupees } from '../../utils/helpers';
// import { useSidebar } from '../SidebarContext';

const Sidebar = ({toggleSidebar, sideBarData}) => {
    
    // const { sideBarData } = useSidebar();
    // State to manage the "Calculator" menu expansion
    const [isCalculatorOpen, setCalculatorOpen] = useState(false);

    // Toggle Calculator sub-menu
    const toggleCalculator = () => {
        setCalculatorOpen(!isCalculatorOpen);
    };

    useEffect(() => {
        setCalculatorOpen(false)
    }, [toggleSidebar])

  return (
    <div>
        <CDBSidebar textColor="#000000" backgroundColor="#ffffff">
            <CDBSidebarContent className="sidebar-content">
                <CDBSidebarMenu>
                    <NavLink exact='true' to="/networth" activeclassname="activeClicked" onClick={toggleSidebar}>
                        <CDBSidebarMenuItem icon='chart-pie'>
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>Net Worth</span>
                                <span className='beside-menu'>₹{shortFormatRupees(sideBarData?.networth?.curr_val || 0)}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='sub-menu'>Dashboard</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink exact='true' to="/assets" activeclassname="activeClicked" onClick={toggleSidebar}>
                        <CDBSidebarMenuItem icon='seedling'>
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>Assets</span>
                                <span className='beside-menu'>₹{shortFormatRupees(sideBarData?.assets?.curr_val || 0)}</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink exact='true' to="/debts" activeclassname="activeClicked" onClick={toggleSidebar}>
                        <CDBSidebarMenuItem icon="handshake">
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>Debts</span>
                                <span className='beside-menu'>₹{shortFormatRupees(sideBarData?.debts?.curr_val || 0)}</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink>
                    {/* Calculator Menu with Toggle */}
                    <div>
                        <CDBSidebarMenuItem icon="calculator" onClick={toggleCalculator} style={{marginBottom: 'unset'}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className="menu-name">Calculators <i className="fas fa-angle-down" style={{ marginLeft: '7px' }}></i></span>
                            </div>
                        </CDBSidebarMenuItem>

                        {/* Sub-menu items that will toggle */}
                        {isCalculatorOpen && (
                            <div className="calculator-submenu">
                                <NavLink exact="true" to="/calculator/growth" target="_blank" activeclassname="activeClicked">
                                    <span className="calculator-sub-menu">CAGR Calculator</span>
                                </NavLink>
                                <NavLink exact="true" to="/calculator/car-loan" target="_blank" activeclassname="activeClicked">
                                    <span className="calculator-sub-menu">Car Loan Calculator</span>
                                </NavLink>
                                <NavLink exact="true" to="/calculator/home-loan" target="_blank" activeclassname="activeClicked">
                                    <span className="calculator-sub-menu">Home Loan Calculator</span>
                                </NavLink>
                                <NavLink exact="true" to="/calculator/personal-loan" target="_blank" activeclassname="activeClicked">
                                    <span className="calculator-sub-menu">Personal Loan Calculator</span>
                                </NavLink>
                                <NavLink exact="true" to="/calculator/retirement" target="_blank" activeclassname="activeClicked">
                                    <span className="calculator-sub-menu">Retirement Planning</span>
                                </NavLink>
                                <NavLink exact="true" to="/calculator/sip" target="_blank" activeclassname="activeClicked">
                                    <span className="calculator-sub-menu">SIP Calculator</span>
                                </NavLink>
                            </div>
                        )}
                    </div>
                    <NavLink exact='true' to="/support" activeclassname="activeClicked" onClick={toggleSidebar}>
                        <CDBSidebarMenuItem icon="handshake">
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>Support</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink exact='true' to="/feedback" activeclassname="activeClicked" onClick={toggleSidebar}>
                        <CDBSidebarMenuItem icon="comments">
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>FeedBack</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink>
                    {/* <NavLink exact='true' to="/reademail" activeclassname="activeClicked" onClick={toggleSidebar}>
                        <CDBSidebarMenuItem icon="comments">
                            <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className='menu-name'>Read Email</span>
                            </div>
                        </CDBSidebarMenuItem>
                    </NavLink> */}
                </CDBSidebarMenu>
            </CDBSidebarContent>
        </CDBSidebar>
    </div>
  );
};

export default Sidebar;