import React, { useRef, useEffect } from 'react';
import { shortFormatRupees } from '../utils/helpers';
import * as d3 from 'd3'; // Import D3 for rendering

const CalculatorGraph = ({ data, chartLabel, tooltipLabel, module }) => {
  const chartRef = useRef(null);

  useEffect(() => {

    // Ensure SVG is cleared before re-rendering
    const svg = d3.select(chartRef.current);
    svg.selectAll('*').remove();

    const svgWidth = chartRef.current.parentElement.offsetWidth;
    const svgHeight = Math.min(svgWidth / 2, 400); // Ensure responsive height
    const margin = { top: 20, right: 30, bottom: 40, left: 100 }; // Increased bottom margin for labels
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    const g = svg
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Data processing
    let stackData = [];
    const expensesCalculation = (data) => {
      switch (module) {
        case 'sip':
          return data.yearlyInvestment;
        case 'homeLoan':
          return data.yearlyPrincipal;
        default:
          return data.months.reduce((acc, month) => acc + parseFloat(month.expense), 0)
      }
    }
    const calculateCorpus = (data) => {
      switch (module) {
        case 'sip':
          return parseFloat(data.remainingCorpus);
        case 'homeLoan':
          return parseFloat(data.yearlyInterest);
        default:
          return parseFloat(data.remainingCorpus)
      }
    }
    stackData = data.map((d) => {
      const lastMonth = d.months[d.months.length - 1]; // Get the last month
      return {
        yearNo: lastMonth.yearNo, // Use the last yearNo
        year: d.year, // Keep year for tooltip
        expenses: expensesCalculation(d),
        corpus: (module === 'homeLoan') ? calculateCorpus(d) : calculateCorpus(lastMonth),
      };
    });

    // Create scales
    const x = d3
      .scaleBand()
      .domain(stackData.map((d) => d.yearNo))
      .range([0, width])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(stackData, (d) => d.expenses + d.corpus)])
      .nice()
      .range([height, 0]);

    const color = d3.scaleOrdinal().range(['#5e75df', '#f57328']); // Color for corpus and expenses

    const stack = d3.stack().keys(['corpus', 'expenses']);
    const stackedSeries = stack(stackData);

    // Tooltip setup
    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('background', 'rgba(0, 0, 0, 0.8)')
      .style('color', '#fff')
      .style('padding', '5px')
      .style('border-radius', '5px')
      .style('pointer-events', 'none');

    // Append bars
    const bars = g.append('g')
      .selectAll('g')
      .data(stackedSeries)
      .enter()
      .append('g')
      .attr('fill', (d) => color(d.key))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.data.yearNo)) // Updated x position
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => y(d[0]) - y(d[1]))
      .attr('width', x.bandwidth())
      .on('mouseover', (event, d) => {
        const total = d.data.expenses + d.data.corpus;
        const expensesPercent = ((d.data.expenses / total) * 100).toFixed(2);
        const corpusPercent = ((d.data.corpus / total) * 100).toFixed(2);

        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(`${`Year: ${d.data.year}`} <br/>
            ${tooltipLabel[0]}: ₹${shortFormatRupees(d.data.expenses)} (${expensesPercent}%)<br/>
            ${tooltipLabel[1]}: ₹${shortFormatRupees(d.data.corpus)} (${corpusPercent}%)`)
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mousemove', (event) => {
        tooltip
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', () => {
        tooltip.transition().duration(500).style('opacity', 0);
      });

    // Append axes
    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    g.append('g').attr('class', 'axis axis--y').call(d3.axisLeft(y));

  }, [data]); // Make sure this triggers on data change

  return (
    <div>
      <div className="stacked-bar-chart">
        <svg ref={chartRef}></svg>
        <div className="chart-labels">
            <div className="label">
                <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#f57328', marginRight: '5px' }}></span> {chartLabel[1]}
            </div>
            <div className="label">
                <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#5e75df', marginRight: '5px' }}></span> {chartLabel[0]}
            </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorGraph;
