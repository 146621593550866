import React, { useState, useEffect, useRef } from 'react';
import '../css/Calculator.css';
import SliderWithInput from './SliderWithInput';
import CalculatorPieChart from './CalculatorPieChart';
import Select from 'react-select'; // Import react-select for searchable dropdown
import { formatRupees } from '../utils/helpers';

import CalculatorGraph from './CalculatorGraph';
import CalculatorTable from './CalculatorTable';

// Functional component for Retirement Calculator
const SIPLumpsum = ({onBackToSIP}) => {
    
    // State variables for the calculator inputs
    const [investment, setInvestment] = useState(10000); // Monthly amount
    const [durationInYear, setDurationInYear] = useState(10);
    const [returnRate, setReturnRate] = useState(12); // Return rate
    const [totalInvested, setTotalInvested] = useState(0);
    const [growthAmount, setGrowthAmount] = useState(0);
    const [futureValue, setFutureValue] = useState(0); 

    const [inflationFlag, setInflationFlag] = useState(false);
    const [inflationRate, setInflationRate] = useState(6);
    const [graphData, setGraphData] = useState([]);

    const chartLabel = ['Total Future Value', 'Principal Amount'];
    const tooltipLabel = ['Principal Amount', 'Total Future Value'];

    const [afterRetirementFlowType, setAfterRetirementFlowType] = useState('graph');
    
    // Function to calculate Future Value (FV)
    const calculateFV = () => {
        // Calculate future value with compound interest for lumpsum investment
        const annualRate = returnRate / 100 / 12; // Convert to decimal
        let futureVal = investment * Math.pow(1 + annualRate, durationInYear * 12); // Future value calculation
        
        const inflationRateMonthly = Math.pow(1 + inflationRate / 100, 1 / 12) - 1;
        // Adjust future value for inflation if inflationFlag is true
        for (let month = 0; month < durationInYear * 12; month++) { 
            // if (inflationFlag) {
            //     const inflationAdjustment = Math.pow(1 + inflationRate / 12 / 100, durationInYear * 12); // Adjust based on the investment duration
            //     futureVal /= inflationAdjustment; // Adjust future value for inflation
            // }
            if (inflationFlag) {
                futureVal /= (1 + inflationRateMonthly);
            }
        }
        // Return the future value
        return futureVal;
    };
    

    const calculateLumpsum = () => {
        let investedAmount = investment; // Lumpsum investment amount
        
        // Calculate future value using the calculateFV function
        let futureVal = calculateFV(); // Assuming calculateFV is defined as shown previously
    
        // If returnRate is 0, future value equals invested amount, and growth is 0
        let growth = 0;
        if (returnRate !== 0) {
            // Calculate growth based on future value and invested amount
            growth = futureVal - investedAmount; 
        }
    
        // Update state values
        setTotalInvested(Math.round(investedAmount)); // Round invested amount
        setFutureValue(Math.round(futureVal)); // Round future value
        setGrowthAmount(Math.round(growth)); // Round growth amount
    };
    
    const calculateGraphTable = () => {
        const rateMonthly = returnRate / 100 / 12; // Monthly return rate
        const yearlyData = []; // Array to store year-wise data
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const durationInMonth = durationInYear * 12;
        let totalFutureValue = investment; // Start with the lump sum investment
        const currentYear = new Date().getFullYear();
        const inflationRateMonthly = Math.pow(1 + inflationRate / 100, 1 / 12) - 1;

        // Loop through the total duration in months
        for (let month = 0; month < durationInMonth; month++) {
            // Calculate investment growth based on the lump sum
            totalFutureValue *= (1 + rateMonthly);

            // Adjust totalFutureValue for inflation if inflationFlag is true
            if (inflationFlag) {
                totalFutureValue /= (1 + inflationRateMonthly);
            }

            // Calculate current year and month within that year
            const yearIndex = Math.floor(month / 12);
            const monthInYear = month % 12;

            // Initialize new year data if it doesn't exist
            if (!yearlyData[yearIndex]) {
                yearlyData[yearIndex] = { 
                    year: currentYear + yearIndex, 
                    months: [],
                    yearlyInvestment: Math.round(investment) // Total initial investment for the year
                };
            }

            // Store month-wise data within each year
            yearlyData[yearIndex].months.push({
                yearNo: yearIndex + 1,
                yearName: currentYear + yearIndex,
                month: monthNames[monthInYear],
                expense: Math.round(investment), // Constant expense as it's a lump sum
                remainingCorpus: Math.round(totalFutureValue) // Total future value after growth
            });
        }
        
        return yearlyData; // Return data structured for table or graph display
    };

    
    const calculatePieChartData = () => {
        let principalAmount = investment; // Lumpsum investment amount
        
        // Calculate future value using the calculateFV function
        let futureVal = calculateFV(); // Assuming calculateFV is defined as shown previously
    
        // If returnRate is 0, future value equals invested amount, and growth is 0
        let growth = 0;
        if (returnRate !== 0) {
            // Calculate growth based on future value and invested amount
            growth = futureVal - principalAmount; 
        }
    
        // Return the results for the pie chart
        return [
            { label: "Principal Amount", count: Math.round(principalAmount) },
            { label: "Interest Amount", count: Math.round(growthAmount) }
        ];
    };
    
    
    // Generate the year-wise and month-wise data for the graph and table
    
    const pieChartData = calculatePieChartData();
    
    useEffect(() => {
        calculateLumpsum();
        const data = calculateGraphTable();
        setGraphData(data)
    }, [
        investment,
        durationInYear,
        returnRate,
        growthAmount,
        futureValue,
        inflationFlag,
        inflationRate
    ]);

    const handleTabSelect = (type) => {
      setAfterRetirementFlowType(type);
    };

    const inflationOptions = [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' },
    ];
    return (
        <>
            <div className='retirement-container container sip-container' style={{marginTop: 'unset'}}>
                <div className='retirement-row'>
                    <div className='retirement-left' style={{paddingTop: 'unset'}}>
                        <h1 className='retirement-heading'>SIP Calculator</h1>
                        <div className="tabs-wrapper" style={{paddingTop: '1rem'}}>
                            <div className='tab' onClick={onBackToSIP}> SIP </div>
                            <div className='tab active' onClick={null}> Lumpsum </div>
                        </div>
                        <SliderWithInput onChange={setInvestment} label={'Total Investment'} inputLabel={'₹'} defaultValue={investment} min={0} max={10000000} />
                        <SliderWithInput onChange={setReturnRate} label={'Expected Return Rate (p.a)'} inputLabel={'%'} defaultValue={returnRate} min={0} max={20} step={1} allowDecimal={true}/>
                        <SliderWithInput onChange={setDurationInYear} label={'Time Period'} inputLabel={'Years'} defaultValue={durationInYear} min={0} max={50} step={1} allowDecimal={true}/>
                        <div className='slider-input-container' style={{marginBottom: '2.3rem'}}>
                            <label className="slider-label">Calculate with Inflation</label>
                            <Select
                                className='select-company-name'
                                options={inflationOptions}
                                placeholder="Calculate with Inflation"
                                onChange={(option) => setInflationFlag(option ? option.value : false)} // Update transaction type
                                value={inflationOptions.find(option => option.value === inflationFlag) || inflationOptions.find(option => option.value === setInflationFlag(false))}
                            />
                        </div>
                        {inflationFlag && 
                            <SliderWithInput onChange={setInflationRate} label={'Inflation Rate'} inputLabel={'%'} defaultValue={inflationRate} min={0} max={20} step={1} allowDecimal={true}/>
                        }
                    </div>
                    <div className='right-border sip-lumpsum'></div>
                    <div className='retirement-right'>
                        <CalculatorPieChart 
                            pieChartData = {pieChartData}
                        />
                        <div>
                            <div className='right-value-container'>
                            <span className='value-title'>Total Amount Invested:</span> 
                            <span className='right-value'>₹ {formatRupees(totalInvested)}</span>
                            </div>
                            <div className='right-value-container'>
                            <span className='value-title'>Total Interest:</span>
                            <span className='right-value'>₹ {formatRupees(growthAmount)}</span>
                            </div>
                            <div className='right-value-container'>
                            <span className='value-title'>Total Future Value:</span>
                            <span className='right-value'>₹ {futureValue >= 0 ? formatRupees(futureValue) : '0'}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='retirement-row'>
                    <div className='show-table-graph-container'>
                        <h4 className='graph-heading'>Lumpsum Growth</h4>
                        <div className="toggle-button-group">
                            <button
                                className={`toggle-button ${afterRetirementFlowType === 'graph' ? 'active' : ''}`}
                                onClick={() => handleTabSelect('graph')}
                            >
                                Graph
                            </button>
                            <button
                                className={`toggle-button ${afterRetirementFlowType === 'table' ? 'active' : ''}`}
                                onClick={() => handleTabSelect('table')}
                            >
                                Table
                            </button>
                        </div>
                        {/* Conditional Rendering based on selected tab */}
                        {afterRetirementFlowType === 'table' ? (
                            <CalculatorTable data={graphData} heading2={'Principal Amount'} heading3={'Total Future value'} module={'sip'}/>
                        ) : (
                            <CalculatorGraph data={graphData} chartLabel={chartLabel} tooltipLabel={tooltipLabel} module={'sip'}/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SIPLumpsum;
