import React, { useState, useEffect } from 'react';
import './css/Login.css';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFullUrl, apiRequest, setCookie } from './utils/helpers';
import { apiConfig } from './ApiConfig';
import { trackPageView, trackEvent } from './utils/googleAnalytics';
import Footer from './Footer';

const Signup = () => {
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle password visibility
    const [agree, setAgree] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [error, setError] = useState(null);
    const [agreeError, setAgreeError] = useState(null);
    
    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const handleSignup = async (e) => {
        e.preventDefault();
        setError(null);
        setIsButtonLoading(true);
        const form = e.currentTarget;
        
        // Track event for form submission attempt
        trackEvent('Sign Up Form', 'Attempt', 'User tried to sign up');
        
        // Check if form is valid and if the "Agree" checkbox is checked
        if (form.checkValidity() === false || !agree || (password && confirmPassword && password !== confirmPassword)) {
            e.stopPropagation();
            setValidated(true);
            
            // Set errors if necessary
            setAgreeError(!agree ? "You must agree to the terms and conditions before submitting." : null);
            if (password && confirmPassword) {
                if (password !== confirmPassword) {
                    setError('Passwords do not match.');
                } else {
                    setError(null); // Clear error if passwords match
                }
            } else {
                setError(null); // Clear error if one or both fields are empty
            }
            
            setIsButtonLoading(false);
            // Track event for form validation failure
            trackEvent('Sign Up Form', 'Failure', 'User failed to sign up due to validation errors');
            return;
        }else {
            setAgreeError(null);
            setError(null);
        }
        setValidated(true);
        try {
            const formData = {
                name: fullName,
                email: email,
                password: password
            };
        
            // Call the API to post the form data
            const result = await apiRequest(apiConfig.SIGNUP, 'POST', formData);
            
            if (result.statusCode === 200) {
                setCookie('emailVerify', email, 7);
                navigate('/verifyotp');

                // Track event for successful signup
                trackEvent('Sign Up Form', 'Success', 'User successfully signed up');
            } else if (result.statusCode === 401 || result.statusCode === 400) {
                setError(result.message);

                // Track event for failed signup due to API error
                trackEvent('Sign Up Form', 'Failure', `Signup failed:${result.statusCode}: ${result.message}`);
            }
        } catch (error) {
            // Use error.message to display the API error message
            setError('Oops! Something went wrong. Please try again later.');

            // Track event for failed signup due to network or server error
            trackEvent('Sign Up Form', 'Failure', `Signup failed: ${error.message}`);
        } finally {
            setIsButtonLoading(false);
        }            
    };
    return (
        <div className='login-main-margin'>
            <div className='signup-main-container'>
                <div className="sign-panels">
                    <div className="signup">
                        <div className='logo-container'>
                            <img alt="icon" src={getFullUrl('logo.png')} style={{ width: "3rem", height: "3rem" }} />
                            <div className='logo-name'>MoneyLens</div>
                        </div>
                        <div className="title">
                            <span>Sign Up</span>
                            <p className='sub-title'>Create a new account</p>
                        </div>

                        {/* <div>
                            <a href="#" className="btn-face"><i className="fa fa-facebook" aria-hidden="true"></i> Facebook</a>
                            <a href="#" className="btn-twitter"><i className="fa fa-twitter" aria-hidden="true"></i> Twitter</a>
                        </div>

                        <div className="or"><span>OR</span></div> */}

                        <Form noValidate validated={validated} onSubmit={handleSignup}>
                            {/* Full Name input */}
                            <Form.Group controlId="fullName" className='password-container'>
                                <Form.Control
                                    type="text"
                                    placeholder="Full Name *"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your full name.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Email Address input */}
                            <Form.Group controlId="email" className='password-container'>
                                <Form.Control
                                    type="email"
                                    placeholder="Email Address *"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Password input */}
                            <Form.Group controlId="password" className='password-container'>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password *"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <i
                                    className={`password-eye fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} // Icon changes based on visibility state
                                    onClick={toggleShowPassword}
                                    aria-hidden="true"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a password.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Confirm Password input */}
                            <Form.Group controlId="confirmPassword" className='password-container signup-password-container'>
                                <Form.Control
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm Password *"
                                    value={confirmPassword}
                                    className={`${error ? 'otp-error' : ''}`}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <i
                                    className={`password-eye fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`} // Icon changes based on visibility state
                                    onClick={toggleShowConfirmPassword}
                                    aria-hidden="true"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your confirm password.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {error && <div className="error-message">{error}</div>}
                            {/* Agree to terms & conditions checkbox */}
                            <Form.Group controlId="agree" className='password-container'>
                                <Form.Check
                                    type="checkbox"
                                    label="Agree to the terms & conditions"
                                    checked={agree}
                                    onChange={() => setAgree(!agree)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    You must agree before submitting.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {agreeError && <div className="error-message">{agreeError}</div>}
                            {/* Submit Button */}
                            <Button type="submit" className="btn-signin btn-primary" disabled={isButtonLoading}>
                                {isButtonLoading ? 'Signing Up...' : 'Sign Up'}
                            </Button>

                            {/* Sign In Link */}
                            <span onClick={() => navigate('/login')} className="btn-login btn-fade">
                                Already have an account? Sign In<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </Form>
                    </div>
                </div>
                <div className='right-container'>
                    <div className='right-top'>
                        <div>Namaste, India!</div>
                        <div className='quotes'>Your financial journey, Simplified!<br />
                        Track. Grow. Thrive.</div>
                    </div>
                    <img src={getFullUrl('images/banner.png')} alt="banner" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Signup;
