import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { apiConfig } from '../ApiConfig';
import { apiRequest, showErrorMessage, showSuccessMessage } from '../utils/helpers';
import { trackEvent } from '../utils/googleAnalytics';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import '../css/feedback.css';

const Support = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        subject: '',
        description: '',
    });
    const [files, setFiles] = useState([]); // State to manage file uploads
    const [fileError, setFileError] = useState(''); // State to show file errors
    const [validated, setValidated] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleDescriptionChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            description: value,
        }));
    };

    const handleFileChange = (e) => {
        const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
        const selectedFiles = Array.from(e.target.files);
        const validFiles = selectedFiles.filter(
            (file) => file.size <= MAX_FILE_SIZE_BYTES
        ); // Filter files <= 5MB

        if (validFiles.length + files.length > 5) {
            setFileError('Maximum of 5 files are allowed.');
            document.getElementById('fileUpload').value = ''; 
            return;
        }

        setFileError(''); // Clear error if valid
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    };

    const removeFile = (index) => {
        setFiles((prevFiles) => {
            const newFiles = prevFiles.filter((_, i) => i !== index);
            if (newFiles.length === 0) {
                // Clear file input if no files are left
                document.getElementById('fileUpload').value = ''; 
            }
            return newFiles;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            setIsButtonLoading(false);
            return;
        }

        try {
            const formDataToSubmit = new FormData();
            formDataToSubmit.append('subject', formData.subject);
            formDataToSubmit.append('details', formData.description);

            // Append files if any
            files.forEach((file) => {
                formDataToSubmit.append('attachments', file);
            });
            
            trackEvent('Support', 'request', 'Save ticket data');
            // Call the API to post the form data
            const result = await apiRequest(apiConfig.TICKET, 'POST', formDataToSubmit);

            if (result.statusCode === 200) {
                showSuccessMessage('Ticket has been created successfully. Please follow updates under "My Tickets"');

                // Reset form to initial state
                setFormData({
                    subject: '',
                    description: '',
                });
                setFiles([]);
                setFileError('');
                setValidated(false);
                trackEvent('Support', 'success', 'Successfully saved ticket data');
            } else {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('Support', 'error', `Error`);
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
            trackEvent('Support', 'error', `Error: ${error.message}`);
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <div className="container card" style={{height: '100%'}}>
            <div className="support-container">
                <div className="card-body p-3">
                    <div className='ticket-heading-container'>
                        <h4>Create New Ticket</h4>
                        <div className='my-ticket' onClick={() => navigate('/support/ticket')}>My Tickets</div>
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="us-stock">
                        <Form.Group controlId="subject">
                            <Form.Label>Subject *</Form.Label>
                            <Form.Control
                                type="text"
                                name="subject"
                                placeholder="Enter subject"
                                value={formData.subject}
                                autoComplete="off"
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter the subject.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="description" className='support-description'>
                            <Form.Label>How can we help you? *</Form.Label>
                            <ReactQuill
                                theme="snow"
                                value={formData.description}
                                onChange={handleDescriptionChange}
                                placeholder="Please provide a detailed description of your issue or problem. The more information you give, including context and background, the better we can assist you."
                                className='editor' // Adjust the height as needed
                            />
                            {validated && !formData.description && (
                                <div className="text-danger mt-2">Please enter the details.</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="fileUpload">
                            <Form.Label>Attach files</Form.Label>
                            <Form.Control
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                style={{marginBottom: '0'}}
                            />
                            <Form.Text className="text-muted">
                                Maximum of 5 files allowed. Each file must be less than 5MB.
                            </Form.Text>
                            {files.length > 0 && (
                                <div className="mt-2">
                                    <Form.Label>Selected Files ({files.length}):</Form.Label>
                                    <ul>
                                        {files.map((file, index) => (
                                            <li key={index}>
                                                {file.name}
                                                <i className="fas fa-trash delete-company" style={{marginLeft: '.5rem'}} onClick={() => removeFile(index)}>
                                                </i>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {fileError && <div className="text-danger mt-2">{fileError}</div>}
                            
                        </Form.Group>
                        <Form.Group controlId="feedBackSubmit" className="btn-container" style={{marginTop: '1rem'}}>
                            <Button variant="primary" type="submit" disabled={isButtonLoading}>
                                {isButtonLoading ? 'Creating...' : 'Create Ticket'}
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Support;
