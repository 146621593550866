// const BEECEPTER_URL = "https://moneylens.proxy.beeceptor.com"; 
let BASE_URL;

if (window.location.hostname === "app.moneylens.in") {
  BASE_URL = "https://moneylens-prod-435836342841.asia-south2.run.app";
} else {
  BASE_URL = "https://moneylens-dev-435836342841.asia-south2.run.app"; // Fallback to dev as default
}

export const apiConfig = {
  LOGIN: `${BASE_URL}/api/user/login`,  // login API

  SIGNUP: `${BASE_URL}/api/user/register`,  // signup API

  VERIFYOTP: `${BASE_URL}/api/user/verify-otp`,  // Verify OTP API
  SENDOTP: `${BASE_URL}/api/user/send-otp`,  // Resend OTP API
  RESETPASSWORD: `${BASE_URL}/api/user/reset-password`,  // Reset Password API
  UPDATEPROFILE: `${BASE_URL}/api/user/update-profile`,  // Update profile API
  UPDATEPROFILEPIC: `${BASE_URL}/api/user/update-profile_pic`,  // Update profile pic API

  NETWORTH: `${BASE_URL}/api/pm/networth`, // Networth API
  INDIASTOCKSUMMARY: `${BASE_URL}/api/pm/assets/indiastocks/summary`,      // India Stock Summary API
  INDIASTOCKTRANSACTIONS: `${BASE_URL}/api/pm/assets/indiastocks/transactions`,// India Stock Transactions API
  INDIASTOCKDIRECTORY: `${BASE_URL}/api/pm/assets/indiastocks/directory`,    // India Stock Company API
  INDIASTOCKINFO: `${BASE_URL}/api/pm/assets/indiastocks/info`, // India Stock Info API
  INDIASTOCKANALYTICS: `${BASE_URL}/api/pm/assets/indiastocks/analytics`, // India Stock Analytics API

  USSUMMARY: `${BASE_URL}/api/pm/assets/usstocks/summary`,      // US Stock Summary API
  USTRANSACTIONS: `${BASE_URL}/api/pm/assets/usstocks/transactions`,// US Stock Transactions API
  USDIRECTORY: `${BASE_URL}/api/pm/assets/usstocks/directory`,    // US Stock Company API
  USINFO: `${BASE_URL}/api/pm/assets/usstocks/info`, // US Stock Info API
  USANALYTICS: `${BASE_URL}/api/pm/assets/usstocks/analytics`, // US Stock Analytics API

  REALESTATESUMMARY: `${BASE_URL}/api/pm/assets/realestate/summary`,      // Real Estate Summary API
  ADDPROPERTY: `${BASE_URL}/api/pm/assets/realestate/property`,  // Real Estate Add Property API
  REALESTATEANALYTICS: `${BASE_URL}/api/pm/assets/realestate/analytics`, // Real Estate Analytics API
  REALESTATETRANSACTIONS: `${BASE_URL}/api/pm/assets/realestate/transactions`,// Real Estate Transactions API

  PRECIOUSMETALSSUMMARY: `${BASE_URL}/api/pm/assets/preciousmetals/summary`,      // Precious Metals Summary API
  PRECIOUSMETALSANALYTICS: `${BASE_URL}/api/pm/assets/preciousmetals/analytics`, // Precious Metals Analytics API
  PRECIOUSMETALSTRANSACTIONS: `${BASE_URL}/api/pm/assets/preciousmetals/transactions`,// Precious Metals Transactions API
  PRECIOUSMETALSTRANSACTIONSINFO: `${BASE_URL}/api/pm/assets/preciousmetals/info`,// Precious Metals Transactions API

  CRYPTOCURRENCYSUMMARY: `${BASE_URL}/api/pm/assets/crypto/summary`,      // Crypto Currency Summary API
  CRYPTOCURRENCYTRANSACTIONS: `${BASE_URL}/api/pm/assets/crypto/transactions`,// Crypto Currency Transactions API
  CRYPTOCURRENCYDIRECTORY: `${BASE_URL}/api/pm/assets/crypto/directory`,    // Crypto Currency Company API
  CRYPTOCURRENCYINFO: `${BASE_URL}/api/pm/assets/crypto/info`, // Crypto Currency Info API
  CRYPTOCURRENCYANALYTICS: `${BASE_URL}/api/pm/assets/crypto/analytics`, // Crypto Currency Analytics API

  MFSUMMARY: `${BASE_URL}/api/pm/assets/mf/summary`,      // Mutual Funds Summary API
  MFTRANSACTIONS: `${BASE_URL}/api/pm/assets/mf/transactions`,// Mutual Funds Transactions API
  MFDIRECTORY: `${BASE_URL}/api/pm/assets/mf/directory`,    // Mutual Funds Company API
  MFINFO: `${BASE_URL}/api/pm/assets/mf/info`, // Mutual Funds Info API
  MFANALYTICS: `${BASE_URL}/api/pm/assets/mf/analytics`, // Mutual Funds Analytics API

  PRIVATEEQUITYSUMMARY: `${BASE_URL}/api/pm/assets/privateequity/summary`,      // Private Equity Summary API
  PRIVATEEQUITYTRANSACTIONS: `${BASE_URL}/api/pm/assets/privateequity/transactions`,// Private Equity Transactions API
  PRIVATEEQUITYDIRECTORY: `${BASE_URL}/api/pm/assets/privateequity/directory`,    // Private Equity Company API
  PRIVATEEQUITYINFO: `${BASE_URL}/api/pm/assets/privateequity/info`, // Private Equity Info API
  PRIVATEEQUITYANALYTICS: `${BASE_URL}/api/pm/assets/privateequity/analytics`, // Private Equity Analytics API

  MFCURRVAL: `${BASE_URL}/api/pm/assets/mf/curr_val`, // Mutual Funds Current value API For Assets Page
  INDIASTOCKSCURRVAL: `${BASE_URL}/api/pm/assets/indiastocks/curr_val`, // Mutual Funds Current value API For Assets Page
  USSTOCKSCURRVAL: `${BASE_URL}/api/pm/assets/usstocks/curr_val`, // Mutual Funds Current value API For Assets Page
  PRIVATEEQUITYCURRVAL: `${BASE_URL}/api/pm/assets/privateequity/curr_val`, // Mutual Funds Current value API For Assets Page
  CRYPTOCURRENCYCURRVAL: `${BASE_URL}/api/pm/assets/crypto/curr_val`, // Mutual Funds Current value API For Assets Page
  REALESTATECURRVAL: `${BASE_URL}/api/pm/assets/realestate/curr_val`, // Mutual Funds Current value API For Assets Page
  PRECIOUSMETALSCURRVAL: `${BASE_URL}/api/pm/assets/preciousmetals/curr_val`, // Mutual Funds Current value API For Assets Page

  FOREXRATE: `${BASE_URL}/api/pm/forex_rate`,

  FEEDBACK: `${BASE_URL}/api/pm/support/feedback`,

  TICKET: `${BASE_URL}/api/pm/support/tickets`,

  DUMMY: `${BASE_URL}/api/user/dummy`
  // Add more API endpoints as needed
};
