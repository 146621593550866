import React, { useEffect, useState, useMemo } from "react";
import { formatCurrency } from "../utils/helpers";
import classNames from 'classnames';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

// Downsampling function to limit the number of data points rendered
const downsampleData = (data, maxPoints = null) => {
    if (!data || data.length === 0) return [];

    // Ensure data is sorted by date in ascending order
    const sortedData = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
    // Extract the latest maxPoints dates dynamically
    const filteredData = maxPoints ? sortedData.slice(-maxPoints) : sortedData;

    return filteredData;
};


function ApexChart({ baseTrend, referenceTrend, baseWeeklyTrend, referenceWeeklyTrend, referenceIndex = 'Nifty 50', baseIndex = 'Returns', module }) {
    const [selectedTab, setSelectedTab] = useState("5D");

    const [graphLineColor, setGraphLineColor] = useState('#34a853');
    const [graphMiddleColor, setGraphMiddleColor] = useState('#b8e1c3');

    const [profitLossClass, setProfitLossClass] = useState('');
    const [rotateClass, setRotateClass] = useState('');

    const [absoluteValue, setAbsoluteValue] = useState(0);
    const [totalPercentage, setTotalPercentage] = useState('');

    const [niftyPercentage, setNiftyPercentage] = useState(0);
    const [niftyProfitLossClass, setNiftyProfitLossClass] = useState('');
    const [niftyRotateClass, setNiftyRotateClass] = useState('');
    const tabs = ['5D', '1M', '3M', '6M', '1Y', '3Y', '5Y', 'MAX'];
    // Determine which trend data to use based on the selected tab
    const isLongTerm = useMemo(() => ["3Y", "5Y", "MAX"].includes(selectedTab), [selectedTab]);

    const currentGraphData = useMemo(() => {
        return (isLongTerm && baseWeeklyTrend?.length > 0) ? baseWeeklyTrend : (baseTrend?.length > 0 ? baseTrend : []);
    }, [baseWeeklyTrend, baseTrend, isLongTerm]);

    const currentReferenceData = useMemo(() => {
        return (isLongTerm && referenceWeeklyTrend?.length > 0) ? referenceWeeklyTrend : (referenceTrend?.length > 0 ? referenceTrend : []);
    }, [referenceWeeklyTrend, referenceTrend, isLongTerm]);

    // Memoized downsampled data
    const downsampledGraphData = useMemo(() => downsampleData(currentGraphData), [currentGraphData]);
    const downsampledReferenceData = useMemo(() => downsampleData(currentReferenceData), [currentReferenceData]);

    // Memoize the series data
    const series = useMemo(() => {
        if (!downsampledGraphData.length) return [{ name: 'No Data', data: [] }];

        return referenceTrend
            ? [
                  { name: referenceIndex, data: downsampledReferenceData },
                  { name: baseIndex, data: downsampledGraphData },
              ]
            : [{ name: baseIndex, data: downsampledGraphData }];
    }, [downsampledGraphData, downsampledReferenceData, referenceTrend, referenceIndex, baseIndex]);

    const [previousTab, setPreviousTab] = useState(null);
    const [tickAmount, setTickAmount] = useState(0);
    const [labelFormat, setLabelFormat] = useState({});

    // Base options shared across all configurations
    const baseChartOptions = {
        chart: {
            id: 'area-datetime',
            type: 'area',
            height: 350,
            animations: {
                enabled: false
            },
            zoom: { autoScaleYaxis: true, enabled: false },
        },
        colors: referenceTrend ? ['#808080', graphMiddleColor] : [graphMiddleColor],
        annotations: {
            yaxis: [{ y: 30, borderColor: '#999' }],
            xaxis: downsampledGraphData && downsampledGraphData.length > 0
                ? [{ x: downsampledGraphData[downsampledGraphData.length - 1][0], borderColor: '#999', yAxisIndex: 0 }]
                : [], // Empty array if downsampledGraphData is empty
        },
        dataLabels: { enabled: false },
        markers: { size: 0, style: 'hollow' },
        yaxis: {
            title: { text: 'Price' },
            labels: { formatter: (value) => formatCurrency(Math.round(value), module) },
        },
        stroke: {
            curve: 'smooth',
            width: [2, 2],
            colors: referenceTrend ? ['#808080', graphLineColor] : [graphLineColor],
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.5,
                opacityTo: 0.9,
                stops: [0, 100],
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            x: {
                formatter: (value) => {
                    const date = new Date(value);
                    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
                },
            },
            y: {
                formatter: (value) => `${formatCurrency(value, module)}`,
            },
        },
    };
    
    const options = useMemo(() => {
        if (!downsampledGraphData || downsampledGraphData.length === 0) {
            return {
                ...baseChartOptions,
                xaxis: {
                    ...baseChartOptions.xaxis,
                    tickAmount: 0,
                    labels: {
                        formatter: () => '',
                    },
                },
            };
        }

        // Define date format based on selectedTab
        let dateFormat = { month: 'short', day: 'numeric' };
        
        // Format dates and filter for uniqueness
        const formattedDates = downsampledGraphData.map(item => {
            const date = new Date(item[0]);
            return date.toLocaleDateString('en-US', dateFormat);
        });
        const uniqueFormattedDates = Array.from(new Set(formattedDates));
        let newTickAmount = (uniqueFormattedDates.length > 4) ? Math.min(uniqueFormattedDates.length, 3) : (uniqueFormattedDates.length === 2) ? 1 : uniqueFormattedDates.length - 2;

        return {
            ...baseChartOptions,
            xaxis: {
                ...baseChartOptions.xaxis,
                tickAmount: newTickAmount,
                labels: {
                    formatter: (value) => {
                        const date = new Date(value);
                        return date.toLocaleDateString('en-US', dateFormat);
                    },
                },
            },
        };
    }, []);    
    
    let totalAbsPercetage = 0;
    const calculateAbsoluteValueAndPercentage = (entryAbsValue, exitAbsValue) => {
        entryAbsValue = parseFloat(entryAbsValue);
        exitAbsValue = parseFloat(exitAbsValue)
        // Determine if exitValue is greater or smaller than entryValue
        if (exitAbsValue > entryAbsValue) {
            // Calculate percentage increase
            totalAbsPercetage = ((exitAbsValue - entryAbsValue) / entryAbsValue) * 100;
            setRotateClass('');
            setProfitLossClass('profit-color');

            setGraphLineColor('#34a853');
            setGraphMiddleColor('#b8e1c3');
        } else if (exitAbsValue < entryAbsValue) {
            // Calculate percentage decrease
            totalAbsPercetage = ((entryAbsValue - exitAbsValue) / entryAbsValue) * 100;
            setRotateClass('rotate-text');
            setProfitLossClass('loss-color');

            setGraphLineColor('#ea4335');
            setGraphMiddleColor('#f7bab5');
        }
        setTotalPercentage(`${(totalAbsPercetage).toFixed(2)}%`);
        setAbsoluteValue((exitAbsValue - entryAbsValue).toFixed(2));
    }

    const calculateNiftyPercentage = (entryAbsNiftyValue, exitAbsNiftyValue) => {
    
        if (exitAbsNiftyValue > entryAbsNiftyValue) {
          setNiftyPercentage(`${(((exitAbsNiftyValue - entryAbsNiftyValue) / entryAbsNiftyValue) * 100).toFixed(2)}%`);
          setNiftyProfitLossClass('profit-color');
          setNiftyRotateClass('');
        } else if (exitAbsNiftyValue < entryAbsNiftyValue) {
          setNiftyPercentage(`${(((entryAbsNiftyValue - exitAbsNiftyValue) / entryAbsNiftyValue) * 100).toFixed(2)}%`);
          setNiftyProfitLossClass('loss-color');
          setNiftyRotateClass('rotate-text');
        }
    }
    
    // Memoized function outside useEffect
    const findClosestDataPoint = useMemo(() => {
        return (data, date) => {
            if (!data || !date) return null;
            for (let i = 0; i < data.length; i++) {
                if (data[i][0] >= date.getTime()) {
                    return data[i];
                }
            }
            return null;
        };
    }, []);
    
    useEffect(() => {
        if (!downsampledGraphData.length) return;
    
        let latestDataPoint = downsampledGraphData[downsampledGraphData.length - 1];
        let latestDate = latestDataPoint[0];
    
        const calculateZoom = (startDate) => {
            let newLabelFormat;
            switch (selectedTab) {
                case '5D':
                case '1M':
                case '3M':
                case '6M':
                    newLabelFormat = { month: 'short', day: 'numeric' }; // Format: Nov 4
                    break;
                case '1Y':
                    newLabelFormat = { month: 'short', year: 'numeric' }; // Format: Nov 2024
                    break;
                case '3Y':
                case '5Y':
                case 'MAX':
                default:
                    newLabelFormat = { year: 'numeric' }; // Format: 2024
                    break;
            }
            // Format dates and filter for uniqueness
            const formattedDates = downsampledGraphData.map(item => {
                const date = new Date(item[0]);
                return date.toLocaleDateString('en-US', newLabelFormat);
            });
            const uniqueFormattedDates = Array.from(new Set(formattedDates));
            let newTickAmount = (uniqueFormattedDates.length > 4) ? Math.min(uniqueFormattedDates.length, 3) : (uniqueFormattedDates.length === 2) ? 1 : uniqueFormattedDates.length - 2;
            if (selectedTab === '3Y' && newTickAmount > 2) {
                newTickAmount = 2;
            }
            const isSameData = previousTab && JSON.stringify(downsampledGraphData) === JSON.stringify(previousTab.data);
        
            // Get the indices of the previous tab and selected tab
            const previousTabIndex = previousTab ? tabs.indexOf(previousTab.tab) : -1;
            const selectedTabIndex = tabs.indexOf(selectedTab);

            // Check if the transition is a reverse one
            const isReverseTransition = previousTabIndex !== -1 && selectedTabIndex < previousTabIndex;
            
            if (isSameData && !isReverseTransition) {
                // If data is the same, use the previous tickAmount and labelFormat
                newTickAmount = tickAmount;
                newLabelFormat = labelFormat;
            } else {
                // If data is different, update previous tab and state
                setPreviousTab({ tab: selectedTab, data: downsampledGraphData });
                setTickAmount(newTickAmount);
                setLabelFormat(newLabelFormat);
            }
            ApexCharts.exec('area-datetime', 'updateOptions', {
                colors: referenceTrend ? ['#808080', graphMiddleColor] : [graphMiddleColor],
                stroke: {
                    colors: referenceTrend ? ['#808080', graphLineColor] : [graphLineColor],
                },
                xaxis: {
                tickAmount: newTickAmount,
                  labels: {
                    formatter: (value) => {
                      const date = new Date(value);
                      return date.toLocaleDateString('en-US', newLabelFormat);
                    },
                  },
                },
            });
            ApexCharts.exec('area-datetime', 'zoomX', startDate, latestDate);
        };
    
        const handleGraphCalculation = (days, months, years, maxPoints = null) => {
            let referenceIndex = null;
            let niftyReferenceIndex = null;
            let adjustedDate = new Date(latestDate);
        
            // Adjust the date based on the input
            if (months) adjustedDate.setMonth(adjustedDate.getMonth() - months);
            if (years) adjustedDate.setFullYear(adjustedDate.getFullYear() - years);

            // Downsample graph and reference data based on maxPoints before further calculations
            const downsampledGraphDataWithMaxPoints = downsampleData(currentGraphData, maxPoints);
            const downsampledReferenceDataWithMaxPoints = downsampleData(currentReferenceData, maxPoints);

            // Calculate referenceIndex based on the inputs
            if (days) {
                referenceIndex = downsampledGraphDataWithMaxPoints[downsampledGraphDataWithMaxPoints.length - days] || null;
                if (downsampledReferenceDataWithMaxPoints.length) {
                    niftyReferenceIndex = downsampledReferenceDataWithMaxPoints[downsampledReferenceDataWithMaxPoints.length - days] || null;
                }
            } else if (months || years) {
                referenceIndex = findClosestDataPoint(downsampledGraphDataWithMaxPoints, adjustedDate);
                if (downsampledReferenceDataWithMaxPoints.length) {
                    niftyReferenceIndex = findClosestDataPoint(downsampledReferenceDataWithMaxPoints, adjustedDate);
                }
            } else {
                referenceIndex = downsampledGraphDataWithMaxPoints[0] || null;
                if (downsampledReferenceDataWithMaxPoints.length) {
                    niftyReferenceIndex = downsampledReferenceDataWithMaxPoints[0] || null;
                }
            }
        
            // Execute zoom and calculations if referenceIndex exists
            if (referenceIndex) {
                calculateZoom(referenceIndex[0]);
                calculateAbsoluteValueAndPercentage(referenceIndex[1], latestDataPoint[1]);
            }
        
            // Calculate nifty percentage if niftyReferenceIndex exists
            if (niftyReferenceIndex) {
                const latestNiftyDataPoint = downsampledReferenceData[downsampledReferenceData.length - 1];
                calculateNiftyPercentage(niftyReferenceIndex[1], latestNiftyDataPoint[1]);
            }
        };        
    
        switch (selectedTab) {
            case "5D":
                handleGraphCalculation(5, null, null, 5);
                break;
            case '1M':
                handleGraphCalculation(null, 1, null, 31);
                break;
            case "3M":
                handleGraphCalculation(null, 3, null, 100);
                break;
            case '6M':
                handleGraphCalculation(null, 6, null, 200);
                break;
            case '1Y':
                handleGraphCalculation(null, null, 1, 400);
                break;
            case "3Y":
                handleGraphCalculation(null, null, 3, 1200);
                break;
            case "5Y":
                handleGraphCalculation(null, null, 5);
                break;
            case "MAX":
                handleGraphCalculation();
                break;
            default:
                break;
        }
    }, [
        downsampledGraphData,
        downsampledReferenceData,
        selectedTab,
        calculateAbsoluteValueAndPercentage,
        calculateNiftyPercentage
    ]);
    

    return (
        <div id="line-chart">
            <div className="company-info-graph-profit">
                {/* <span className="company-info-graph-profit-number text-bold">₹{formatRupees(absoluteValue)} <span className={classNames('', profitLossClass)}><span className={classNames('', rotateClass)}>▲</span>{totalPercentage}</span>
                </span> */}
                {referenceIndex && 
                <span className="nifty-50-col">
                    {referenceIndex}:
                    <span style={{fontWeight: '700'}} className={classNames('', niftyProfitLossClass)}>&nbsp;
                        <span className={classNames('', niftyRotateClass)}>▲</span>
                        {niftyPercentage}
                    </span>
                </span>
                }
                <span className="this-fund-col">
                    {baseIndex}: 
                    {/* <span className="company-info-graph-profit-number text-bold">&nbsp;₹{shortFormatRupees(absoluteValue)}&nbsp;&nbsp; */}
                    <span className="company-info-graph-profit-number text-bold">
                        <span className={classNames('', profitLossClass)}>&nbsp;
                            <span className={classNames('', rotateClass)}>▲</span>
                            {totalPercentage}
                        </span>
                    </span>
                </span>
                
            </div>
            <div className="company-info-tab-container">
                {tabs.map((tab) => (
                    <span
                        key={tab}
                        className={classNames('company-info-tab', { active: selectedTab === tab })}
                        onClick={() => setSelectedTab(tab)}
                    >
                        {tab}
                    </span>
                ))}
            </div>
            <Chart id="area-date" options={options} series={series} type="area" height={350} />
        </div>
    );
}

export default ApexChart;
