import React, { useState, useEffect } from 'react';
import '../css/Calculator.css';
import SliderWithInput from './SliderWithInput';
import CalculatorPieChart from './CalculatorPieChart';
import { formatRupees } from '../utils/helpers';
import HeaderNoValidation from '../components/HeaderNoValidation';

import CalculatorGraph from './CalculatorGraph';
import LoanCalculatorTable from './LoanCalculatorTable';

import { useAuth } from '../AuthContext';
import Header from '../components/Header';

const LoanCalculator = ({module}) => {
    const {isAuthenticated} = useAuth();

    const [loanAmount, setLoanAmount] = useState(100000);
    const [interestRate, setInterestRate] = useState(6);
    const [durationInYear, setDurationInYear] = useState(10);

    const [monthlyEmi, setMonthlyEmi] = useState(0);
    const [principalAmount, setPrincipalAmount] = useState(0);
    const [interestAmount, setInterestAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [graphData, setGraphData] = useState([]);
    const [afterRetirementFlowType, setAfterRetirementFlowType] = useState('graph');
    const chartLabel = ['Interest Amount', 'Principal Amount'];
    const tooltipLabel = ['Principal Paid', 'Interest Paid'];

    const calculateEmi = () => {
        const monthlyRate = interestRate / 12 / 100;
        const months = durationInYear * 12;
        const emi =
            loanAmount * monthlyRate * Math.pow(1 + monthlyRate, months) /
            (Math.pow(1 + monthlyRate, months) - 1);
        const totalPayment = emi * months;
        const totalInterest = totalPayment - loanAmount;

        setMonthlyEmi(Math.round(emi));
        setPrincipalAmount(loanAmount);
        setInterestAmount(Math.round(totalInterest));
        setTotalAmount(Math.round(totalPayment));
    }

    const calculateGraphTable = () => {
        const periodInMonths = durationInYear * 12;
        const monthlyRate = interestRate / 12 / 100; // Monthly interest rate
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const currentYear = new Date().getFullYear();
        
        let yearlyData = [];
        let remainingBalance = loanAmount; // Start with the full loan amount
    
        // Loop through the total duration in months
        for (let month = 0; month < periodInMonths; month++) {
            // Calculate interest for this month
            const interestForMonth = remainingBalance * monthlyRate;
            
            // EMI is calculated as constant, covering both interest and principal
            const emi = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, periodInMonths)) /
                        (Math.pow(1 + monthlyRate, periodInMonths) - 1);
            
            // Principal paid for this month
            const principalForMonth = emi - interestForMonth;
            
            // Deduct principal payment from remaining balance
            remainingBalance -= principalForMonth;
    
            // Ensure remaining balance does not go negative
            remainingBalance = Math.max(0, remainingBalance);
    
            const yearIndex = Math.floor(month / 12); // Get the year index
            const monthInYear = month % 12; // Get the month within that year
    
            // Initialize new year data if it doesn't exist
            if (!yearlyData[yearIndex]) {
                yearlyData[yearIndex] = {
                    year: currentYear + yearIndex,
                    months: [],
                    yearlyPrincipal: 0,
                    yearlyInterest: 0,
                    yearlyExpense: 0
                };
            }
    
            // Store month-wise data within each year
            yearlyData[yearIndex].months.push({
                yearNo: yearIndex + 1,
                yearName: currentYear + yearIndex,
                month: monthNames[monthInYear],
                principal: Math.round(principalForMonth),
                interest: Math.round(interestForMonth),
                emiAmount: Math.round(principalForMonth + interestForMonth),
                remainingBalance: Math.round(remainingBalance)
            });

            // Accumulate yearly totals
            yearlyData[yearIndex].yearlyPrincipal += Math.round(principalForMonth);
            yearlyData[yearIndex].yearlyInterest += Math.round(interestForMonth);
            yearlyData[yearIndex].yearlyEmi += Math.round(totalAmount);
        }
    
        return yearlyData; // Return the yearly data structured by year and month
    };
    
    const pieChartData = [
        { label: "Principal Amount", count: Math.round(principalAmount) },
        { label: "Interest Amount", count: Math.round(interestAmount) }
    ];

    useEffect(() => {
        calculateEmi();
        const data = calculateGraphTable();
        setGraphData(data)
    }, [loanAmount, interestRate, durationInYear]);

    const handleTabSelect = (type) => {
        setAfterRetirementFlowType(type);
    };

    return (
        <>
            {isAuthenticated ? <Header /> : <HeaderNoValidation />}

            <div className='retirement-container container'>
                <div className='retirement-row'>
                    <div className='retirement-left' style={{paddingTop: 'unset'}}>
                    <h1 className='retirement-heading'>{module} Loan Calculator</h1>
                    <div style={{paddingTop: '1rem'}}>
                        <SliderWithInput 
                            onChange={setLoanAmount} 
                            label={'Loan Amount'} 
                            inputLabel={'₹'} 
                            defaultValue={loanAmount} 
                            min={0} 
                            max={50000000} 
                        />
                        <SliderWithInput 
                            onChange={setInterestRate} 
                            label={'Rate Of Interest (p.a)'} 
                            inputLabel={'%'} 
                            defaultValue={interestRate} 
                            min={0} 
                            max={20} 
                            step={1}
                            allowDecimal={true} 
                        />
                        <SliderWithInput 
                            onChange={setDurationInYear} 
                            label={'Loan Tenure'} 
                            inputLabel={'Years'} 
                            defaultValue={durationInYear} 
                            min={0} 
                            max={50} 
                            step={1}
                            allowDecimal={true} 
                        />
                        </div>
                    </div>
                    <div className='right-border home-loan'></div>
                    <div className='retirement-right'>
                        <CalculatorPieChart pieChartData={pieChartData} module={'growth'}/>
                        <div>
                            <div className='right-value-container'>
                                <span className='value-title'>Monthly EMI:</span> 
                                <span className='right-value'>₹{formatRupees(monthlyEmi)}</span>
                            </div>
                            {/* <div className='right-value-container'>
                                <span className='value-title'>Principal Amount:</span>
                                <span className='right-value'>₹ {formatRupees(principalAmount)}</span>
                            </div>
                            <div className='right-value-container'>
                                <span className='value-title'>Interest Amount:</span>
                                <span className='right-value'>₹ {interestAmount >= 0 ? formatRupees(interestAmount) : '0'}</span>
                            </div> */}
                            <div className='right-value-container'>
                                <span className='value-title'>Total Amount:</span>
                                <span className='right-value'>₹{totalAmount >= 0 ? formatRupees(totalAmount) : '0'}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='retirement-row'>
                    <div className='show-table-graph-container'>
                        <h4 className='graph-heading'>Payment Schedule</h4>
                        <div className="toggle-button-group">
                            <button
                                className={`toggle-button ${afterRetirementFlowType === 'graph' ? 'active' : ''}`}
                                onClick={() => handleTabSelect('graph')}
                            >
                                Graph
                            </button>
                            <button
                                className={`toggle-button ${afterRetirementFlowType === 'table' ? 'active' : ''}`}
                                onClick={() => handleTabSelect('table')}
                            >
                                Table
                            </button>
                        </div>
                        {/* Conditional Rendering based on selected tab */}
                        {afterRetirementFlowType === 'table' ? (
                            <LoanCalculatorTable data={graphData} heading1={'Principal'} heading2={'Interest'} heading3={'EMI'} heading4={'Balance'} module={'sip'}/>
                        ) : (
                            <CalculatorGraph data={graphData} chartLabel={chartLabel} tooltipLabel={tooltipLabel} module={'homeLoan'}/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoanCalculator;