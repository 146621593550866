
import { message } from "antd";

export const isNegative = (num) => {
    return num < 0 || Object.is(num, -0);
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
};

export const formatDateWithTime = (milliseconds) => {
  const date = new Date(milliseconds);
  return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
  }) + ', ' + date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
  });
};

/**
 * Formats a number value as a currency string with commas.
 * 
 * @param {number|string} value - The value to format. Can be a number or a string.
 * @returns {string} - The formatted value as a string, or an empty string if the value is invalid.
 */
export const formatRupees = (value) => {
  if (isEmptyField(value)) {
      return ''; // Return an empty string for invalid values
  }

  // Convert value to a number if it's a string and ensure it's a valid number
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  // Check if the value is a valid number
  if (isNaN(numberValue)) {
      return ''; // Return an empty string if the value is not a valid number
  }

  // Format the number with commas
  return numberValue.toLocaleString('en-IN');
};


/**
 * Formats a number into a string representation with suffixes 
 * like 'K' for thousand, 'L' for lakh, and 'Cr' for crore.
 *
 * @param {number} number - The number to format.
 * @returns {string} - The formatted number as a string with the appropriate suffix.
 */
export const shortFormatRupees = (number) => {
  // Helper function to format numbers with two decimals and remove trailing .00
  const formatNumber = (num) => {
    const fixed = num.toFixed(2);
    return fixed.endsWith('.00') ? fixed.slice(0, -3) : fixed; // Remove .00 if present
  };

  // Store whether the number is negative
  const isNegative = number < 0;
  
  // Work with the absolute value of the number for formatting
  const absoluteNumber = Math.abs(number);

  // Format based on value ranges
  if (absoluteNumber >= 10000000) {
    // Numbers greater than or equal to 1 Crore
    return (isNegative ? '-' : '') + formatNumber(absoluteNumber / 10000000) + ' Cr';
  } else if (absoluteNumber >= 100000) {
    // Numbers between 1 Lakh and 99.99 Lakh
    const formattedNumber = formatNumber(absoluteNumber / 100000);

    // If formattedNumber >= 100, exit this range, multiply by 100000, and call recursively
    if (parseFloat(formattedNumber) >= 100) {
      return shortFormatRupees(parseFloat(formattedNumber) * 100000);
    }
    // Numbers between 100000 and 999999 should display in Lakhs (L)
    // If value is close to 1 Lakh, it should show as 1 L, for values closer to 10 Lakhs, it should show in appropriate decimal.
    return (isNegative ? '-' : '') + formatNumber(absoluteNumber / 100000) + ' L';
  } else if (absoluteNumber >= 1000) {
    // Numbers between 1 Thousand and 99.99 Thousand
    const formattedNumber = formatNumber(absoluteNumber / 1000);

    // If formattedNumber >= 100, exit this range, multiply by 1000, and call recursively
    if (parseFloat(formattedNumber) >= 100) {
      return shortFormatRupees(parseFloat(formattedNumber) * 1000);
    }

    return (isNegative ? '-' : '') + formattedNumber + 'k';
  } else {
    // Numbers below 1000, show as it is
    return (isNegative ? '-' : '') + formatNumber(absoluteNumber);
  }
};

/**
 * Formats a number into a short format (e.g., 1K, 3.4M, 5.6B).
 *
 * @param {number|string} value - The number to format.
 * @param {number} [decimals=1] - The number of decimal places to include for non-integers.
 * @returns {string} - The formatted string or '-' for invalid input.
 */
export const shortFormatUSD = (value, decimals = 2) => {
  const numericValue = Number(value);

  // Check if the value is a valid number
  if (isNaN(numericValue)) return '-';

  const formatter = (num, suffix) => {
    const formatted = num.toFixed(decimals);
    return `${parseFloat(formatted)}${suffix}`; // Removes unnecessary ".00"
  };

  if (Math.abs(numericValue) >= 1e9) {
    return formatter(numericValue / 1e9, 'B'); // Billions
  } else if (Math.abs(numericValue) >= 1e6) {
    return formatter(numericValue / 1e6, 'M'); // Millions
  } else if (Math.abs(numericValue) >= 1e3) {
    return formatter(numericValue / 1e3, 'K'); // Thousands
  }
  return `${parseFloat(numericValue.toFixed(decimals))}`; // Less than 1000
};


export const formatNumberWithTwoDecimals = (value) => {
  // Convert the value to a number
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  // Check if the value is a valid number
  if (isNaN(numberValue)) {
    return ''; // Return an empty string if the value is not a valid number
  }

  // Check if the number has decimals
  const hasDecimals = numberValue % 1 !== 0;

  // Format with commas and decimals if needed
  return numberValue.toLocaleString('en-IN', {
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: 2,
  });
};

/**
 * Checks if a value is null, undefined, or blank.
 * 
 * @param {any} value - The value to check.
 * @returns {boolean} - Returns true if the value is null, undefined, or blank; otherwise, false.
 */
export const isEmptyField = (value) => {
  if (value === null || value === undefined) {
      return true; // Value is null or undefined
  }

  if (typeof value === 'string') {
      return value.trim() === ''; // Check if string is blank
  }

  if (Array.isArray(value)) {
      return value.length === 0; // Check if array is empty
  }

  if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length === 0; // Check if object is empty
  }

  return false; // For numbers and other types, the value is not considered blank
};

export const apiRequest = async (url, method = 'GET', data = null, key = false) => {
  try {
    // Set up headers
    const headers = {};

    // This array is used to ignore the token for login, signup, send OTP, verify OTP, and reset password requests.
    const authPaths = ['/login', '/register', '/verify', '/reset', '/send', '/forex_rate'];
    const isAuthRequest = authPaths.some(path => url.includes(path));

    // Only add the token if it's not a login or signup request
    if (!isAuthRequest) {
      const token = localStorage.getItem('token');

      // Check if token is available for non-auth requests
      if (!token) {
        logout();
        throw new Error('Authorization token is missing');
      }

      // Include the token in the Authorization header
      headers['Authorization'] = `Bearer ${token}`;
    }

    // If the data is a JavaScript object, assume JSON
    if (data && !(data instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
      data = JSON.stringify(data); // Convert the data object to a JSON string
    }

    // Set up the request options
    const options = {
      method,
      headers,
      body: data, // Body will be null for GET requests
    };

    // Make the API call
    const response = await fetch(url, options);

    // Handle 403 Unathorized token
    if(response.status === 403) {
      logout();
    }

    // Handle 401 Unauthorized error separately
    if (response.status === 401) {
      const errorData = await response.json();
      if(url.includes('login')) return errorData.error;
      // Show the response message from the API
      throw new Error(errorData.error.message || 'Unauthorized access');
    }

    // Handle 400 Bad Request
    if (response.status === 400) {
      const errorData = await response.json();
      if(url.includes('login')) return errorData.error;
      throw new Error(errorData.error.message || 'Bad Request'); // Return the response data for a 400 status
    }

    // Handle 500 Internal Server Error
    if (response.status === 500) {
      const errorData = await response.json();
      throw new Error(errorData.error.message || 'Internal Server Error'); // Return the response data for a 400 status
    }

    // Handle other HTTP errors
    if (!response.ok) {
      const errorText = await response.text(); // Capture the error message from the server
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    // Parse the JSON response
    const result = await response.json();
    // Check if status is 201 and result.data is null, then store in localStorage
    if (key && response.status === 201 && result.data === null) {
      localStorage.setItem(key, JSON.stringify(result));
    }
    return (method === 'POST' || method === 'PUT' || method === 'DELETE') ? result : (result.hasOwnProperty('data') ? result.data : result);

  } catch (error) {
    console.error('API request failed:', error.message);
    throw error; // Re-throw the error for further handling
  }
};

const logout = () => {
  const rememberMeData = localStorage.getItem('rememberMeData');
  localStorage.clear();
  clearCacheAndReload();
  if (rememberMeData) {
      localStorage.setItem('rememberMeData', rememberMeData);
  }
  window.location.href = '/login';
}

// Function to call the API and cache the data
const callApiAndCacheData = async (url, method, key, refresh, individualInfo) => {
  try {
    let result = await apiRequest(url, method, null, key);
    // Store fetched data in cache with a dynamic key
    if(key && result !== null) {
      localStorage.setItem(key, JSON.stringify(result));
      setCookie(key, false);
    } else if(!individualInfo) {
      const cachedData = localStorage.getItem(key);
      result = JSON.parse(cachedData);
    } 
    return result;
  } catch (error) {
    if(refresh || individualInfo) showErrorMessage();
    const cachedData = localStorage.getItem(key);
    return JSON.parse(cachedData);
  }
};

// Function to fetch data, either from cache or by calling the API
export const fetchData = async (url, method, key = false, refresh = false, individualInfo = false) => {
  try {
    // Check if individualInfo is true
    if (individualInfo) {
      // Directly call the API and return the result
      return await callApiAndCacheData(url, method, key, refresh, individualInfo);
    }

    // Check if data is available in cache with a dynamic key
    const cachedData = localStorage.getItem(key);
    const isDataDirty = getCookie(key)?.toLowerCase() === "true";
    if (!isDataDirty && cachedData && !refresh) {
      return JSON.parse(cachedData);
    } else {
      // If no cached data, call the API and return the result
      return await callApiAndCacheData(url, method, key, refresh);
    }
  } catch (error) {
      console.error(`Error in api: ${url}`);
    throw error; // Rethrow error for handling in the calling function
  }
};

export const updateDirtyState = (key, value = true) => {
  setCookie(key, value);
}

export const formatDateToInputValue = (timestamp) => {
  const date = new Date(parseInt(timestamp));
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

export const ConvertDateToMilliseconds = (date) => {
  // Convert to Date object
  const dateObj = new Date(date);
  
  // Get milliseconds since epoch
  return dateObj.getTime();
};

export const twoDigitAfterDecimal = (number) => {
  if(!isEmptyField(number)){
    return parseFloat(number).toFixed(2);
  }
}

export const getSortValue = (item, key) => {
  const value = item[key];
  if (Array.isArray(value)) {
    // Handle array values (e.g., 'returns')
    return parseFloat(value[1]); // Assume the first element is a number
  } else if (typeof value === 'string') {
    // Check if the string can be converted to a number
    const numberValue = parseFloat(value);
    
    // If value is a valid number string (like '111'), return it as a number
    if (!isNaN(numberValue)) {
      return numberValue; // Return as a number for sorting
    }
    
    // Otherwise, return the string value (case-insensitive)
    return value.toLowerCase(); // Ensure consistent string comparison
  } else {
    // Handle numeric values (e.g., integers)
    return parseFloat(value); // Return the numeric value directly
  }
};

export const sortData = (data, sortConfig) => {
  return [...data].sort((a, b) => {
    const valueA = getSortValue(a, sortConfig.key);
    const valueB = getSortValue(b, sortConfig.key);

    if (valueA < valueB) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const getFullUrl = (path) => {
  const host = window.location.hostname;
  const baseUrl = host.includes('localhost') ? process.env.REACT_APP_API_URL : `https://${host}`;
  return `${baseUrl}/${path}`;
};

// Function to remove the negative symbol from a value (handles both strings and numbers)
export const removeSign = (value) => {
  // Convert value to a number and return its absolute value if it's not empty
  if (!isEmptyField(value)) {
    return Math.abs(parseFloat(value).toFixed(2));
  }
};

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "expires=" + date.toUTCString() + "; ";
  }
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length);
  }
  return null;
}

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const downloadFile = (fileUrl) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = ''; // Ensures the original file name is used
  document.body.appendChild(link); // Append to body for compatibility
  link.click(); // Trigger the download
  document.body.removeChild(link); // Clean up after download
};

export const showErrorMessage = (msg) => {
  message.error({
    content: msg || 'Oops! Unable to refresh data. Please try again later.',
    duration: 1, // Duration in seconds
  });
}

export const showSuccessMessage = (msg) => {
  message.success({
    content: msg || 'Success! Your data has been saved.',
    duration: 2, // Duration in seconds
  });
}

export const formatCurrency = (value, module, isNotcurrencySymbol = false) => {

  let formattedValue, decimal = 2;

  if(module === 'cryptocurrencyinfo' || module === 'usstocksinfo'){
    decimal = 3;
  }

  // Format for USD-based modules
  if (module === 'usstocks' || module === 'US Stocks' || module === 'Crypto Currency' || module === 'cryptocurrency' || module === 'cryptocurrencyinfo' || module === 'usstocksinfo') {
    formattedValue = (module === 'cryptocurrencyinfo' || module === 'usstocksinfo') ? formatNumberWithTwoDecimals(value) : shortFormatUSD(value, decimal);
    return isNotcurrencySymbol ? formattedValue : `$${formattedValue}`;
  }

  // Default to Rupees formatting
  formattedValue = (module === 'indiastocksinfo' || module === 'mfinfo') ? formatNumberWithTwoDecimals(value) : shortFormatRupees(value);
  return isNotcurrencySymbol ? formattedValue : `₹${formattedValue}`;
};

export const clearCacheAndReload = () => {
  if ('caches' in window) {
    // Clear the cache storage
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  // Force the browser to reload the page and fetch new resources
  window.location.reload(true);
};

export const versionCheck = async () => {
  try {
    const response = await fetch(getFullUrl(`version.json?timestamp=${Date.now()}`));
    if (!response.ok) {
      console.error("Failed to fetch version information.");
      return false;
    }

    const { version: latestVersion } = await response.json();
    const currentVersion = process.env.REACT_APP_VERSION;
    console.log(`Current Version: ${currentVersion}`)

    const versionUpdateFlag = localStorage.getItem('versionUpdate') === 'true'; // Convert to boolean

    if (!versionUpdateFlag && currentVersion && String(currentVersion).trim() !== String(latestVersion).trim()) {
      console.log(`Update available: ${latestVersion}`);
      return { updateAvailable: true, latestVersion };
    }

    console.log("App is up-to-date.");
    return { updateAvailable: false, latestVersion };
  } catch (error) {
    console.error("Error checking version:", error);
    return false;
  }
};

export const getUserInfo = (field = false) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  return (field) ? userInfo[field] : userInfo;
}