// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect, useContext } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form } from 'react-bootstrap'; // Import Modal, Button, Form components from React Bootstrap
import Select from 'react-select'; // Import react-select for searchable dropdown
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { formatRupees, formatDateToInputValue, fetchData, ConvertDateToMilliseconds, getFullUrl } from "../../utils/helpers";
import { ExchangeRateContext } from '../../ExchangeRateContext';
import { apiConfig } from '../../ApiConfig';
import CustomSelect from '../CustomSelect';
import FileUpload from '../FileUpload';

import { trackEvent } from '../../utils/googleAnalytics';
// Define the Mutual fund form functional component
const MFForm = ({ isOpen, closeModal, onFormSubmit, initialFormData, defaultForm, onEdit }) => {
  // State for form validation and form data for both forms
    const [activeForm, setActiveForm] = useState(defaultForm); // State to toggle between forms
    const [formData, setFormData] = useState({ ...initialFormData });
    const [allCompanies, setAllCompanies] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [validated, setValidated] = useState(false);
    const [addedCompanies, setAddedCompanies] = useState([]); // Array to store added companies
    const [selectedOption, setSelectedOption] = useState(null);
    const [transactionType, setTransactionType] = useState('buy'); // State for transaction type
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isDateFocused, setIsDateFocused] = useState(false);
    const [showQuantity, setShowQuantity] = useState(true); // State to toggle between quantity and amount
    // Access currency, exchangeRate, and currency toggle function from context
    const { currency, handleCurrencyToggle, loadingExchangeRate, exchangeRate, convertCurrency } = useContext(ExchangeRateContext);
    
    const url = apiConfig.MFDIRECTORY;
    const method = 'GET';
    const localStorageKey = 'mfdirectory';

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (isOpen) {

            if (currency === 'USD') {
                handleCurrencyToggle(formData, setFormData, 'amount');
            }
            setFormData({
                name: '',
                companyid: '',
                amount: '',
                date: '',
            });
            setSelectedOption(null); // Reset react-select
            setValidated(false);
            setIsFormDirty(false);
            setAddedCompanies([]);
            setTransactionType('buy'); // Reset transaction type
        }
    }, [isOpen]);

    const loadData = async (refresh = false) => {
        try {
            trackEvent('MutualFund', 'request', 'Requesting mutual fund directory');
            const data = await fetchData(url, method, localStorageKey, refresh);
            setAllCompanies(data);
            trackEvent('MutualFund', 'success', 'Successfully mutual fund directory');
        } catch (error) {
            console.error(`MF Directory: ${error.message}`);
        }
    };

    const companyOptions = allCompanies.map(company => ({
        value: company.id,
        label: company.name,
    }));

    useEffect(() => {
        if (!isOpen) return;
    
        const selectedCompany = companyOptions.find(option => option.value === initialFormData.companyid);
        setFormData({
            ...initialFormData,
            date: initialFormData.date ? formatDateToInputValue(initialFormData.date) : "",
        });
        setSelectedOption(selectedCompany || null);
        setTransactionType(initialFormData.type)
        setValidated(false);
        setIsFormDirty(false);
    }, [isOpen, JSON.stringify(companyOptions), JSON.stringify(initialFormData)]);
    

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        let newValue = value;

        if (id === "amount") {
            newValue = value.replace(/,/g, "");
            // Allow only numbers and a single dot
            if (/^\d*\.?\d*$/.test(newValue)) {
                // Do not apply formatting if the input is incomplete (e.g., ends with a dot)
                if (newValue === "." || newValue.endsWith(".")) {
                    setFormData((prevData) => ({ ...prevData, [id]: newValue }));
                    setIsFormDirty(true);
                    return;
                }
    
                // Apply formatting for valid numbers
                newValue = formatRupees(newValue);
            } else {
                // If invalid input, do nothing
                return;
            }
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
        setIsFormDirty(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsButtonLoading(true);
        const form = event.currentTarget;
    
        // Check if form is valid
        if (form.checkValidity() === false && addedCompanies.length === 0) {
            event.stopPropagation();
            setValidated(true);
            setIsButtonLoading(false);
        } else {
            // Additional validation for required fields
            if (addedCompanies.length === 0 && (!formData.companyid || (!formData.amount && !formData.quantity) || !formData.date)) {
                setValidated(true); // If the form is blank and nothing has been added, show validation error
                setIsButtonLoading(false);
                return;
            }            
    
            try {
                // Destructure to exclude name from formData
                const { name, companyid, ...rest } = formData; // Destructure to exclude name and capture companyid

                // Create updatedFormData with necessary modifications
                let updatedFormData = {
                    ...rest, // Spread the rest of the properties from formData
                    scheme_code: companyid, // Replace companyid with scheme_code
                    date: ConvertDateToMilliseconds(formData.date), // Transform date
                    type: transactionType, // Keep type from existing data
                };

                // Logic to conditionally remove amount for sell transactions
                if (updatedFormData.type === 'sell') {
                    if (updatedFormData.quantity) { // Check if quantity is available
                        delete updatedFormData.amount; // Remove amount if quantity is present
                    }else if (updatedFormData.amount) {
                        delete updatedFormData.quantity; // Remove quantity if amount is present
                    }
                }
                // Prepare the final added companies list
                // Create a new array for final added companies
                let finalAddedCompanies = addedCompanies.map(company => {
                    // Destructure to remove name and companyid
                    const { name, companyid, ...rest } = company;
                
                    // Create the new object with scheme_code replacing companyid
                    let updatedCompany = {
                        ...rest,
                        scheme_code: companyid // Replace companyid with scheme_code
                    };
                
                    // Logic to conditionally remove amount or quantity for sell transactions
                    if (updatedCompany.type === 'sell') {
                        if (updatedCompany.quantity) {
                            delete updatedCompany.amount; // Remove amount if quantity is present
                        } else if (updatedCompany.amount) {
                            delete updatedCompany.quantity; // Remove quantity if amount is present
                        }
                    }

                    return updatedCompany; // Return the modified company object
                });

                // Check if companyid and amount are present in the form data
                if (updatedFormData.scheme_code && (updatedFormData.amount || updatedFormData.quantity)) {
                    // Ensure amount is in INR if currency is USD
                    if (currency === 'USD' && updatedFormData.amount) {
                        updatedFormData.amount = convertCurrency(updatedFormData.amount, exchangeRate, 'INR');  // Convert to INR
                    }

                    // Merge the form data only if it's not blank
                    finalAddedCompanies = [...finalAddedCompanies, updatedFormData];
                }

                // Call the callback function with the entire list of added companies
                await onFormSubmit(finalAddedCompanies);

                setValidated(false); // Reset validation state
                setTransactionType('buy'); // Reset transaction type
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsButtonLoading(false);
            }
        }
    };

    const handleAddMore = (event) => {
        event.preventDefault();
        if (!selectedOption || !formData.companyid || (!formData.amount && !formData.quantity) || !formData.date) {
            setValidated(true); // Validate the form fields
            return;
        }
        // Prepare the new company data
        let newCompanyData = {
            ...formData,
            name: selectedOption.label,
            date: ConvertDateToMilliseconds(formData.date),
            type: transactionType,
        };

        // Check currency and convert amount if necessary
        if (currency === 'USD' && newCompanyData.amount) {
            newCompanyData.amount = convertCurrency(newCompanyData.amount, exchangeRate, 'INR')// Convert USD to INR
        }

        // Set the updated companies list, including the new company
        setAddedCompanies([...addedCompanies, newCompanyData]);
      
        // Reset the form fields
        setFormData({
            name: '',
            companyid: '',
            amount: '',
            date: '',
        });
        // Reset the select input
        setSelectedOption(null); // Clear selected option
        setTransactionType('buy'); // Reset transaction type
        setValidated(false); // Reset validation
        setIsFormDirty(false); // Mark form as clean
    };
    
    const handleDelete = (index) => {
        // Remove the item from the list
        setAddedCompanies(addedCompanies.filter((_, i) => i !== index));
    };
    
    
    const handleEdit = (index) => {
        // Find the item to edit
        const itemToEdit = addedCompanies[index];
        // Find the corresponding company option
        const selectedCompany = companyOptions.find(option => option.value === itemToEdit.companyid);
        // Format the date for the input field
        const formattedDate = itemToEdit.date ? formatDateToInputValue(itemToEdit.date) : "";
        const amount = currency === 'USD' ? convertCurrency(itemToEdit.amount, exchangeRate, currency) : itemToEdit.amount;
        // Fill the form with the selected item's data for editing
        setFormData({
            ...itemToEdit,
            companyid: itemToEdit.companyid,
            date: formattedDate, // Set the formatted date
           amount: amount
        });
        
        setSelectedOption(selectedCompany); // Set the selected option for react-select
        setTransactionType(itemToEdit.type);
        // Remove the item from the list for now
        handleDelete(index);
    };
    
    // Dropdown options for transaction type
    const transactionOptions = [
        { value: 'buy', label: 'Buy' },
        { value: 'sell', label: 'Sell' },
    ];

  // Render the form modal
  return (
    <Modal show={isOpen} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>{onEdit ? 'Update' : 'Add'} transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* Toggle Buttons */}
            <div className="toggle-button-group">
            <button
                className={`toggle-button ${activeForm === 'manual' ? 'active' : ''} ${onEdit && activeForm !== 'manual' ? 'disable' : ''}`}
                onClick={() => !onEdit && setActiveForm('manual')}
                disabled={onEdit}
            >
                Manual
            </button>
            <button
                className={`toggle-button ${activeForm === 'upload' ? 'active' : ''} ${onEdit && activeForm !== 'upload' ? 'disable' : ''}`}
                onClick={() => !onEdit && setActiveForm('upload')}
                disabled={onEdit}
            >
                Upload File
            </button>
            </div>

            {activeForm === 'manual' ? (
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="us-stock">
                <Form.Group controlId="transactionType">
                    <Select
                        className='select-company-name'
                        options={transactionOptions}
                        placeholder="Select Transaction Type"
                        onChange={(option) => setTransactionType(option ? option.value : 'buy')} // Update transaction type
                        value={transactionOptions.find(option => option.value === transactionType) || transactionOptions.find(option => option.value === setTransactionType('buy'))}
                        isSearchable
                        isDisabled={onEdit}
                    />
                </Form.Group>

                <Form.Group controlId="name">
                    {onEdit ? (
                        <Form.Control
                            type="text"
                            placeholder="Select Mutual Fund Scheme"
                            value={selectedOption ? selectedOption.label : ""}
                            autoComplete="off"
                            disabled={true}
                        />
                    ) : (
                        <CustomSelect
                            placeholder="Select Mutual Fund Scheme *"
                            storageKey="mfdirectory" // or any other key you want to pass
                            value={selectedOption}
                            onChange={(option) => {
                                setSelectedOption(option);
                                setFormData({ ...formData, companyid: option ? option.value : '' });
                            }}
                            isSearchable
                            isDisabled={onEdit}
                        />
                    )}

                    {validated && !selectedOption && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please select the mutual fund scheme.
                        </div>
                    )}
                </Form.Group>

                {transactionType === 'sell' && (
                <>
                    {showQuantity ? (
                        <Form.Group controlId="quantity">
                            <Form.Control
                                type="number"
                                name="quantity"
                                placeholder="Quantity Sold *"
                                value={formData.quantity}
                                autoComplete="off"
                                onChange={handleInputChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter the quantity.
                            </Form.Control.Feedback>
                        </Form.Group>
                    ) : (
                        <>
                        <Form.Group controlId="amount">
                            <div className="input-container">
                            <span className="input-label">{currency === 'USD' ? '$' : '₹'}</span>
                                <Form.Control
                                    type="text"
                                    name="amount"
                                    placeholder="Amount Withdrawn *"
                                    value={formData.amount}
                                    autoComplete="off"
                                    required
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter the withdrawn amount.
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        </>
                    )}
                    <div className="mb-3">
                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="quantityAmountSwitch">
                                Enter Quantities Sold
                            </label>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="quantityAmountSwitch"
                                checked={showQuantity}
                                onChange={() => {
                                    setShowQuantity(prev => {
                                        // If toggling off, clear the quantity
                                        if (prev) {
                                            setFormData(prevFormData => ({
                                                ...prevFormData,
                                                quantity: '' // Clear quantity if toggled off
                                            }));
                                        }
                                        return !prev; // Toggle the state
                                    });
                                }}
                            />
                        </div>
                    </div>
                </>
            )}

            {transactionType === 'buy' && (
                <>
                    <Form.Group controlId="amount">
                        <div className="input-container">
                        <span className="input-label">{currency === 'USD' ? '$' : '₹'}</span>
                            <Form.Control
                                type="text"
                                name="amount"
                                placeholder="Investment Amount *"
                                value={formData.amount}
                                autoComplete="off"
                                required
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter the investment amount.
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </>
            )}
                <Form.Group controlId="date">
                    <div className={`position-relative ${!formData.date ? 'placeholder-date' : ''}`}>
                    {!formData.date && !isDateFocused && (
                        <span className='date-placeholder'>
                        Investment Date *
                        </span>
                    )}
                    <Form.Control
                        type="date"
                        value={formData.date}
                        onChange={handleInputChange}
                        autoComplete="off"
                        placeholder="Investment Date *"
                        required
                        style={{ width: '100%', cursor: 'pointer' }}
                        onFocus={(e) => {
                            if (!formData.date) {
                                setIsDateFocused(true); // Only set focus state if date is not filled
                                e.target.showPicker();  // Show the picker when focused
                            }
                        }}
                        onBlur={() => setIsDateFocused(false)} // Show placeholder on blur if no date
                    />
                    <Form.Control.Feedback type="invalid">
                        Please select the date.
                    </Form.Control.Feedback>
                    </div>
                </Form.Group>
                {!onEdit && (
                    <>
                        <a className="add-more" onClick={handleAddMore}>
                        Add More
                        </a>
                        {addedCompanies.length > 0 && (
                            <div className="added-companies-list">
                                <table className="companies-table">
                                    <thead>
                                        <tr>
                                            <th className="table-head-name">Name</th>
                                            <th>Qty</th>
                                            <th>Amount</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {addedCompanies.map((company, index) => (
                                        <tr key={index} className={company.type}>
                                            <td className="table-row-name">
                                                <div className="name-wrapper">
                                                    <span>{company.name}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span>{company.quantity || 'N/A'}</span>
                                            </td>
                                            <td>
                                                <span>
                                                {company.amount ? `₹${company.amount}` : 'N/A'}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    <i
                                                        className="fas fa-edit edit-company"
                                                        onClick={() => handleEdit(index)}
                                                    ></i>
                                                    <i
                                                        className="fas fa-trash delete-company"
                                                        onClick={() => handleDelete(index)}
                                                    ></i>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </>
                )}
                <Form.Group controlId="mfSubmit" className="btn-container">
                    <Button variant="primary" type="submit" disabled={isButtonLoading || (onEdit && !selectedOption)}>
                    {isButtonLoading ? (onEdit ? 'Updating...' : 'Saving...') : (onEdit ? 'Update' : 'Save')}
                    </Button>
                </Form.Group>
            </Form>
            ) : (
                <FileUpload onFileSubmit={onFormSubmit} fileUrl={getFullUrl('sample/Mutual_Fund_Sample_File.xlsx')}/>
            )}
        </Modal.Body>
    </Modal>
  );
};

export default MFForm;
