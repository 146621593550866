import React, {useState} from 'react';
import { isEmptyField, sortData, shortFormatRupees } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';

function PrivateEquityList({ data, module }) {
    const navigate = useNavigate();
    // Check if data is valid and has elements
    const isValidData = !isEmptyField(data);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const [activeSortKey, setActiveSortKey] = useState(null);
    // Show message if data is not valid
    if (!isValidData) {
        return (
        <div className="broker-main-container ind-stock">
            <p>No data available.</p>
        </div>
        );
    }

    const sortedData = sortData(data, sortConfig);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setActiveSortKey(key); // Set the active sort key
    };

  return (
    <div className="broker-main-container ind-stock">
      <div className='filter-container'>
        <div className={`filter ${activeSortKey === 'name' ? 'active' : ''}`} onClick={() => handleSort('name')}>Name <i className="fas fa-caret-down" aria-hidden="true"></i></div>
        <div className={`filter ${activeSortKey === 'investment' ? 'active' : ''}`} onClick={() => handleSort('investment')}>Current Value <i className="fas fa-caret-down" aria-hidden="true"></i></div>
        <div className={`filter ${activeSortKey === 'quantity' ? 'active' : ''}`} onClick={() => handleSort('quantity')}>Qty <i className="fas fa-caret-down" aria-hidden="true"></i></div>
      </div>
      {sortedData.map((stockDetails, index) => (
        <div className="broker-cell-container" key={index} onClick={() => navigate(`/assets/${module}/information/${stockDetails?.name}`)}>
            <div className="broker-cell-row">
                <div className="broker-cell-icon">
                    <img alt="" loading="lazy" width="32" height="32" decoding="async" data-nimg="1" src={stockDetails?.logo} style={{color: 'transparent'}} onError={(e) => {e.target.onerror = null; e.target.style.display = 'none'; }}/>
                </div>
                <div className="broker-cell-name-container">
                    <p className="broker-cell-name" style={{textAlign: 'unset'}}>{stockDetails?.name}</p>
                </div>
                <div>
                    <div className="broker-cell-price-container">
                        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="broker-cell-right-icon">
                            <path d="M5.57 5.226l3.143 2.778-3.142 2.777a.656.656 0 000 1.01c.316.279.826.279 1.142 0l3.717-3.286a.656.656 0 000-1.01L6.713 4.21a.883.883 0 00-1.142 0 .67.67 0 000 1.017z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="broker-cell-row padding-unset">
                <div className="broker-cell-name-container broker-cell-details">
                        <p className="broker-cell-name broker-name-color">Qty</p>
                    <p className="broker-cell-account">{stockDetails?.quantity}</p>
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Current Value</p>
                    <p className="broker-cell-account">₹{shortFormatRupees(stockDetails?.investment)}</p>
                </div>
            </div>
        </div>
      ))}
    </div>
  );
}

export default PrivateEquityList;
