import React from "react";
import { removeSign, formatCurrency } from "../utils/helpers";
import PieChart from "./PieChart";
import { Col } from "antd";

function HoldingSummary ({ data, pieChartData, module }) {
    const profitSummary = data?.profit_summary || {};
    const lossSummary = data?.loss_summary || {};

    // Handling possible null or undefined values in highest_profit and highest_loss
    const highestProfit = data?.highest_profit || { name: 'N/A', returns: [0, 0] };
    const highestLoss = data?.highest_loss || { name: 'N/A', returns: [0, 0] };

    return (
        <div className='holding-summary-main'>
            <Col className="col-md-12">
                <div className='holding-summary-container'>
                    <div className='holding-summary-row'>
                        <div className='holding-summary-pie-chart'>
                            <PieChart pieData={pieChartData} id='chart1' width={175} height={175} heading={''} status={false} profitLossColor={true}/>
                        </div>
                        <div className='holding-summary-flex'>
                            <div className=''>
                                <div><span className='text-bold'>{profitSummary.info || 'N/A'}</span></div>
                                <div style={{display: 'flex'}}>
                                    <span className='text-bold'>
                                        {profitSummary?.total_profit?.[0] != null 
                                            ? formatCurrency(Math.floor(profitSummary.total_profit[0]), module) 
                                            : formatCurrency(0, module)}
                                        &nbsp;&nbsp;
                                    </span>
                                    <span className='profit-color'>
                                        ▲{profitSummary?.total_profit?.[1] != null 
                                            ? removeSign(profitSummary.total_profit[1]) 
                                            : 0}%
                                    </span>
                                </div>
                            </div>
                            <div className='border'></div>
                            <div className=''>
                                <div><span className='text-bold'>{lossSummary.info || 'N/A'}</span></div>
                                <div className="holding-abs-per-val">
                                    <span className='text-bold'>
                                        {lossSummary?.total_loss?.[0] != null 
                                            ? formatCurrency(Math.floor(lossSummary.total_loss[0]), module) 
                                            : formatCurrency(0, module)}
                                    </span>
                                    <span className='loss-color' style={{ display: 'flex' }}>
                                        <span className='rotate-text'>▲</span>
                                        {lossSummary?.total_loss?.[1] != null 
                                            ? removeSign(lossSummary.total_loss[1]) 
                                            : 0}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='holding-summary-flex profit-loss-container'>
                        <div className='holding-summary-flex width-401'>
                            <h2 className='heading'>Highest Profit</h2>
                            <div>{highestProfit.name}</div>
                            <div style={{display: 'flex'}}>
                                <span className='text-bold'>
                                    {highestProfit?.returns?.[0] != null && !isNaN(Math.floor(highestProfit.returns[0]))
                                        ? formatCurrency(Math.floor(highestProfit.returns[0]), module)
                                        : formatCurrency(0, module)}
                                    &nbsp;&nbsp;
                                </span>
                                <span className='profit-color'>
                                    ▲{highestProfit?.returns?.[1] != null 
                                        ? removeSign(highestProfit.returns[1]) 
                                        : 0}%
                                </span>
                            </div>
                        </div>
                        <div className='vertical-border'></div>
                        <div className='holding-summary-flex width-401'>
                            <h2 className='heading'>Highest Loss</h2>
                            <div>{highestLoss.name}</div>
                            <div className="holding-abs-per-val">
                                <span className='text-bold'>
                                    {highestLoss?.returns?.[0] != null && !isNaN(Math.floor(highestLoss.returns[0]))
                                        ? formatCurrency(Math.floor(highestLoss.returns[0]), module)
                                        : formatCurrency(0, module)}
                                </span>
                                <span className='loss-color' style={{ display: 'flex' }}>
                                    <span className='rotate-text'>▲</span>
                                    {highestLoss?.returns?.[1] != null 
                                        ? removeSign(highestLoss.returns[1]) 
                                        : 0}%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className="col-md-3"></Col>
        </div>
    );
}

export default HoldingSummary;
