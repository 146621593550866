import React, { useState, useEffect } from 'react';
import { fetchData } from '../../utils/helpers';
import { apiConfig } from '../../ApiConfig';

import Loader from "../Loader";
import TransactionList from '../TransactionList';

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';

function CryptoCurrencyTransactions() {

  const location = useLocation();
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [transactionList, setTransactionList] = useState([]);

  const url = apiConfig.CRYPTOCURRENCYTRANSACTIONS;
  const method = 'GET';
  const localStorageKey = 'cryptoCurrencyTransactionList';

  // Fetch asset data when the component mounts
  useEffect(() => {
    loadData();
  }, [url, method, localStorageKey]);

  const loadData = async (refresh = false) => {
      try {
          trackEvent('CryptoCurrency Transactions', 'request', `Loading crypto currency transactions data`);
          const data = await fetchData(url, method, localStorageKey, refresh);
          setTransactionList(data?.transactions_data);
          trackEvent('CryptoCurrency Transactions', 'success', `Successfully crypto currency transactions data`);
      } catch (error) {
          console.error(`CryptoCurrecny Transaction: ${error.message}`);
          trackEvent('CryptoCurrency Transactions', 'error', `APi (${url}): ${error.message}`);
      } finally {
          setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  return (
    <div>
      {isLoading ? 
        <Loader />
        : (
          <div className="broker-main-container">
            {transactionList.length > 0 ? (
              <TransactionList data={transactionList} module='cryptocurrency'/>
            ) : (
              <div className='show-error'>No transactions available.</div> // Message for empty transactions
            )}
          </div>
        )
      }
    </div>
  );
}

export default CryptoCurrencyTransactions;
