import React, { useEffect } from 'react';
import { shortFormatRupees, formatRupees } from '../utils/helpers';
import * as d3 from 'd3';

const CalculatorPieChart = ({ pieChartData, module }) => {
    // Define your dataset
    const dataset = pieChartData;
    // Calculate total amount for percentage calculation
    const totalAmount = dataset.reduce((acc, data) => acc + data.count, 0);
    // Add percentage to each dataset item
    const datasetWithPercentage = dataset.map(data => ({
        ...data,
        percentage: ((data.count / totalAmount) * 100).toFixed(2), // Calculate percentage and format it to 2 decimal places
    }));

    // Chart dimensions
    const width = 250; // Adjust width to provide enough space for the legend
    const height = 250; // Adjust height to accommodate the pie chart and legend
    const radius = Math.min(width, height) / 2 - 20; // Slightly reduce radius to fit everything

    // Define specific colors
    const color = ['#f57328', '#5e75df'];

    useEffect(() => {
        // Clear any previous chart before appending a new one
        d3.select('#calculatorPieChart').select('svg').remove();
        d3.select('#calculatorPieChart').select('.tooltip').remove(); // Clear previous tooltips

        // Create the SVG container with padding for hover effects
        const svg = d3.select('#calculatorPieChart')
            .append('svg')
            .attr('class', 'main-svg')
            .attr('width', width + 40)  // Add padding for hover effect
            .attr('height', height + 80) // Increase height for legend
            .append('g')
            .attr('transform', `translate(${(width + 40) / 2 +25}, ${(height) / 2})`);

        const arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius);

        const arcHover = d3.arc()
            .innerRadius(0)
            .outerRadius(radius + 10);

        const pie = d3.pie()
            .value(d => d.count)
            .sort(null);

        // Create tooltip div
        const tooltip = d3.select('#calculatorPieChart')
            .append('div')
            .attr('class', 'tooltip')
            .style('position', 'absolute')
            .style('background-color', '#fff')
            .style('padding', '5px')
            .style('border', '1px solid #ccc')
            .style('border-radius', '5px')
            .style('display', 'none')
            .style('pointer-events', 'none');

        // Add pie chart arcs
        const path = svg.selectAll('path')
            .data(pie(datasetWithPercentage))
            .enter()
            .append('path')
            .attr('d', arc)
            .attr('fill', (d, i) => color[i % color.length])
            .each(function(d) { this._current = d; })
            .style('cursor', 'pointer');

        // Mouse event handlers for showing tooltip and hover effect
        path.on('mouseover', function(e, d) {
            tooltip.style('display', 'block');
            tooltip.html(`<strong>${d.data.label}</strong>: ₹${shortFormatRupees(d.data.count)} (${d.data.percentage}%)`) // Show percentage in tooltip
                .style('top', (e.pageY - 40) + 'px') // Position tooltip above the mouse
                .style('left', (e.pageX + 10) + 'px'); // Position tooltip to the right of the mouse

            d3.select(this)
                .transition()
                .duration(200)
                .attr('d', arcHover)
                .attr('opacity', 1);
        })
        .on('mouseout', function() {
            tooltip.style('display', 'none');

            d3.select(this)
                .transition()
                .duration(200)
                .attr('d', arc)
                .attr('opacity', 1);
        })
        .on('mousemove', function(e) {
            tooltip
                .style('top', (e.pageY - 40) + 'px')
                .style('left', (e.pageX + 10) + 'px');
        });

        // Calculate the total width of the legend based on the number of items
        const legendItemWidth = 160; // Adjust this value based on desired spacing
        const totalLegendWidth = dataset.length * legendItemWidth;
        const itemsPerRow = 2; // Set to 2 items per row
        const legendItemHeight = 25; // Vertical spacing between rows
        
        // Create legend group below the pie chart and center it
        const legendGroup = svg.append('g')
            .attr('transform', `translate(${-totalLegendWidth / 2}, ${height / 2 + 20})`); // Center the legend group

        // Define legend items
        let legendItems = legendGroup.selectAll('g')
        .data(datasetWithPercentage)
        .enter()
        .append('g');

        // Apply different transformations based on module type
        legendItems.attr('transform', (d, i) =>
        module === 'growth' 
            ? `translate(0, ${i * legendItemHeight})`   // Vertical placement
            : `translate(${i * legendItemWidth}, 0)`    // Horizontal placement
        );

        // Adding colored squares to legend
        legendItems.append('circle')
            .attr('cx', 7) // Center the circle (half of width)
            .attr('cy', 7) // Center the circle (half of height)
            .attr('r', 7) // Radius of the circle
            .style('fill', (d, i) => color[i])
            .style('stroke', (d, i) => color[i]);

        // Add text to the right of colored squares
        legendItems.append('text')
        .attr('x', 24)
        .attr('y', 14)
        .style('font-size', '.9rem')
        .text(d => module === 'growth' ? `${d.label}: ₹${formatRupees(d.count)}` : `${d.label}`);


    }, [datasetWithPercentage, width, height, radius, color]);

    return <div id="calculatorPieChart"></div>;
};

export default CalculatorPieChart;
