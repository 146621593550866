import React, { useState, useRef, useEffect } from "react";
import { Header } from "./Navbar.style";
import { CDBNavbar } from "cdbreact";
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useAuth } from "../../AuthContext";
import { getFullUrl, apiRequest, getUserInfo } from "../../utils/helpers";
import { apiConfig } from "../../ApiConfig";
import ChangePassword from "../ChangePassword";
import { trackEvent } from "../../utils/googleAnalytics";

const Navbar = ({toggleSidebar}) => {
    const { logout } = useAuth();  // Authentication status
    // State to track if the menu is active or not
    const [isActive, setIsActive] = useState(false);
    // State to track if the sub-menu is active or not
    const [isSubActive, setIsSubActive] = useState(false);
    // State to track if the profile is active or not
    const [isProfileActive, setIsProfileActive] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [profilePic, setProfilePic] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [isEditing, setIsEditing] = useState(false); // Manage edit mode
    const [editName, setEditName] = useState(name);    // Local state to hold the name temporarily
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const nameRef = useRef(null); // Reference to the contenteditable div
    // Refs for the menu and profile components
    const menuRef = useRef(null);
    const profileRef = useRef(null);

    // Function to toggle the menu
    const toggleMenu = () => {
        setIsActive(prev => !prev);
        setIsProfileActive(false); // Close profile if menu is toggled
    };

    // Function to toggle the sub-menu
    const toggleSubMenu = () => {
        setIsSubActive(prev => !prev);
    };

    // Function to toggle the profile
    const toggleProfile = () => {
        setIsProfileActive(prev => !prev);
        setIsActive(false); // Close menu if profile is toggled
    };

    // Function to handle clicks outside of the menu and profile
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target) && 
            profileRef.current && !profileRef.current.contains(event.target)) {
            setIsActive(false);
            setIsProfileActive(false);
            setEditName(name);
            setIsEditing(false);
            setError(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const userInfo = getUserInfo();
        setName(userInfo.name);
        setEmail(userInfo.email);
        setProfilePic(userInfo.profile_pic_bytes);
    })

    const handleEditClick = () => {
        setIsEditing(true);
        setEditName(name);
        // Focus and place cursor at the end of text
        setTimeout(() => {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(nameRef.current);
            range.collapse(false); // Collapse range to end of content
            selection.removeAllRanges();
            selection.addRange(range);
            nameRef.current.focus();
        }, 0);
    };

    const handleSaveClick = async () => {
        const userInfo = getUserInfo();
        trackEvent('Profile', 'save', 'Update Profile');
        if (editName === name) {
            setIsEditing(false); // Exit edit mode
            setError(null);
            return;
        }
        if (editName.trim() === "") {
            // If name is blank or hasn't changed
            setError("Please enter a username.");
            nameRef.current.focus();
            return;
        }
        try {
            const formData = {
                email: userInfo.email,
                name: editName
            };
            trackEvent('Profile', 'request', 'Update Profile');
            // Call the API to post the form data
            const result = await apiRequest(apiConfig.UPDATEPROFILE, 'POST', formData);
            
            if (result.statusCode === 200) {
                setError(null);
                setName(editName);
                setIsEditing(false); // Exit edit mode

                // Update name in localStorage
                userInfo.name = editName;
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                trackEvent('Profile', 'success', 'Successfully updated profile');
            } else if (result.statusCode === 401 || result.statusCode === 400) {
                setError('Something went wrong, please try again.');
                trackEvent('Profile', 'error', `Error: ${result}`);
            }
        } catch (error) {
            // Display the API error message
            setError('Something went wrong, please try again.');
            trackEvent('Profile', 'error', `Error: ${error}`);
        }     
    };

    const handleNameChange = (e) => {
        setEditName(e.target.innerText); // Update editName state with the new content
    };

    // Function to handle opening the modal
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditName(name);
        setIsEditing(false);
        setError(null);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          uploadFile(file);
        }
    };
    
    const uploadFile = (file) => {
        const MAX_FILE_SIZE_MB = 1; // Maximum file size in MB
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
        const ALLOWED_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/webp"]; // Allowed image MIME types

        // Check file type
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setError("Invalid file type. Please upload an image (JPG, JPEG, PNG, GIF, WEBP).");
            return; // Stop the upload process
        }

        // Check file size
        if (file.size > MAX_FILE_SIZE_BYTES) {
            setError(`File size exceeds ${MAX_FILE_SIZE_MB}MB. Please upload a smaller file.`);
            return; // Stop the upload process
        }
        const userInfo = getUserInfo();
        const formData = new FormData();
        formData.append("profile_pic", file);
        
        trackEvent('Profile Pic', 'request', 'Update Profile Pic');
        const xhr = new XMLHttpRequest();
    
        // Set up progress tracking
        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = Math.round((event.loaded * 100) / event.total);
                setUploadProgress(progress); // Update progress state
            }
        };
        // Handle successful upload
        xhr.onload = () => {
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                if (response.data) {
                    const base64String = response.data.profile_pic_bytes;
                    // Update profile_pic_bytes in localStorage
                    userInfo.profile_pic_bytes = base64String;
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));

                    setProfilePic(base64String); // Update profile picture
                    setUploadProgress(0); // Reset progress
                    trackEvent('Profile Pic', 'success', 'Successfully updated profile pic');
                } else {
                    setError('Oops! Something went wrong. Please try again later.');
                }
            } else {
                setError('Oops! Something went wrong. Please try again later.');
                trackEvent('Profile Pic', 'error', `Error: ${xhr.status}`);
            }
        };
    
        // Handle errors
        xhr.onerror = () => {
            setError('Oops! Something went wrong. Please try again later.');
            setUploadProgress(0); // Reset progress
            trackEvent('Profile Pic', 'error', `Error`);
        };
    
        // Open and send the request
        xhr.open("POST", apiConfig.UPDATEPROFILEPIC);
        xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem('token')}`);
        xhr.send(formData);
    };

    return (
        <Header className="header-container">
            <div className="form-inline ml-auto">
                {/* <span className='toggle-bar'>
                    <i className="fa fa-bars fa-large" onClick={toggleSidebar}></i>
                    <span className="navbar-brand text-primary mr-0">MoneyLens</span>
                </span> */}
                <div className='logo-container'>
                    <i className="fa fa-bars fa-large" onClick={toggleSidebar}></i>
                    <img alt="icon" src={getFullUrl('logo.png')} style={{ width: "2rem", height: "2rem"}} />
                    <div className='logo-name'>MoneyLens</div>
                </div>
            </div>
            <CDBNavbar dark expand="md" scrolling className="justify-content-end">
                <div className="ml-auto">
                    <div className="menu-container">
                        <NavLink exact='true' to="/networth" activeclassname="activeClicked">NetWorth</NavLink>
                        <NavLink exact='true' to="/assets" activeclassname="activeClicked">Assets</NavLink>
                        <NavLink exact='true' to="/debts" activeclassname="activeClicked">Debts</NavLink>
                        {/* Calculator Dropdown */}
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="custom-dropdown-toggle">
                            Calculators <i className="fas fa-angle-down" style={{ marginLeft: '7px' }}></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="custom-dropdown-menu">
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/growth" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    CAGR Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/car-loan" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Car Loan Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/home-loan" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Home Loan Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/personal-loan" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Personal Loan Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/retirement" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Retirement Planning
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/sip" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    SIP Calculator
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{ marginLeft: '15px', cursor: 'pointer', textTransform: 'capitalize' }} onClick={toggleProfile}>
                        {name}
                            <i className="fas fa-angle-down" style={{ marginLeft: '7px' }}></i>
                        </span>
                        <img alt="profileImage" src={profilePic || getFullUrl('images/avatar.png')} className="profile-image" onClick={toggleProfile} />
                    </div>
                </div>
                <div ref={menuRef} className={`menu ${isActive ? 'active' : ''}`}>
                    <ul>
                        <li>
                            <a className="active" style={{textTransform: 'capitalize'}}>{name}</a>
                            <button className="options" onClick={toggleSubMenu}></button>
                            <div className={`submenu ${isSubActive ? 'active' : ''}`}>
                                <ul>
                                    <li><a className="">Rename Portfolio</a></li>
                                    <li className="newPSection"><a href="#">Delete</a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="newPSection"><a href="#">+ New Portfolio</a></li>
                    </ul>
                </div>
                <div ref={profileRef} className={`profile ${isProfileActive ? 'active' : ''}`}>
                    <div className="profile-section">
                        {/* Profile Image */}
                        <div
                            className="profile-image-container"
                            style={{ position: "relative", height: "5rem", borderRadius: "50%", overflow: "hidden", cursor: "pointer" }}
                            onMouseEnter={() => setIsHovering(true)}
                            onMouseLeave={() => setIsHovering(false)}
                        >
                            <img
                            alt="profileImage"
                            src={profilePic || getFullUrl("images/avatar.png")}
                            style={{ width: "5rem", height: "100%", objectFit: "cover" }}
                            />

                            {/* Hover Camera Icon */}
                            {isHovering && (
                            <div
                                onClick={() => document.getElementById("fileInput").click()}
                                style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "5rem",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                fontSize: "1.2rem",
                                }}
                            >
                                <i className="fas fa-camera" style={{ width: "3rem", objectFit: "cover" }}/>
                            </div>
                            )}

                            {/* Upload Progress */}
                            {uploadProgress > 0 && uploadProgress < 100 && (
                            <div
                                style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                fontSize: "1rem",
                                }}
                            >
                                {uploadProgress}%
                            </div>
                            )}

                            {/* Hidden File Input */}
                            <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            />
                        </div>
                        
                        {/* Profile Name */}
                        <div className="profile-name">
                            <div
                                contentEditable={isEditing}
                                ref={nameRef}
                                onInput={handleNameChange}
                                suppressContentEditableWarning={true}
                                style={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    padding: '.5rem',
                                    outline: 'none',
                                    display: 'inline'
                                }}
                            >
                                {name}
                            </div>
                            {isEditing ? (
                                <i className="fas fa-save" onClick={handleSaveClick} style={{ cursor: 'pointer', marginLeft: '5px' }}></i>
                            ) : (
                                <i className="fas fa-edit" onClick={handleEditClick} style={{ cursor: 'pointer', marginLeft: '5px' }}></i>
                            )}
                        </div>
                        {/* Error Message */}
                        {error && <div className="error-message">{error}</div>}

                        {/* Profile Email */}
                        <div className="profile-info">{email}</div>
                    </div>
                    <div className="newPSection">
                        <ul>
                            <li><a className="active" onClick={handleOpenModal}>Change Password</a></li>
                            <li onClick={logout}><a>Sign Out</a></li>
                        </ul>
                        {isModalOpen && <ChangePassword onClose={handleCloseModal} />}
                    </div>
                </div>
            </CDBNavbar>
        </Header>
    );
};

export default Navbar;
