import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { apiConfig } from '../ApiConfig';
import { showErrorMessage, fetchData, formatDateWithTime } from '../utils/helpers';
import { trackEvent } from '../utils/googleAnalytics';
import Loader from './Loader';
import '../css/feedback.css';
import { useNavigate } from 'react-router-dom';
import EmptyState from './EmptyState';

const Ticket = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // State for loading status
    const [ticketList, setTicketList] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                trackEvent('Ticket', 'request', 'Loading ticket data');
                const data = await fetchData(apiConfig.TICKET, 'GET', '', true, true);
                if (data) {
                    // Set state with actual data
                    setTicketList(data?.tickets || []);
                    trackEvent('Ticket', 'success', 'Successfully loaded ticket data');
                }
            } catch (error) {
                showErrorMessage();
                console.error(`Ticket list: ${error.message}`);
                trackEvent('Ticket', 'error', `Error: ${error.message}`);
            } finally {
                setIsLoading(false); // Set loading to false after data is fetched or an error occurs
            }
        };
        loadData();
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : ticketList.length > 0 ?
                <div className="container card" style={{height: '100%'}}>
                    <div className="support-container">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-ticket">
                                    <thead className='table-header'>
                                        <tr>
                                            <th scope="col" className="table-heading-data ">
                                                <span className="table-heading-name">Ticket Id</span>
                                            </th>
                                            <th scope="col" className="table-heading-data ">
                                                <span  className="table-heading-name">Subject</span>
                                            </th>
                                            <th scope="col" className="table-heading-data ">
                                                <span className="table-heading-name">Status</span>
                                            </th>
                                            <th scope="col" className="table-heading-data ">
                                                <span className="table-heading-name">Updated</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {ticketList.map(ticket => (
                                        <tr key={ticket?.ticket_id} className='table-heading-data table-single-row' onClick={() => navigate(ticket?.ticket_id)}>
                                            <td scope="row" className=' ticket-id'># {ticket?.ticket_id}</td>
                                            <td scope="row" className=''>{ticket?.subject}</td>
                                            <td scope="row" className={classNames('', ticket?.status?.toLowerCase())}>{ticket?.status}</td>
                                            <td scope="row" className=''>{formatDateWithTime(ticket?.last_updated)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            : ( <EmptyState openModal={false} backeUrl='/support'/> )}
        </>
    );
};

export default Ticket;
