import React, { useState } from "react";
import { apiConfig } from "../ApiConfig";
import { apiRequest, showErrorMessage } from "../utils/helpers";
import '../css/TicketDetails.css';

const StarRating = ({id, rating}) => {
    const [hoveredRating, setHoveredRating] = useState(0);
    const [selectedRating, setSelectedRating] = useState(rating);

    const handleMouseOver = (rating) => setHoveredRating(rating);
    const handleMouseOut = () => setHoveredRating(0);

    const handleRatingClick = async (rating) => {
        setSelectedRating(rating);
        await sendRating(rating);
    };

    const sendRating = async (rating) => {
        const formData = { rating: rating }
        try {
            // Call the API to post the form data
            const result = await apiRequest(`${apiConfig.TICKET}/${id}/rate`, 'POST', formData);

            if (result.statusCode === 200) {
                return true;
            } else {
                setSelectedRating(0);
                showErrorMessage('Oops! Something went wrong. Please try again later.');
            }
        } catch (error) {
            setSelectedRating(0);
            showErrorMessage('Oops! Something went wrong. Please try again later.');
        } 
    };

    return (
        <div className="star-rating">
        {[1, 2, 3, 4, 5].map((star) => (
            <span
            key={star}
            className={`star ${hoveredRating >= star || selectedRating >= star ? "filled" : ""}`}
            onMouseOver={() => handleMouseOver(star)}
            onMouseOut={handleMouseOut}
            onClick={() => handleRatingClick(star)}
            >
            &#9733;
            </span>
        ))}
        </div>
    );
};

export default StarRating;
