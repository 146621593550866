import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { fetchData, removeSign, showErrorMessage } from '../../utils/helpers';
import { apiConfig } from '../../ApiConfig';

import HoldingSummary from '../HoldingSummary';
import PieChart from '../PieChart';
import ApexChart from '../ApexChart';
import DynamicTable from '../DynamicTable';
import Analytics from '../Analytics';
import TrendingStocks from '../TrendingStocks';
import Loader from '../Loader'; // Import the loader component

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';


function CryptoCurrencyAnalytics() {

  const location = useLocation();  // Get the current location
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const [selectedChart, setSelectedChart] = useState('current');
  const [analyticsValues, setAnalyticsValues] = useState({}); // State to hold asset values
  const [holdingInsights, setHoldingInsights] = useState({}); // State to hold asset values
  const [distribution, setDistribution] = useState([]);
  const [topGainers, setTopGainers] = useState([]);
  const [topLosers, setTopLosers] = useState([]);
  const [baseDailyTrend, setBaseDailyTrend] = useState({}); // State to hold asset values
  const [baseWeeklyTrend, setBaseWeeklyTrend] = useState({}); // State to hold asset values
  const [referenceDailyTrend, setReferenceDailyTrend] = useState({}); // State to hold asset values
  const [referenceWeeklyTrend, setReferenceWeeklyTrend] = useState({}); // State to hold asset values

  const [topGainersTime, setTopGainersTime] = useState('1d_change');
  const [topLosersTime, setTopLosersTime] = useState('1d_change');

  const [profitData, setProfitData] = useState([]);
  const [lossData, setLossData] = useState([]);

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null);

  const url = apiConfig.CRYPTOCURRENCYANALYTICS;
  const method = 'GET';
  const localStorageKey = 'cryptoCurrencyAnalyticsData';
  // Fetch asset data when the component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        trackEvent('CryptoCurrency Analytics', 'request', 'Loading crypto currency analytics data');
        const data = await fetchData(url, method, localStorageKey);
        if (!data) {
          throw new Error(); // Manually throw an error if data is null or undefined
        }
        updateStateWithData(data);
        trackEvent('CryptoCurrency Analytics', 'success', 'Successfully crypto currency analytics data');
      } catch (error) {
        console.error(`CryptoCurrency Analytics data: ${error.message}`);
        showErrorMessage('Oops! Something went wrong. Please try again later.');
        trackEvent('CryptoCurrency Analytics', 'error', `APi (${url}): ${error.message}`);
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  loadData();
  }, [url, method, localStorageKey]);
  
  const updateStateWithData = (result) => {
    // Assuming the API returns an object with asset values
    setAnalyticsValues(result ?? {});
    setHoldingInsights(result?.holdings_insights ?? {});
    setTopGainers(result?.holdings_summary?.top_gainers);
    setTopLosers(result?.holdings_summary?.top_losers);
    setDistribution(result?.holdings_insights?.distribution ?? []);
    setProfitData(result?.holdings_summary?.profit_loss.profit);
    setLossData(result?.holdings_summary?.profit_loss.loss);

    setBaseDailyTrend(result?.historic_trend?.base_trend?.daily_data ?? []);

    setBaseWeeklyTrend(result?.historic_trend?.base_trend?.weekly_data ?? []);

    // const referenceDailyTrend = result?.historic_trend?.reference_trend?.daily_data ?? [];
    // setReferenceDailyTrend(referenceDailyTrend);

    // const referenceWeeklyTrend = result?.historic_trend?.reference_trend?.weekly_data ?? [];
    // setReferenceWeeklyTrend(referenceWeeklyTrend);

    trackEvent('CryptoCurrency Analytics', 'success', 'Loaded crypto currency analytics data');
  }

  const pieDataProfitLoss = [
    ['Profit', removeSign(profitData[1]), removeSign(profitData[1])],
    ['Loss', removeSign(lossData[1]), removeSign(lossData[1])],
  ];

  const handleTopGainersChange = (e) => {
    setTopGainersTime(e.target.value);
    trackEvent('Crypto Currency Analytics TopGainer', 'Change', `Change crypto currency analytics topgainer ${e.target.value}`);
  };

  const handleTopLosersChange = (e) => {
    setTopLosersTime(e.target.value);
    trackEvent('Crypto Currency Analytics TopLoser', 'Change', `Change crypto currency analytics toploser ${e.target.value}`);
  };

  const memoizedTopGainers = useMemo(() => topGainers[topGainersTime] || [], [topGainers, topGainersTime]);
  const memoizedTopLosers = useMemo(() => topLosers[topLosersTime] || [], [topLosers, topLosersTime]);

  const renderTopGainers = useCallback(() => {
    return <TrendingStocks stocksData={memoizedTopGainers} module='cryptocurrency'/>;
  }, [memoizedTopGainers]);

  const renderTopLosers = useCallback(() => {
    return <TrendingStocks stocksData={memoizedTopLosers} module='cryptocurrency'/>;
  }, [memoizedTopLosers]);

  const initialRows = [
    {
      id: 'row1',
      columns: [
        { id: '1', header: 'Portfolio Summary', contentId: 'holding-summary'}
      ],
    },
    {
      id: 'row2',
      columns: [
        { id: '2', header: 'Portfolio Growth Trend', contentId: 'historic-trend' },
      ],
    },
    {
      id: 'row3',
      columns: [
        { id: '3', header: 'Market Cap', contentId: 'market-cap'},
        { id: '4', header: 'Portfolio Breakdown by Crypto Currency', contentId: 'distribution'},
      ]
    },
    {
      id: 'row4',
      columns: [
        { id: '5', header: 'Unrealized Gain Breakdown by Crypto Currency Holdings', contentId: 'unrealized-gain'},
        {id: '6', header: '', contentId: ''}
      ]
    },
    {
      id: 'row5',
      columns: [
        { id: '7', header: 'Portfolio Performance by Crypto Currency Holdings', contentId: 'portfolio-performance'}
      ]
    },
    {
      id: 'row6',
      columns: [
        { id: '8', header: 'Top Gainers', contentId: 'trending-stocks-gainers'},
        { id: '9', header: 'Top Losers', contentId: 'trending-stocks-losers'}
      ]
    }
  ];
  const contentMap = useMemo(() => ({
    
    'holding-summary' : <HoldingSummary data={analyticsValues?.holdings_summary} pieChartData={pieDataProfitLoss} module='cryptocurrency'/>,
    'historic-trend' : <div className='holding-chart-container'>
                          <ApexChart 
                            baseTrend={baseDailyTrend} 
                            referenceTrend={false} 
                            baseWeeklyTrend={baseWeeklyTrend} 
                            referenceWeeklyTrend={false} 
                            referenceIndex={false}
                            baseIndex = 'Returns'
                            module='cryptocurrency'
                          />
                        </div>,
    ...(analyticsValues?.market_cap?.length > 0 && {
      'market-cap' : <div className="checkbox-heading">
                      <div className='checkbox-wrapper adjust-height'></div>
                      <PieChart pieData={analyticsValues?.market_cap} id='market-cap' width='480' height='250' heading='' status = {true} profitLossColor = {false} module='cryptocurrency'/>
                    </div>,
    }),
    'distribution' : <div className="checkbox-heading">
                                    {/* <h2 className='heading'>Mutual Fund Distribution</h2> */}
                                    <div className='checkbox-wrapper'>
                                      <label className='analytics-label'>
                                        <input type='radio' className='analytics-checkbox' name='chartSelector' value='current' checked={selectedChart === 'current'} onChange={() => setSelectedChart('current')}/>
                                        Current
                                      </label>
                                      <label className='analytics-label'>
                                        <input type='radio'className='analytics-checkbox'  name='chartSelector' value='invested' checked={selectedChart === 'invested'} onChange={() => setSelectedChart('invested')} />
                                        Invested
                                      </label>
                                    </div>
                                  
                                    {selectedChart === 'current' && (
                                      <PieChart pieData={distribution?.curr_val} id='current' width='480' height='250' heading='' status = {true} profitLossColor = {false} module='cryptocurrency'/>
                                    )}
                                    {selectedChart === 'invested' && (
                                      <PieChart pieData={distribution?.investment} id='invested' width='480' height='250' heading='' status = {true} profitLossColor = {false} module='cryptocurrency'/>
                                    )}
                                  </div>,
    ...(holdingInsights?.unrealised_gain?.length > 0 && {
      'unrealized-gain' : <div className="checkbox-heading">
                          <div className='checkbox-wrapper adjust-height'></div>
                          <PieChart pieData={holdingInsights?.unrealised_gain} id='unrealized-gain' width='480' height='250' heading='' status = {true} profitLossColor = {false} module='cryptocurrency'/>
                        </div>,
    }),
    'portfolio-performance' : <div className="piechart-wrapper">
                                <DynamicTable tableData={holdingInsights?.change} module='cryptocurrency'/>
                              </div>,
    'trending-stocks-gainers' : <div className='piechart-wrapper'>
                                  <select className='gainers-dropdown' onChange={handleTopGainersChange} value={topGainersTime}>
                                    <option value="1d_change">1 Day</option>
                                    <option value="1w_change">1 Week</option>
                                    <option value="1m_change">1 Month</option>
                                    <option value="total">Total</option>
                                  </select>
                                  {/* Conditionally render the trending stocks based on selected time period */}
                                  {renderTopGainers()}
                                </div>,
    'trending-stocks-losers' : <div className='piechart-wrapper'>
                                  <select className='gainers-dropdown' onChange={handleTopLosersChange} value={topLosersTime}>
                                    <option value="1d_change">1 Day</option>
                                    <option value="1w_change">1 Week</option>
                                    <option value="1m_change">1 Month</option>
                                    <option value="total">Total</option>
                                  </select>
                                  {/* Conditionally render the trending stocks based on selected time period */}
                                  {renderTopLosers()}
                                </div>,
   }), [analyticsValues, holdingInsights, distribution, baseDailyTrend, baseWeeklyTrend, selectedChart, topGainersTime, topLosersTime, pieDataProfitLoss]);
  
  return (
    <div className='crypto-stock-analytics'>
      {isLoading ? (
        <Loader /> // Show the loader while data is loading
      ) : error ? (
        <div className='show-error'>{error}</div>
      ) : (
        <Analytics initialRows={initialRows} contentMap={contentMap} module={'cryptocurrency-analytics'}/>
      )}
    </div>
  );
}

export default CryptoCurrencyAnalytics;
