import React from 'react';
import '../css/TransactionList.css';
import { formatDate, getFullUrl, formatCurrency } from '../utils/helpers';

const TransactionList = ({ data = [], module = false }) => {

  return (
    <div className="transaction-card-list">
      {data.map((group, index) => (
        <div key={index}>
          <div className="date">{group?.transaction_date ? formatDate(group.transaction_date) : 'Unknown Date'}</div>
          {group?.transactions?.map((transaction, i) => (
            <div className="broker-cell-container" key={transaction?.id || i}>
              <div className="broker-cell-row">
                <div className="broker-cell-icon">
                  <img
                    loading="lazy"
                    width="32"
                    height="32"
                    decoding="async"
                    src={
                      transaction?.logo ? 
                        transaction.logo :
                        (module === 'realestate' ? 
                          getFullUrl('images/realestate.png') : 
                          (module === 'preciousmetals' && 
                            ['gold', 'silver', 'palladium', 'platinum'].includes(transaction?.name?.toLowerCase()) ? 
                              getFullUrl(`images/${transaction.name.toLowerCase()}.png`) : 
                              getFullUrl('images/default-logo.png')
                          )
                        )
                    }
                    alt={transaction?.name || 'Unknown'}
                    style={{ color: 'transparent' }}
                    onError={(e) => { e.target.onerror = null; e.target.style.display = 'none'; }}
                  />
                </div>
                <div className="broker-cell-name-container transaction-info">
                  <p className="broker-cell-name" style={{ textAlign: 'unset' }}>
                    {transaction?.name || 'Unknown Name'}
                  </p>
                  <span className={`status ${transaction?.type?.toLowerCase() || 'unknown'}`}>
                    {transaction?.type || ''}
                  </span>
                </div>
              </div>
              <div className="broker-cell-row padding-unset">
                {transaction.quantity && 
                <div className="broker-cell-name-container broker-cell-details">
                  <p className="broker-cell-name broker-name-color">
                    Qty: <span className="broker-cell-account">{transaction?.quantity ?? 'N/A'}</span>
                  </p>
                </div>
                }
                <div className="broker-cell-name-container broker-cell-details">
                  <div className="broker-cell-name broker-name-color">
                    Total amount:&nbsp; 
                    <span className="broker-cell-account">
                      {transaction?.amount !== undefined ? formatCurrency(transaction.amount, module) : 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TransactionList;
