import React, { useState } from "react";
import '../css/DynamicTable.css';
import { formatRupees } from "../utils/helpers";

const LoanCalculatorTable = ({ data, heading1, heading2, heading3, heading4, module }) => {
  const [expandedYear, setExpandedYear] = useState(null);

  const toggleYear = (year) => {
    setExpandedYear(expandedYear === year ? null : year);
  };

  return (
    <div className="table-responsive">
      <table className="table table-retirement">
        <thead className="table-header">
          <tr>
            <th></th>
            <th scope="col" className="table-heading-data">Year</th>
            <th scope="col" className="table-heading-data">{heading1}</th>
            <th scope="col" className="table-heading-data">{heading2}</th>
            <th scope="col" className="table-heading-data">{heading3}</th>
            <th scope="col" className="table-heading-data">{heading4}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((yearData, index) => (
            <React.Fragment key={index}>
              {/* Year Row */}
              <tr onClick={() => toggleYear(yearData.year)}>
                <td>
                  <span className="table-button-container">
                    {/* Conditionally render the icon based on expandedYear */}
                    <i className={`fa ${expandedYear === yearData.year ? 'fa-minus' : 'fa-plus'}`}></i>
                  </span>
                </td>
                <td>{`Year ${yearData.months[yearData.months.length - 1].yearNo}`}</td>
                <td>{`₹${formatRupees(yearData.yearlyPrincipal)}`}</td>
                <td>{`₹${formatRupees(yearData.yearlyInterest)}`}</td>
                <td>{`₹${formatRupees(yearData.yearlyPrincipal + yearData.yearlyInterest)}`}</td>
                <td>{`₹${formatRupees(yearData.months[yearData.months.length - 1].remainingBalance)}`}</td>
              </tr>

              {/* Expanded Monthly Data */}
              {expandedYear === yearData.year &&
                yearData.months.map((monthData, idx) => (
                  <tr key={idx}>
                    <td></td> {/* This cell is left empty; you can add any content or remove this if not needed */}
                    <td className="table-heading-data">{`${monthData.month}`}</td>
                    <td className="table-heading-data">{`₹${formatRupees(monthData.principal)}`}</td>
                    <td className="table-heading-data">{`₹${formatRupees(monthData.interest)}`}</td>
                    <td className="table-heading-data">{`₹${formatRupees(monthData.emiAmount)}`}</td>
                    <td className="table-heading-data">{`₹${formatRupees(monthData.remainingBalance)}`}</td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LoanCalculatorTable;
