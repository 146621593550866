import React, { createContext, useState, useEffect } from "react";
import { formatRupees, showErrorMessage, apiRequest } from "./utils/helpers";
import { apiConfig } from "./ApiConfig";

// Create the context
export const ExchangeRateContext = createContext();

// Create the provider component
export const ExchangeRateProvider = ({ children }) => {
    const [exchangeRate, setExchangeRate] = useState(null);
    const [currency, setCurrency] = useState('INR');
    const [loadingExchangeRate, setLoadingExchangeRate] = useState(false);

    // Function to fetch and store exchange rate globally
    const fetchExchangeRate = async () => {
        setLoadingExchangeRate(true); // Start loading
        let rate = null;
        try {
            const formData = {date: new Date().getTime()}
            const response = await apiRequest(apiConfig.FOREXRATE, 'POST', formData);
            if(response.statusCode === 200){
                rate = response.data
                setExchangeRate(rate); // Update global state with fetched exchange rate
            }
    
            localStorage.setItem("exchangeRate", rate); // Store exchange rate in localStorage
            localStorage.setItem("exchangeRateDate", new Date().toISOString().split('T')[0]); // Store today's date
        } catch (error) {
            console.error(`Error fetching exchange rate: ${error.message}`);
        } finally {
            setLoadingExchangeRate(false); // Stop loading after fetching rate
        }
    };

    // Function to load data from localStorage or fetch from API
    const loadData = () => {
        const storedRate = localStorage.getItem("exchangeRate");
        const storedDate = localStorage.getItem("exchangeRateDate");
        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    
        if (storedRate && storedDate === today) {
            // Use the stored rate if the date is today
            setExchangeRate(storedRate);
        } else {
            // Fetch from API if no stored rate or the date is not today
            fetchExchangeRate();
        }
    };

    useEffect(() => {
        loadData(); // Load data on component mount
    }, []);

    // Helper function to convert amount based on exchange rate
    const convertCurrency = (amount, exchangeRate, targetCurrency) => {
        if (targetCurrency === 'USD') {
            return (parseFloat(amount.replace(/,/g, '')) / exchangeRate).toFixed(2);
        } else {
            return (parseFloat(amount) * exchangeRate).toFixed(2);
        }
    };

    // Function to toggle currency and update price format
    const handleCurrencyToggle = async (formData, setFormData, field, resetOnly = false) => {
        const targetCurrency = resetOnly ? 'INR' : (currency === 'INR' ? 'USD' : 'INR');
    
        if (!resetOnly && targetCurrency === 'USD' && !exchangeRate) {
            await fetchExchangeRate(); // Ensure exchange rate is fetched
            if (!exchangeRate) {
                showErrorMessage('Unable to fetch exchange rate');
                return;
            }
        }
    
        if (!resetOnly) {
            const amount = formData[field] || '0'; // Dynamically use the field for amount
            const convertedAmount = convertCurrency(amount, exchangeRate, targetCurrency);
    
            setFormData((prevData) => ({
                ...prevData,
                [field]: targetCurrency === 'INR' ? formatRupees(convertedAmount) : convertedAmount,
            }));
        }
    
        setCurrency(targetCurrency);
    };
    

    return (
        <ExchangeRateContext.Provider value={{ exchangeRate, fetchExchangeRate, loadingExchangeRate, currency, handleCurrencyToggle, convertCurrency}}>
            {children}
        </ExchangeRateContext.Provider>
    );
};
