import React, {useState, useEffect} from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { versionCheck, clearCacheAndReload } from './utils/helpers';
import { useAuth } from './AuthContext';
import NotFound from './NotFound';
import NoHeaderLayout from './NoHeaderLayout';
import ProtectedRoute from './ProtectedRoute';
import Header from './components/Header';
import Login from './Login';
import Signup from './Signup';
import OTPForm from './OTPForm';
import ResetPassword from './ResetPassword';
import SetPassword from './SetPassword';
import TermsAndCondition from './TermsAndCondition';
import PrivacyPolicy from './PrivacyPolicy';
import NetWorth from './components/NetWorth';
import Assets from './components/Assets';
import Debts from './components/Debts';
import MutualFund from './components/mutualfunds/MutualFund';
import IndiaStocks from './components/indiastocks/IndiaStocks';
import USStocks from './components/usstocks/USStocks';
import CryptoCurrency from './components/cryptocurrency/CryptoCurrency';
import PrivateEquity from './components/privateequity/PrivateEquity';
import RealEstate from './components/realestate/RealEstate';
import PreciousMetals from './components/preciousmetals/PreciousMetals';
import PreciousMetalsDetails from './components/preciousmetals/PreciousMetalsDetails';
import RetirementFunds from './components/retirementfunds/RetirementFunds';
import Others from './components/others/Others';
import BankFds from './components/bankfds/BankFds';
import USStocksInformation from './components/USStocksInformation';
import CompanyInformationn from './components/CompanyInformationn';
import CryptoInformation from './components/cryptocurrency/CryptoInformation';
import RetirementCalculator from './calculator/RetirementCalculator';
import SIPCalculator from './calculator/SIPCalculator';
import GrowthCalculator from './calculator/GrowthCalculator';
import LoanCalculator from './calculator/LoanCalculator';
import MFInformation from './components/mutualfunds/MFInformation';
import IndiaStocksInformation from './components/indiastocks/IndiaStocksInformation';
import PrivateEquityInformation from './components/privateequity/PrivateEquityInformation';
import FeedBack from './components/FeedBack';
import Support from './components/Support';
import Ticket from './components/Ticket';
import TicketDetails from './components/TicketDetails';
import ReadEmail from './components/ReadEmail';

const AppLayout = () => {
    const {isAuthenticated} = useAuth();
    const location = useLocation();
    const [updateAvailable, setUpdateAvailable] = useState(false);

    useEffect(() => {
        const checkForUpdates = async () => {
          const result = await versionCheck();
          if (result?.updateAvailable) {
            setUpdateAvailable(true);
          } else {
            setUpdateAvailable(false);
          }
        };
    
        // Call your version checking logic here
        checkForUpdates();
    }, [location]);

    const cancelVersionUpdate = () => {
        setUpdateAvailable(false);
        localStorage.setItem('versionUpdate', true);
    }
    return (
        <div className="app-layout"> 
            {updateAvailable && isAuthenticated && (
                <div className="alert alert-warning alert-dismissible fade show version-update-container" role="alert">
                    <span onClick={clearCacheAndReload}>A new update is available! Please click <u style={{cursor: 'pointer'}}>here</u> to update.</span>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={cancelVersionUpdate}></button>
                </div>
            )}
            <div className="main-content">
                <Routes>
                    {/* Catch-all route for 404 */}
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<Navigate to="/login" replace />} />
                    {/* Routes without header */}
                    <Route path="/terms-of-services" element={<NoHeaderLayout><TermsAndCondition /></NoHeaderLayout>} />
                    <Route path="/privacy-policy" element={<NoHeaderLayout><PrivacyPolicy /></NoHeaderLayout>} />
                    <Route path="/login" element={<NoHeaderLayout><Login /></NoHeaderLayout>} />
                    <Route path="/signup" element={<NoHeaderLayout><Signup /></NoHeaderLayout>} />
                    <Route path="/verifyotp" element={<NoHeaderLayout><OTPForm /></NoHeaderLayout>} />
                    <Route path="/resetpassword" element={<NoHeaderLayout><ResetPassword /></NoHeaderLayout>} />
                    <Route path="/setpassword" element={<NoHeaderLayout><SetPassword /></NoHeaderLayout>} />
                    
                    {/* Route with header conditionally rendered */}
                    <Route 
                    path="calculator/retirement" 
                    element={<NoHeaderLayout><RetirementCalculator /></NoHeaderLayout>} 
                    />
                    <Route 
                    path="calculator/sip" 
                    element={<NoHeaderLayout><SIPCalculator /></NoHeaderLayout>} 
                    />
                    <Route 
                    path="calculator/growth" 
                    element={<NoHeaderLayout><GrowthCalculator /></NoHeaderLayout>} 
                    />
                    <Route 
                    path="calculator/home-loan" 
                    element={<NoHeaderLayout><LoanCalculator module='Home'/></NoHeaderLayout>} 
                    />
                    <Route 
                    path="calculator/car-loan" 
                    element={<NoHeaderLayout><LoanCalculator module='Car'/></NoHeaderLayout>} 
                    />
                    <Route 
                    path="calculator/personal-loan" 
                    element={<NoHeaderLayout><LoanCalculator module='Personal'/></NoHeaderLayout>} 
                    />

                    {/* Protected routes */}
                    <Route path="/networth" element={
                    <ProtectedRoute>
                        <Header />
                        <NetWorth />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets" element={
                    <ProtectedRoute>
                        <Header />
                        <Assets />
                    </ProtectedRoute>
                    } />
                    <Route path="/debts" element={
                    <ProtectedRoute>
                        <Header />
                        <Debts />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/mutualfund" element={
                    <ProtectedRoute>
                        <Header />
                        <MutualFund />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/indiastocks" element={
                    <ProtectedRoute>
                        <Header />
                        <IndiaStocks />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/usstocks" element={
                    <ProtectedRoute>
                        <Header />
                        <USStocks />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/cryptocurrency" element={
                    <ProtectedRoute>
                        <Header />
                        <CryptoCurrency />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/privateequity" element={
                    <ProtectedRoute>
                        <Header />
                        <PrivateEquity />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/realestate" element={
                    <ProtectedRoute>
                        <Header />
                        <RealEstate />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/preciousmetals" element={
                    <ProtectedRoute>
                        <Header />
                        <PreciousMetals />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/preciousmetals/details/:id" element={
                    <ProtectedRoute>
                        <Header />
                        <PreciousMetalsDetails />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/indiastocks/information/:id" element={
                    <ProtectedRoute>
                        <Header />
                        <IndiaStocksInformation />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/usstocks/information/:id" element={
                    <ProtectedRoute>
                        <Header />
                        <USStocksInformation />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/mutualfund/information/:id" element={
                    <ProtectedRoute>
                        <Header />
                        <MFInformation />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/cryptocurrency/information/:id" element={
                    <ProtectedRoute>
                        <Header />
                        <CryptoInformation />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/privateequity/information/:name" element={
                    <ProtectedRoute>
                        <Header />
                        <PrivateEquityInformation />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/retirementfunds" element={
                    <ProtectedRoute>
                        <Header />
                        <RetirementFunds />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/others" element={
                    <ProtectedRoute>
                        <Header />
                        <Others />
                    </ProtectedRoute>
                    } />
                    <Route path="/assets/fds" element={
                    <ProtectedRoute>
                        <Header />
                        <BankFds />
                    </ProtectedRoute>
                    } />
                    <Route path="/support" element={
                    <ProtectedRoute>
                        <Header />
                        <Support />
                    </ProtectedRoute>
                    } />
                    <Route path="/support/ticket" element={
                    <ProtectedRoute>
                        <Header />
                        <Ticket />
                    </ProtectedRoute>
                    } />
                    <Route path="support/ticket/:id" element={
                    <ProtectedRoute>
                        <Header />
                        <TicketDetails />
                    </ProtectedRoute>
                    } />
                    <Route path="/feedback" element={
                    <ProtectedRoute>
                        <Header />
                        <FeedBack />
                    </ProtectedRoute>
                    } />
                    <Route path="/reademail" element={
                    <ProtectedRoute>
                        <Header />
                        <ReadEmail />
                    </ProtectedRoute>
                    } />
                    {/* Add more routes as needed */}
                </Routes>
            </div>
        </div>
    );
}

export default AppLayout;