import React from "react";
import '../../css/StockCell.css';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { isNegative, shortFormatRupees, removeSign } from '../../utils/helpers';

function PreciousMetalsCell ({key, data}) {
    const navigate = useNavigate();

    const handleNavigation = (metalCode) => {
        navigate(`/assets/preciousmetals/details/${metalCode}`);
    };
    const metals = {
        au: 'Gold',
        ag: 'Silver',
        pt: 'Platinum',
        pd: 'Palladium',
    };

    const metalCode = data?.metal_code;
    const metalsName = metals[metalCode];
    const metalsIcon = {
        au: '../../images/gold.png',
        ag: '../../images/silver.png',
        pd: '../../images/palladium.png',
        pt: '../../images/platinum.png'
    }
    // Get the image source dynamically based on the metalsName
    const imageSrc = metalsIcon[metalCode];

    const returns = data?.returns;
    const returnsPercentage = isNegative(parseInt(returns[0]));
    const profitLossSymbol = (returnsPercentage) ? '' : '+';
    const profitLossClass = (returnsPercentage) ? 'loss-color' : 'profit-color';

    return (
        <div className="broker-cell-container" key={key} onClick={() => handleNavigation(metalCode)}>
            <div className="broker-cell-row">
                <div className="broker-cell-icon">
                    <img alt="" loading="lazy" width="32" height="32" decoding="async" data-nimg="1" src={imageSrc} style={{color: 'transparent'}}/>
                </div>
                <div className="broker-cell-name-container">
                    <p className="broker-cell-name" style={{textAlign: 'unset'}}>{metalsName}</p>
                </div>
                <div>
                    <div className="broker-cell-price-container">
                        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="broker-cell-right-icon">
                            <path d="M5.57 5.226l3.143 2.778-3.142 2.777a.656.656 0 000 1.01c.316.279.826.279 1.142 0l3.717-3.286a.656.656 0 000-1.01L6.713 4.21a.883.883 0 00-1.142 0 .67.67 0 000 1.017z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="broker-cell-row padding-unset">
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Investment</p>
                    <p className="broker-cell-account">₹{shortFormatRupees(data?.investment)}</p>
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Current Value</p>
                    <p className="broker-cell-account">₹{shortFormatRupees(data?.curr_val)}</p>
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Returns</p>
                    <p className={classNames('broker-cell-account returns-col', profitLossClass)}><span>₹{shortFormatRupees(returns[0])}</span> <span>({profitLossSymbol}{removeSign(returns[1])}%)</span></p>
                </div>
            </div>
        </div>
    );
}

export default PreciousMetalsCell;