import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { apiConfig } from '../ApiConfig';
import { apiRequest, showErrorMessage, showSuccessMessage } from '../utils/helpers';
import '../css/feedback.css';

const FeedBack = () => {
    const [formData, setFormData] = useState({
        liked_features: '',
        suggested_features: '',
        problem_areas: '',
        other_comments: '',
    });
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Check if the entire form is blank
        const isFormEmpty = Object.values(formData).every((value) => value.trim() === '');
        if (isFormEmpty) {
            alert('Form cannot be blank.');
            return;
        }

        setIsButtonLoading(true);

        try {
            // Call the API to post the form data
            const result = await apiRequest(apiConfig.FEEDBACK, 'POST', formData);
            if(result.statusCode === 200){
                showSuccessMessage('Feedback submitted successfully!');

                // Reset form to initial state
                setFormData({
                    liked_features: '',
                    suggested_features: '',
                    problem_areas: '',
                    other_comments: '',
                });
            }else {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <div className="container">
            <div className='feedback-container card'>
                <div className='card-body p-3'>
                    <h3>Your Opinion Shapes MoneyLens! </h3>
                    <h3 className="feedback-heading">MoneyLens is built to simplify wealth insights and give you personalised recommendations to grow your net wealth. By sharing your experiences, you help us prioritize improvements and build the best tools for managing your financial wealth. </h3>
                    <Form noValidate onSubmit={handleSubmit} className="us-stock">
                        <Form.Group controlId="liked_features">
                            <Form.Label>1. What are your favourite features of MoneyLens so far?</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Add your comments here"
                                value={formData.liked_features}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="suggested_features">
                            <Form.Label>2. What functionality are you missing or would like to see? </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Add your comments here"
                                value={formData.suggested_features}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="problem_areas">
                            <Form.Label>3. Did you encounter any issues or bugs that you'd like to report?  </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Add your comments here"
                                value={formData.problem_areas}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="other_comments">
                            <Form.Label>Additional Comments or Suggestions: </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Additional Comments or Suggestions"
                                value={formData.other_comments}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="feedBackSubmit" className="btn-container">
                            <Button variant="primary" type="submit" disabled={isButtonLoading}>
                                {isButtonLoading ? 'Submiting...' : 'Submit'}
                            </Button>
                        </Form.Group>
                        
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default FeedBack;
