import React, { useState } from "react";
import { formatDate, formatRupees, shortFormatUSD, showErrorMessage } from "../../utils/helpers";
import CryptoCurrencyForm from "./CryptoCurrencyForm";
import { apiConfig } from "../../ApiConfig";
import { apiRequest, sortData } from "../../utils/helpers";

function CryptoCurrencyIndividualTransactionList({ transaction, cryptoId, handleRefresh }) {
    const [transactionDetails, setTransactionDetails] = useState(transaction);
    const [showModal, setShowModal] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);

    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
    const [activeSortKey, setActiveSortKey] = useState(null);

    const handleEdit = (transaction) => {
        setCurrentTransaction({ ...transaction, cryptoid: parseInt(cryptoId, 10), cryptoprice: formatRupees(transaction.cryptoprice) });
        setShowModal(true);
    };    

    // Handle modal close
    const handleDelete = async (id) => {
        const confirmClose = window.confirm('Are you sure you want to delete?');
        if (!confirmClose) {
            return; // Prevent closing if user cancels
        }
        try {
            const url = `${apiConfig.CRYPTOCURRENCYTRANSACTIONS}/${id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'DELETE', null);
            if(result.statusCode === 200){
                handleRefresh(true);
                handleCloseModal(); // Close the modal after submission
            }else {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentTransaction(null);
    };

    const handleFormSubmit = async (formData) => {
        const { amount, ...dataToSubmit } = formData[0];
        try {
            const url = `${apiConfig.CRYPTOCURRENCYTRANSACTIONS}/${dataToSubmit.id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'PUT', dataToSubmit);
            if(result.statusCode === 200){
                handleRefresh();
                handleCloseModal(); // Close the modal after submission
            }else {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
        }
    };

    // Function to handle sorting logic
    const sortedData = sortData(transactionDetails, sortConfig);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
        }
        setSortConfig({ key, direction });
        setActiveSortKey(key); // Set the active sort key
    };

    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className={`filter ${activeSortKey === 'amount' ? 'active' : ''}`} onClick={() => handleSort('amount')}>Investment <i className="fas fa-caret-down" aria-hidden="true"></i></th>
                            <th scope="col" className={`filter ${activeSortKey === 'quantity' ? 'active' : ''}`} onClick={() => handleSort('quantity')}>Qty <i className="fas fa-caret-down" aria-hidden="true"></i></th>
                            <th scope="col" className={`filter ${activeSortKey === 'cryptoprice' ? 'active' : ''}`} onClick={() => handleSort('cryptoprice')}>Stock Price <i className="fas fa-caret-down" aria-hidden="true"></i></th>
                            <th scope="col" className={`filter ${activeSortKey === 'type' ? 'active' : ''}`} onClick={() => handleSort('type')}>Type <i className="fas fa-caret-down" aria-hidden="true"></i></th>
                            <th scope="col" className={`filter ${activeSortKey === 'date' ? 'active' : ''}`} onClick={() => handleSort('date')}>Date <i className="fas fa-caret-down" aria-hidden="true"></i></th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.length > 0 ? (
                            sortedData.map(details => (
                                <tr key={details?.id}>
                                    <td>${shortFormatUSD(details?.amount)}</td>
                                    <td>{details?.quantity}</td>
                                    <td>${shortFormatUSD(details?.cryptoprice)}</td>
                                    <td style={{textTransform: 'capitalize'}}>{details?.type}</td>
                                    <td>{formatDate(parseInt(details.date))}</td>
                                    <td>
                                        <i className="fas fa-edit edit-company" onClick={(e) => { e.preventDefault(); handleEdit(details); }}></i>
                                        <i className="fas fa-trash delete-company" onClick={(e) => { e.preventDefault(); handleDelete(details?.id); }}></i>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No transactions available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* Render the modal form */}
            {showModal && (
                <CryptoCurrencyForm
                    isOpen={showModal}
                    closeModal={handleCloseModal}
                    onFormSubmit={handleFormSubmit}
                    initialFormData={currentTransaction}
                    onEdit={true}
                />
            )}
        </div>
    );
}

export default CryptoCurrencyIndividualTransactionList;
