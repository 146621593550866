import React, { createContext, useContext, useState, useEffect } from 'react';
import { deleteCookie, clearCacheAndReload } from './utils/helpers';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // Check token on mount
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true); // Token is still valid
        } else {
            // Token expired, clear it
            logout();
        }
        setLoading(false);
    }, []);

    const login = (data) => {

        const token = data.token;
        localStorage.setItem('token', token);
        localStorage.setItem('userInfo', JSON.stringify(data));
        deleteCookie('emailVerify');

        setIsAuthenticated(true);
        clearCacheAndReload()
    };

    const logout = () => {
        const rememberMeData = localStorage.getItem('rememberMeData');
        localStorage.clear();
        
        if (rememberMeData) {
            localStorage.setItem('rememberMeData', rememberMeData);
        }
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, loading }}>
        {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
