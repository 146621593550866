import React, { useState, useEffect } from "react";
import '../css/Assets.css';
import { useNavigate } from 'react-router-dom';
import { fetchData, getFullUrl, shortFormatRupees } from '../utils/helpers';
import { apiConfig } from "../ApiConfig";
//import Loader from './Loader'; // Import the loader component


function Assets() {

    const navigate = useNavigate();

    const [assetValues, setAssetValues] = useState({
        mutualFundList: { curr_val: 'Loading...' },
        indiaStockList: { curr_val: 'Loading...' },
        usStockList: { curr_val: 'Loading...' },
        privateEquityList: { curr_val: 'Loading...' },
        cryptoCurrencyList: { curr_val: 'Loading...' },
        realEstateList: { curr_val: 'Loading...' },
        preciousMetalsList: { curr_val: 'Loading...' },
        // More keys can be added as needed
      }); // State to hold asset values
    // Fetch asset data when the component mounts
    useEffect(() => {
        const loadAssetsData = async () => {
            try {
                const urls = {
                    mutualFundList: { url: apiConfig.MFSUMMARY, key: 'mutualFundList' },
                    indiaStockList: { url: apiConfig.INDIASTOCKSUMMARY, key: 'indiaStockList' },
                    usStockCurrVal: { url: apiConfig.USSTOCKSCURRVAL, key: 'usStockCurrVal' },
                    privateEquityList: { url: apiConfig.PRIVATEEQUITYSUMMARY, key: 'privateEquityList' },
                    cryptoCurrencyCurrVal: { url: apiConfig.CRYPTOCURRENCYCURRVAL, key: 'cryptoCurrencyCurrVal' },
                    realEstateList: { url: apiConfig.REALESTATESUMMARY, key: 'realEstateList' },
                    preciousMetalsList: { url: apiConfig.PRECIOUSMETALSSUMMARY, key: 'preciousMetalsList' },
                };
        
                const assetValues = {};
        
                for (const [key, { url, key: storageKey }] of Object.entries(urls)) {
                    try {
                        const fetchedValue = await fetchData(url, 'GET', storageKey, false);
                        // Special handling for usStockCurrVal and cryptoCurrencyCurrVal
                        if (key === 'usStockCurrVal' || key === 'cryptoCurrencyCurrVal') {
                            assetValues[key] = { ...fetchedValue, curr_val: fetchedValue };
                        } else {
                            assetValues[key] = fetchedValue;
                        }

                        // Incrementally update state
                        setAssetValues((prevState) => ({
                            ...prevState,
                            [key]: assetValues[key],
                        }));

                    } catch (error) {
                        console.error(`${key.toUpperCase()} Assets:`, error);
                    }
                }
                // Optional: Update the state with all values at once (if needed)
                setAssetValues(assetValues);
            } catch (error) {
                console.error('Assets data:', error);
            }
        };
        

        loadAssetsData();
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const getFormattedValue = (key) => {
        if (!assetValues[key] || assetValues[key].curr_val === 'Loading...') {
          return 'Loading...'; // Placeholder while the data is loading
        }
      
        const currVal = assetValues[key]?.curr_val;
        return currVal != null ? shortFormatRupees(currVal) : '0';
      };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/mutualfund')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-chart-bar icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Mutual Funds</div>
                            <div className="floating-value">
                                ₹{getFormattedValue('mutualFundList')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/indiastocks')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-rupee-sign icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">India Stocks</div>
                            <div className="floating-value">
                                ₹{getFormattedValue('indiaStockList')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/usstocks')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-dollar-sign icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">US Stocks</div>
                            <div className="floating-value">
                                ₹{getFormattedValue('usStockCurrVal')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/privateequity')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-balance-scale-left icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Private Equity</div>
                            <div className="floating-value">
                                ₹{getFormattedValue('privateEquityList')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/cryptocurrency')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <img alt="icon" src={getFullUrl('images/crypto.svg')} className="icon crypto-icon" />
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Crypto Currency</div>
                            <div className="floating-value">
                                ₹{getFormattedValue('cryptoCurrencyCurrVal')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/realestate')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-building icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Real Estate</div>
                            <div className="floating-value">
                                ₹{getFormattedValue('realEstateList')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/preciousmetals')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-gem icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Precious Metals</div>
                            <div className="floating-value">
                                ₹{getFormattedValue('preciousMetalsList')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/fds')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-landmark icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">FDs</div>
                            <div className="floating-value" style={{color: '#ced4da'}}>{`(Coming Soon)`}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-car-alt icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Vehicle</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.vehicle) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-euro-sign icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">EPF</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.epf) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-piggy-bank icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">PPF</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.ppf) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/retirementfunds')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-child icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Retirement Funds</div>
                            <div className="floating-value" style={{color: '#ced4da'}}>{'(Coming Soon)'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container" onClick={() => handleNavigation('/assets/others')}>
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-shapes icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Others</div>
                            <div className="floating-value" style={{color: '#ced4da'}}>{'(Coming Soon)'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    {/* <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-wallet icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Cash</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.cash) || 'Loading...'}</div>
                        </div>
                    </div> */}
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center"></div>
            </div>
        </div>
    );
    
}

export default Assets;