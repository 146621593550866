import React, { useState, useEffect } from "react";
import { formatRupees } from "../utils/helpers";
import SliderWithInput from "./SliderWithInput";
import HeaderNoValidation from "../components/HeaderNoValidation";
import CalculatorPieChart from "./CalculatorPieChart";
import CalculatorGraph from "./CalculatorGraph";
import CalculatorTable from "./CalculatorTable";
import { useAuth } from "../AuthContext";
import Header from "../components/Header";

const GrowthCalculator = () => {
    
    const {isAuthenticated} = useAuth();

    const [pastValue, setPastValue] = useState(50000);
    const [presentValue, setPresentValue] = useState(100000);
    const [durationInYear, setDurationInYear] = useState(10);
    const [growthRate, setGrowthRate] = useState(0);
    const [growthAmount, setGrowthAmount] = useState(0);
    const [graphData, setGraphData] = useState([]);
    const [afterRetirementFlowType, setAfterRetirementFlowType] = useState('graph');
    const chartLabel = ['Interest Amount', 'Principal Amount'];
    const tooltipLabel = ['Principal Amount', 'Interest Amount'];

    useEffect(() => {
        if (pastValue > 0 && durationInYear > 0) {
            const rate = (Math.pow(presentValue / pastValue, 1 / durationInYear) - 1) * 100;
            setGrowthRate(parseFloat(rate.toFixed(2))); // Set growth rate as a number
        } else {
            setGrowthRate(0);
        }
    }, [pastValue, presentValue, durationInYear]);

    useEffect(() => {
        // Calculate growth amount whenever pastValue or presentValue changes
        const growthValue = presentValue - pastValue;
        setGrowthAmount(growthValue);
    }, [pastValue, presentValue]);

    useEffect(() => {
        const data = calculateGraphTable();
        setGraphData(data)
    }, [
        durationInYear,
        growthAmount,
        presentValue,
        pastValue
    ]);

    const calculateGraphTable = () => {
        const periodInMonths = durationInYear * 12;
        const monthlyRate = Math.pow(presentValue / pastValue, 1 / periodInMonths) - 1; // Monthly growth rate
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const currentYear = new Date().getFullYear();
        
        let yearlyData = [];
        let totalFutureValue = pastValue; // Start with the initial past value
        let yearlyInterestAccumulated = 0; // Track yearly interest
        // Loop through the total duration in months
        for (let month = 0; month < periodInMonths; month++) {

            const previousFutureValue = totalFutureValue;
            // Apply monthly growth rate to get future value for this month
            totalFutureValue *= (1 + monthlyRate); // Apply monthly growth rate
            const interestEarned = totalFutureValue - previousFutureValue;
            
            const yearIndex = Math.floor(month / 12); // Get the year index
            const monthInYear = month % 12; // Get the month within that year
            
            // Initialize new year data if it doesn't exist
            if (!yearlyData[yearIndex]) {
                yearlyData[yearIndex] = {
                    year: currentYear + yearIndex,
                    months: [],
                    yearlyInvestment: Math.round(pastValue), // Total initial investment for the year
                    yearlyInterest:  Math.round(yearlyInterestAccumulated),
                };
            }
            // Accumulate interest for the current year
            yearlyInterestAccumulated += interestEarned;
            
            yearlyData[yearIndex].months.push({
                yearNo: yearIndex + 1,
                yearName: currentYear + yearIndex,
                month: monthNames[monthInYear],
                expense: Math.round(pastValue),
                interest: Math.round(interestEarned),
                remainingCorpus: Math.round(yearlyInterestAccumulated)
            });
        }
    
        return yearlyData; // Return the yearly data structured by year and month
    };
    
    const pieChartData = [
        { label: "Principal Amount", count: Math.round(pastValue) },
        { label: "Interest Amount", count: Math.round(growthAmount) }
    ];
    const handleTabSelect = (type) => {
        setAfterRetirementFlowType(type);
      };
    return (
        <>
            {isAuthenticated ? <Header /> : <HeaderNoValidation />}
            
            <div className='retirement-container container'>
                <div className='retirement-row'>
                    <div className='retirement-left' style={{paddingTop: 'unset'}}>
                    <h1 className='retirement-heading'>CAGR Calculator</h1>
                    <div style={{paddingTop: '1rem'}}>
                        <SliderWithInput 
                            onChange={setPresentValue} 
                            label={'Present Value'} 
                            inputLabel={'₹'} 
                            defaultValue={presentValue} 
                            min={0} 
                            max={50000000} 
                        />
                        <SliderWithInput 
                            onChange={setPastValue} 
                            label={'Past Investment value'} 
                            inputLabel={'₹'} 
                            defaultValue={pastValue} 
                            min={0} 
                            max={10000000} 
                        />
                        <SliderWithInput 
                            onChange={setDurationInYear} 
                            label={'Time Period'} 
                            inputLabel={'Years'} 
                            defaultValue={durationInYear} 
                            min={0} 
                            max={50} 
                            step={1}
                            allowDecimal={true} 
                        />
                        </div>
                    </div>
                    <div className='right-border growth'></div>
                    <div className='retirement-right'>
                        <CalculatorPieChart pieChartData={pieChartData} module={'growth'}/>
                        <div>
                            <div className='right-value-container'>
                                <span className='value-title'>Annual Growth Rate:</span> 
                                <span className='right-value'>{growthRate}%</span>
                            </div>
                            {/* <div className='right-value-container'>
                                <span className='value-title'>Total Interest:</span>
                                <span className='right-value'>₹ {formatRupees(growthAmount)}</span>
                            </div> */}
                            {/* <div className='right-value-container'>
                                <span className='value-title'>Total Present Value:</span>
                                <span className='right-value'>₹ {presentValue >= 0 ? formatRupees(presentValue) : '0'}</span>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='retirement-row'>
                    <div className='show-table-graph-container'>
                        <h4 className='graph-heading'>Growth</h4>
                        <div className="toggle-button-group">
                            <button
                                className={`toggle-button ${afterRetirementFlowType === 'graph' ? 'active' : ''}`}
                                onClick={() => handleTabSelect('graph')}
                            >
                                Graph
                            </button>
                            <button
                                className={`toggle-button ${afterRetirementFlowType === 'table' ? 'active' : ''}`}
                                onClick={() => handleTabSelect('table')}
                            >
                                Table
                            </button>
                        </div>
                        {/* Conditional Rendering based on selected tab */}
                        {afterRetirementFlowType === 'table' ? (
                            <CalculatorTable data={graphData} heading2={'Principal Amount'} heading3={'Interest Amount'} module={'sip'} cagr={'Total Amount'}/>
                        ) : (
                            <CalculatorGraph data={graphData} chartLabel={chartLabel} tooltipLabel={tooltipLabel} module={'sip'}/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default GrowthCalculator;
