import React, {useState, useEffect} from "react";
import '../../css/MutualFund.css';

import TotalPortfolioValue from "../TotalPortfolioValue";
import DynamicTabs from "../DynamicTabs";
import StockList from "../StockList";
import USStocksAnalytics from './USStocksAnalytics';
import USStocksTransaction from './USStocksTransaction';
import Loader from "../Loader";

import { fetchData, updateDirtyState, apiRequest, showErrorMessage, showSuccessMessage } from "../../utils/helpers";
import USStocksForm from "./USStocksForm";
import EmptyState from "../EmptyState";
import { apiConfig } from "../../ApiConfig";

import { trackEvent, trackPageView } from "../../utils/googleAnalytics";
import { useLocation } from "react-router-dom";
// import { useSidebar } from "../SidebarContext";

function USStocks() {

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [usStockList, setUsStockList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const { updateSideBarData } = useSidebar();

    const url = apiConfig.USSUMMARY;
    const method = 'GET';
    const localStorageKey = 'usStockList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            trackEvent('USStocks', 'request', 'Loading us stocks data');
            const data = await fetchData(url, method, localStorageKey, refresh);
            if (data) {
                // Set state with actual data
                setPortfolioValue(data);
                setUsStockList(data?.asset_details || []);
                setPortfolioValue1DChange(data['1d_change'] || [0, 0]);
                setPortfolioValueReturns(data['returns'] || [0, 0]);
                trackEvent('USStocks', 'success', 'Successfully loaded us stocks data');
            }
        } catch (error) {
            showErrorMessage();
            trackEvent('USStocks', 'error', `Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        updateDirtyState('usStockList');
        updateDirtyState('usStocksAnalyticsData');
        updateDirtyState('usStocksTransactionList');
        updateDirtyState('networth-dashboard');
        updateDirtyState('usStockCurrVal');
        // updateSideBarData();
    };

    const portfolioData = {
        module: 'usstocks',
        value: portfolioValue?.curr_val || 0,  // Use optional chaining and a fallback value
        dayChangeValue: portfolioValue1DChange?.[0] || 0,  // Optional chaining with fallback
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
    };    
    
    const tabs = [
        { key: 'stocklist', name: 'My Stocks', component: StockList },
        { key: 'analytics', name: 'Analytics', component: USStocksAnalytics },
        { key: 'transactions', name: 'Transactions', component: USStocksTransaction },
    ];
    
    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true); // Open the modal
        trackEvent('USStocks', 'openForm', 'Opening form modal');
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (requestData, isFile = false) => {
        let url = apiConfig.USTRANSACTIONS;
        let formData = { transactions: requestData };

        if (isFile) {
            const fileData = new FormData();
            fileData.append("file", requestData);
            formData = fileData;
        }
        try {
            trackEvent('USStocks', 'request', 'Submitting us stocks transaction');
            // Call the API to post the form data
            const result = await apiRequest(url, 'POST', formData);
            if (result.statusCode === 200){
                showSuccessMessage()
                handleRefresh();
                closeModal(); // Close the modal after submission
                trackEvent('USStocks', 'success', 'Successfully submitted transaction');
            } if (result.error) {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('USStocks', 'error', `APi (${url}): ${result.error.message}`);
            } else if(result.statusCode !== 200) {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('USStocks', 'error', `APi (${url}): ${result.message}`);
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
            trackEvent('USStocks', 'error', `APi (${url}): ${error.message}`);
        }
    };

    const initialFormData = {
        name: '', //name
        companyid: '', //  id
        quantity: '', // Quantity
        stockprice: '', // Purchase price
        date: '', // Purchase date
    };
    return (
        <div>
        {isLoading ? ( 
            <Loader />
        ) : usStockList.length === 0 ? ( // Check if stock list is empty
            <EmptyState openModal={openModal}/>  // Render the Empty State component when there's no data
        ) : (
            <div className="container indStock pad-right-unset pad-left-unset">
                <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'US Stocks'} openForm={() => openModal()}/>
                <DynamicTabs tabs={tabs} data={usStockList} module={'usstocks'} onTabChange={(activeTabKey) => {
                    if (activeTabKey === 'stocklist') {
                        loadData(); // Call loadData when "My Stocks" tab is clicked
                    }
                }}/>
                
                <div className="float-container">
                    <a className="float"  onClick={(e) => { e.preventDefault(); openModal(); }}>
                        <i className="fa fa-plus my-float"></i>
                    </a>
                </div>
            </div>
        )}
        <USStocksForm 
            isOpen={isModalOpen} 
            closeModal={closeModal} 
            onFormSubmit={handleFormSubmit} 
            initialFormData={initialFormData}
            defaultForm='manual'
        />
        </div>
    );
}

export default USStocks;